import { Button, Input, Menu, Modal, Dropdown } from 'antd';
import firebase from 'firebase';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setTimeout } from 'timers';
import moment from 'moment';
import { trackAnalytics } from 'utils';
import { signOut as signOutAction } from '../../store/actions/authActions';
import { canvasFilterThreads } from '../../store/actions/newCanvasActions';
import { IThread } from '../../types/projectTypes';
import { mobileCheck, copyURL } from '../../utils';
import ProfileDrawer from '../auth/ProfileDrawer';
import DropDownCustom from '../dropDown';
import OutreachCanvasChatWindow from '../projects/Chat/OutreachCanvasChatWindow';
import { TripleDot, BackCircleIcon } from '../svg';
import { Logo } from '../svg/Logo';
import './style.css';
import thread from './thread';
import Thread from './thread';

const OutreachCanvas = (props: any) => {
  const { profile, canvasFilterThreadsAction, threads, match, auth } = props;
  const { uid = '' } = auth;
  const canvasId = props.match.params.id || '';
  const isWT = match.path === '/chat/:id' ? true : false;
  const { fullName = '', photoURL = '', email = '' } = profile;
  const [selectedOrganizationData, setSelectedOrganizationData] = useState({});
  const [isChatMode, setIsChatMode] = useState(false);
  const [initial, setInitial] = useState(true);
  const [threadChatId, setThreadChatId] = useState('');
  const [clearOldChat, setClearOldChat] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [profileDrawerVisible, setProfileDrawerVisible] = useState(false);

  const [filterCheckbox]: any = useState({
    isShowAll: false,
    isShowUnread: false,
    isShowResolved: false,
  });
  const initialCanvasState = {
    title: '',
    project_url: '',
    createdAtUTC: '',
    tags: [],
    members: [],
    threads: [],
    workflowId: '',
    transcript: [],
    archived: 'no',
    blobURL: '',
    project_created_by: '',
    userNotes: '',
    jiraIssueUrl: '',
    trelloIssueUrl: '',
    canvasPassword: '',
    type: '',
    isInterviewCanvas: false,
  };

  const [canvasDetails, setCanvasDetails] = useState(initialCanvasState);
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [selectedThread, setSelectedThread] = useState<IThread>({
    member: {
      image: photoURL,
      name: fullName,
      email,
    },
    message: '',
    mediaTime: 0,
    createdAt: '',
    replyCount: 0,
    sequenceNo: canvasDetails.threads ? canvasDetails.threads.length + 1 : 1,
    id: '',
    isResolve: false,
    readUserEmails: [email],
  });
  const oldChatId = useRef('');
  const isCheckedOnce = useRef(true);
  const isMobile = mobileCheck();

  const onCloseProfileDrawer = () => {
    setProfileDrawerVisible(false);
  };

  const wtThreadActionMenu = () => {
    const menu = (
      <Menu>
        {['Create Topic', 'Profile', 'Logout'].map((action: string, index: number) => (
          <Menu.Item
            className='thread-action-menu-item project-dropdown-item'
            key={index}
            onClick={async () => {
              console.log("action====", action)
              if (action === 'Logout') {
                sessionStorage.removeItem('lastWTChatURL');
                sessionStorage.removeItem('isWT');
                props.signOut();
                window.location.href = 'https://walkaitalkai.com';
              } else if (action === 'Profile') {
                setProfileDrawerVisible(true);
              }else if(action === 'Create Topic'){
                  if (!uid) {
                    Modal.confirm({
                      content: '',
                      okText: 'Yes',
                      cancelText: 'No',
                      title: 'Please Sign Up to continue!',
                      onOk: () => {
                        props.history.push('/signup');
                      },
                    });
                  } else {
                    const threadId = await addComment();
                    let pathname = window.location.pathname;
                    pathname = `${pathname}?threadId=${threadId}`;
                    const pageUrlParams = new URLSearchParams(window.location.search);
                    const pageParam: any = pageUrlParams.get('page');
                    if (pageParam) {
                      pathname = `${pathname}&page=${pageParam}`;
                    }
                    trackAnalytics('Canvas Detail', 'Add New Thread Clicked');
                    window.history.pushState({}, document.title, pathname);
                  }
              }
            }}
          >
            {action}
          </Menu.Item>
        ))}
      </Menu>
    );
    return menu;
  };

  useEffect(() => {
    const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');
    if (selectedOrganizationId) {
      const firestore = firebase.firestore();
      firestore
        .collection('organization')
        .doc(selectedOrganizationId)
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            const currentOrganizationData = doc.data();
            setSelectedOrganizationData(currentOrganizationData);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (type === 'whiteboard' && thread.length === 1) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [threads, canvasDetails.type]);

  useEffect(() => {
    let filterThreads = canvasDetails.threads || [];
    if (!filterCheckbox.isShowAll) {
      if (filterCheckbox.isShowResolved) {
        filterThreads = filterThreads.filter((thread: IThread) => thread.isResolve);
      } else {
        filterThreads = filterThreads.filter((thread: IThread) => !thread.isResolve);
      }
      if (filterCheckbox.isShowUnread) {
        filterThreads = filterThreads.filter((thread: IThread) => {
          const readUserEmails = thread.readUserEmails || [];
          return !readUserEmails.includes(email);
        });
      }
    }
    canvasFilterThreadsAction(filterThreads);
  }, [filterCheckbox]);

  useEffect(() => {
    const pageUrlParams = new URLSearchParams(window.location.search);
    const threadId: any = pageUrlParams.get('threadId');
    if (!threadId) {
      const newSelectedThreadData = JSON.parse(JSON.stringify(selectedThread));
      newSelectedThreadData.mediaTime = 0;
      setSelectedThread(newSelectedThreadData);
      setIsChatMode(false);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (props.location.search) {
      const pageUrlParams = new URLSearchParams(window.location.search);
      const threadId: any = pageUrlParams.get('threadId');
      if (threadId) {
        setThreadChatId(threadId);
        const firestore = firebase.firestore();
        firestore
          .collection('chat')
          .where(firebase.firestore.FieldPath.documentId(), '==', threadId)
          .onSnapshot((chatDetails: any) => {
            if (chatDetails && chatDetails.docs && chatDetails.docs.length) {
              //  setThreadChatId(chatDetails.docs[0].id);
              // setThreadChatMessages(constchatDetailData.messages);
              setIsChatMode(true);
              if (oldChatId.current !== threadId) {
                setClearOldChat(true);
              }
              oldChatId.current = threadId;
              const oldThreads: IThread[] = canvasDetails.threads || [];
              const matchedThreadIndex = oldThreads.findIndex(
                (thread: IThread) => thread.id === threadId,
              );
              const canvasThread: any = oldThreads.find((canvasThreadTemp: IThread) => {
                return canvasThreadTemp.id === threadId;
              });
              if (canvasThread && canvasThread.id) {
                const readUserEmails: any = canvasThread.readUserEmails || [];
                const newSelectedThreadData: any = canvasThread;
                if (!readUserEmails.includes(email)) {
                  readUserEmails.push(email);
                  newSelectedThreadData.readUserEmails = readUserEmails;
                }
                setSelectedThread(newSelectedThreadData);
                oldThreads[matchedThreadIndex] = canvasThread;
                firestore
                  .collection('projects')
                  .doc(props.match.params.id)
                  .update({
                    threads: oldThreads,
                  });
              }
            } else {
              setIsChatMode(true);
              setSelectedThread({
                member: {
                  image: photoURL,
                  name: fullName,
                  email,
                },
                message: '',
                mediaTime: 0,
                createdAt: '',
                replyCount: 0,
                sequenceNo: canvasDetails.threads ? canvasDetails.threads.length + 1 : 1,
                id: '',
                isResolve: false,
                readUserEmails: [email],
                dummy: true,
              });
            }
          });
      }
    }
  }, [props.location.search]);

  React.useEffect(() => {
    setLoading(true);
    const firestore = firebase.firestore();
    firestore
      .collection('projects')
      .where(firebase.firestore.FieldPath.documentId(), '==', props.match.params.id)
      .onSnapshot(async (projectDetails: any) => {
        if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
          const projectDetailData = projectDetails.docs[0].data();
          let isMember = false;
          if (!projectDetailData.isPublic) {
            if (projectDetailData.masterProjectId) {
              const masterProjectDoc = await firestore
                .collection('masterProjects')
                .doc(projectDetailData.masterProjectId)
                .get();
              if (masterProjectDoc.exists) {
                const masterProjectDocData: any = masterProjectDoc.data();
                if (masterProjectDocData.member_ids) {
                  masterProjectDocData.member_ids &&
                    masterProjectDocData.member_ids.map((member_id: any) => {
                      if (
                        props.auth &&
                        (member_id === props.auth.uid || member_id === props.auth.email)
                      ) {
                        isMember = true;
                      }
                    });
                }
              }
            }
            if (projectDetailData.canvasPassword && !isMember && isCheckedOnce.current) {
              setShowPasswordField(true);
            } else {
              setShowPasswordField(false);
            }
            isCheckedOnce.current = false;
          }

          if (
            isMember ||
            projectDetailData.isPublic ||
            projectDetailData.canvasPassword ||
            !props.auth.isLoaded
          ) {
            let filterThreads = projectDetailData.threads || [];
            if (!filterCheckbox.isShowAll) {
              if (filterCheckbox.isShowResolved) {
                filterThreads = filterThreads.filter((thread: IThread) => thread.isResolve);
              } else {
                filterThreads = filterThreads.filter((thread: IThread) => !thread.isResolve);
              }
              if (filterCheckbox.isShowUnread) {
                filterThreads = filterThreads.filter((thread: IThread) => {
                  const readUserEmails = thread.readUserEmails || [];
                  return !readUserEmails.includes(email);
                });
              }
            }
            setError('');
            canvasFilterThreadsAction(filterThreads);
            setCanvasDetails(projectDetailData);
            setInitial(false);
            const canvasThreads = projectDetailData.threads || [];
            const pageUrlParams = new URLSearchParams(window.location.search);
            const threadId = pageUrlParams.get('threadId');
            const canvasThread = canvasThreads.find((canvasThreadTemp: IThread) => {
              return canvasThreadTemp.id === threadId;
            });
            if (canvasThread && canvasThread.id) {
              changeThread(canvasThread, projectDetailData);
            }
          } else {
            setError('You dont have access to this canvas!');
          }
        } else {
          setCanvasDetails(initialCanvasState);
        }
        setLoading(false);
      });
  }, [props.auth]);

  const changeThread = async (
    selectedThreadData: IThread,
    _canvasProjectDetails = initialCanvasState,
  ) => {
    setIsChatMode(true);
    const readUserEmails: any = selectedThread.readUserEmails || [];
    const newSelectedThreadData: any = selectedThreadData;
    if (!readUserEmails.includes(email)) {
      readUserEmails.push(email);
      newSelectedThreadData.readUserEmails = readUserEmails;
    }
    setSelectedThread(newSelectedThreadData);
    setThreadChatId(selectedThreadData.id);
    let pathname = window.location.pathname;
    pathname = `${pathname}?threadId=${selectedThreadData.id}`;
    if (selectedThreadData && selectedThreadData.page) {
      pathname = `${pathname}&page=${selectedThreadData.page}`;
    }
    window.history.pushState({}, document.title, pathname);
  };

  const addMessageInThread = (chatMessage: any, newDocumentId: any) => {
    const { message, chatMessageCount } = chatMessage;
    const firestore = firebase.firestore();
    const oldThreads: IThread[] = canvasDetails.threads || [];
    const matchedThreadIndex = oldThreads.findIndex(
      (thread: IThread) => thread.id === threadChatId,
    );
    const pageUrlParams = new URLSearchParams(window.location.search);
    const page: any = pageUrlParams.get('page');
    if (matchedThreadIndex > -1) {
      oldThreads[matchedThreadIndex].replyCount = chatMessageCount;
      oldThreads[matchedThreadIndex].id = newDocumentId;
      if (
        !oldThreads[matchedThreadIndex].message ||
        oldThreads[matchedThreadIndex].message.includes('Untitled thread')
      ) {
        if (oldThreads[matchedThreadIndex].message === 'Untitled thread') {
          oldThreads[matchedThreadIndex].message = message;
        } else {
          oldThreads[matchedThreadIndex].message = oldThreads[matchedThreadIndex].message.replace(
            'Untitled thread',
            message,
          );
        }
      }
      oldThreads[matchedThreadIndex].page = page;
      setThreadChatId(newDocumentId);
      firestore
        .collection('projects')
        .doc(props.match.params.id)
        .update({
          threads: oldThreads,
        });
      setSelectedThread({
        ...selectedThread,
        replyCount: chatMessageCount,
        page,
      });
    }
  };

  const addComment = async () => {
    const firestore = firebase.firestore();
    return await firestore
      .collection('chat')
      .add({
        messages: [],
        count: 0,
      })
      .then((obj): any => {
        if (obj.id) {
          setThreadChatId(obj.id);
          // setThreadChatMessages([]);
          setIsChatMode(true);
          // setTimeout(() => {
          //   setIsChatMode(false);
          // }, 0)
          // addMessageInThread({
          //   message: "Untitled thread",
          //   createdAt: moment()
          //     .utc()
          //     .valueOf(),
          //   messageId: obj.id,
          //   chatMessageCount: 0
          // }, obj.id)

          let oldThreads: IThread[] = canvasDetails.threads || [];
          const sequenceNo = oldThreads.length + 1;
          // const { fullName = '', photoURL = '', email = '' } = profile;
          const pageUrlParams = new URLSearchParams(window.location.search);
          const page: any = pageUrlParams.get('page');
          let message = 'Untitled thread';
          if (oldThreads.length) {
            oldThreads.unshift({
              member: {
                image: photoURL,
                name: fullName,
                email,
              },
              message,
              mediaTime: 0,
              createdAt: moment()
                .utc()
                .valueOf()
                .toString(),
              replyCount: 0,
              sequenceNo,
              id: obj.id,
              isResolve: false,
              readUserEmails: [email],
              page,
            });
          } else {
            oldThreads = [
              {
                member: {
                  image: photoURL,
                  name: fullName,
                  email,
                },
                message,
                mediaTime: 0,
                createdAt: moment()
                  .utc()
                  .valueOf()
                  .toString(),
                replyCount: 0,
                sequenceNo,
                id: obj.id,
                isResolve: false,
                readUserEmails: [email],
                page,
              },
            ];
          }
          setSelectedThread({
            member: {
              image: photoURL,
              name: fullName,
              email,
            },
            message,
            mediaTime: 0,
            createdAt: moment()
              .utc()
              .valueOf()
              .toString(),
            replyCount: 0,
            sequenceNo,
            id: obj.id,
            isResolve: false,
            readUserEmails: [email],
            page,
          });
          firestore
            .collection('projects')
            .doc(props.match.params.id)
            .update({
              threads: oldThreads,
            });
          return obj.id;
        }
      });
  };

  const threadActionMenu = () => {
    const menu = (
      <Menu>
        {['Share Link', 'Delete'].map((action: string, index: number) => (
          <Menu.Item
            className='thread-action-menu-item project-dropdown-item'
            key={index}
            onClick={() => {
              if (action === 'Delete') {
                deleteThread();
              } else if (action === 'Share Link') {
                copyURL(window.location.href);
              }
            }}
          >
            {action}
          </Menu.Item>
        ))}
      </Menu>
    );
    return menu;
  };

  const deleteThread = () => {
    if (!uid) {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Please Sign Up to continue!',
        onOk: () => {
          props.history.push('/signup');
        },
      });
    } else {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Do you want to delete this thread?',
        onOk: async () => {
          const firestore = firebase.firestore();
          const oldThreads: IThread[] = canvasDetails.threads || [];
          const matchedThreadIndex = oldThreads.findIndex(
            (thread: IThread) => thread.id === threadChatId,
          );
          if (matchedThreadIndex > -1) {
            oldThreads.splice(matchedThreadIndex, 1);
            firestore
              .collection('projects')
              .doc(canvasId)
              .update({
                threads: oldThreads,
              });
            const pageDocument = await firestore
              .collection('pages')
              .where('project_id', '==', canvasId)
              .get();
            if (pageDocument && pageDocument.docs && pageDocument.docs.length) {
              const pageData: any = pageDocument.docs[0].data();
              let feedbacks = pageData.feedback || [];
              feedbacks = feedbacks.filter((feedback: any) => {
                return feedback.circle_pointer.id !== threadChatId;
              });
              pageData.feedback = feedbacks;
              firestore
                .collection('pages')
                .doc(pageDocument.docs[0].id)
                .update({
                  feedback: feedbacks,
                });

              // const urlParams = new URLSearchParams(window.location.search);
              // const pageValue =
              //  props.match.path === '/c/:id' ? urlParams.get('threadId') : urlParams.get('page');

              // const store = { pages: pageData, auth: uid, pagePath: pageValue };
              // syncWithIframe(store, 'SYNC_INIT');
            }
            setIsChatMode(false);
          }
        },
      });
    }
  };

  // const resolveThread = () => {
  //   if (!uid) {
  //     Modal.confirm({
  //       content: '',
  //       okText: 'Yes',
  //       cancelText: 'No',
  //       title: 'Please Sign Up to continue!',
  //       onOk: () => {
  //         props.history.push('/signup');
  //       },
  //     });
  //   } else {
  //     Modal.confirm({
  //       content: '',
  //       okText: 'Yes',
  //       cancelText: 'No',
  //       title: `Do you want to ${selectedThread.isResolve ? 'un resolve' : 'resolve'} this thread?`,
  //       onOk: () => {
  //         const firestore = firebase.firestore();
  //         const oldThreads: IThread[] = canvasDetails.threads || [];
  //         const matchedThreadIndex = oldThreads.findIndex(
  //           (thread: IThread) => thread.id === threadChatId,
  //         );
  //         if (matchedThreadIndex > -1) {
  //           oldThreads[matchedThreadIndex].isResolve = selectedThread.isResolve ? false : true;
  //           firestore
  //             .collection('projects')
  //             .doc(canvasId)
  //             .update({
  //               threads: oldThreads,
  //             });
  //           setIsChatMode(false);
  //         }
  //       },
  //     });
  //   }
  // };

  const chatWindowRender = () => {
    return (
      <div
        className='thread-chat-container'
        style={{
          width: isMobile ? '100%' : undefined,
          position: 'relative',
          height: isMobile ? '100vh' : undefined,
        }}
      >
        <ProfileDrawer
          onCloseDrawer={onCloseProfileDrawer}
          visible={profileDrawerVisible}
          profile={profile}
        />
        {isWT && !isChatMode && (
          <div
            style={{
              padding: '10px',
              display: 'flex',
              background: '#ecf5ff',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ paddingLeft: '10px', display: 'flex', alignItems: 'center' }}>
              {props.auth.email}
            </div>
            <div
              style={{
                width: '30px',
                height: '30px',
                right: '25px',
                display: 'flex',
                background: '#cccccc',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '5px',
                top: '15px',
                borderRadius: '10px',
              }}
            >
              <DropDownCustom
                buttonComponent={() => (
                  <div className='more-icon cursor-pointer'>
                    <TripleDot />
                  </div>
                )}
                position={'bottom'}
                items={wtThreadActionMenu()}
              />
            </div>
          </div>
        )}

        {!isChatMode && (
          <Thread
            initial={initial}
            filterCheckbox={filterCheckbox}
            setFilterCheckbox={() => {
              console.log("filter thread on the basis of unread, resolve");
            }}
            loginUserEmail={email}
            threads={threads}
            changeThread={changeThread}
            addComment={addComment}
            setActiveTab={() => null}
            showCommenting={
              props.match.path === '/pdf/:id' ||
              props.match.path === '/whiteboard/:id' ||
              props.match.path === '/website/:id' ||
              props.match.path === '/image/:id'
            }
            mediaPlayedTime={0}
            profile={profile}
            videoUrl={canvasDetails && canvasDetails.project_url}
            match={props.match}
            uid={uid}
            selectedOrganizationData={selectedOrganizationData}
            isWT={true}
          />
        )}
        {/* {!threads.length && !isChatMode && !initial ? (
          <div className='no-thread-found'>
            <Chat />
            <div>No comments yet</div>
          </div>
        ) : null} */}

        {isChatMode && (
          <div
            className='thread-list'
            style={{
              height: '80vh',
            }}
          > 
            <div className='chat-container-thread' style={{padding: '10px', backgroundColor: 'rgb(236, 245, 255)'}}>
                <div className='thread-header' style={{padding: '0px'}}>
                  <div className='thread-left-section'>
                    <div
                      style={{marginTop: '5px'}}
                      className='back-icon cursor-pointer'
                      onClick={() => {
                        setIsChatMode(false);
                        const newSelectedThreadData = JSON.parse(JSON.stringify(selectedThread));
                        newSelectedThreadData.mediaTime = 0;
                        setSelectedThread(newSelectedThreadData);
                        let pathname = window.location.pathname;
                        const pageUrlParams = new URLSearchParams(window.location.search);
                        const pageParam: any = pageUrlParams.get('page');
                        if (pageParam) {
                          pathname = `${pathname}?page=${pageParam}`;
                        }
                        window.history.pushState({}, document.title, pathname);
                      }}
                    >
                      <BackCircleIcon />
                    </div>
                    {props.match.path === '/c/:id' && (
                      <div className='thread-canvas-time'>
                        {/* {getVideoDurationFormat(selectedThread.mediaTime)} */}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                      right: '25px',
                      display: 'flex',
                      background: '#cccccc',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '5px',
                      top: '15px',
                      borderRadius: '10px',
                    }}
                  >
                    {isMobile ? (
                      <DropDownCustom
                        buttonComponent={() => (
                          <div className='more-icon cursor-pointer'>
                            <TripleDot />
                          </div>
                        )}
                        position={'bottom'}
                        items={threadActionMenu()}
                      />
                    ) : (
                      <Dropdown overlay={threadActionMenu()}>
                        <div className='more-icon cursor-pointer'>
                          <TripleDot />
                        </div>
                      </Dropdown>
                    )}
                    {/* {<Tooltip
                      title={selectedThread.isResolve ? 'Un Resolve' : 'Resolve'}
                      placement='bottomLeft'
                    >
                      <div className='right-click cursor-pointer' onClick={resolveThread}>
                        <CircleRightClick />
                      </div>
                    </Tooltip>
                    */}
                  </div>
                </div>
              </div>
            <div
              style={{
                width: isMobile ? '100%' : undefined,
                height: '100%',
                paddingTop: isMobile ? '0px' : undefined,
              }}
              className='chat-container'
            >
              <OutreachCanvasChatWindow
                projectID={props.match.params.id}
                chatId={threadChatId}
                isWriteAccess={true}
                addMessageInThread={addMessageInThread}
                clearOldChat={clearOldChat}
                setClearOldChat={setClearOldChat}
                isOutreachCanvas={true}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const { type = '', canvasPassword } = canvasDetails;

  const checkPassword = () => {
    if (canvasPassword === inputPassword) {
      setShowPasswordField(false);
      setPasswordError('');
    } else {
      setPasswordError('Password is not Correct!');
    }
  };
  if (loading) {
    return (
      <div className='full-page-loader'>
        <img width='80' src='/images/small-loader.gif' alt='Zooc' />
      </div>
    );
  }
  if (error) {
    return error;
  }
  if (showPasswordField) {
    return (
      <div>
        <Logo />
        <div style={{ padding: '30px 0 10px 0' }}>This canvas is password protected!</div>
        <div className='d-flex'>
          <div style={{ width: '350px' }}>
            <Input
              value={inputPassword}
              onChange={(e: any) => setInputPassword(e.target.value)}
              className='ant-input'
              type='password'
              placeholder='Password'
            />
            {/* <input value={inputPassword} onChange={(e: any) => setInputPassword(e.target.value)} placeholder='Type canvas password' /> */}
          </div>
        </div>
        <div style={{ color: 'red', marginBottom: 10 }}>{passwordError}</div>
        <Button className='add tag-btn' type='primary' onClick={checkPassword}>
          Verify
        </Button>
      </div>
    );
  }

  return <div className='canvas-detail-container'>{chatWindowRender()}</div>;
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    iframe: state.project.iframe,
    threads: state.canvas.filterThreads,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    canvasFilterThreadsAction: (threads: IThread[]) => dispatch(canvasFilterThreads(threads)),
    signOut: () => dispatch(signOutAction()),
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OutreachCanvas);
