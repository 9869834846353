import styled from '@emotion/styled';
import React from 'react';
import { IMasterProject, IProject } from '../../types/projectTypes';
import { grid } from '../constants';
import InnerCanvasList from './InnerCanvasList';

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: calc(100vh - 230px);
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: ${grid}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

interface IInnerListProps {
  canvases: IProject[];
  dropProvided: any;
  selectedMasterProject: IMasterProject;
  history: any;
}

export default class InnerList extends React.Component<IInnerListProps, any> {
  render() {
    const { canvases, dropProvided, selectedMasterProject, history } = this.props;
    return (
      <Container>
        <DropZone ref={dropProvided.innerRef}>
          <InnerCanvasList
            history={history}
            canvases={canvases}
            selectedMasterProject={selectedMasterProject}
          />
          {dropProvided.placeholder}
        </DropZone>
      </Container>
    );
  }
}