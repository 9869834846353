import { Alert, Drawer, Form, Typography } from 'antd';
import firebase from 'firebase';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { trackAnalytics } from 'utils';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { statsAction } from '../../store/actions/authActions';
import { mobileCheck } from '../../utils';
import { Close } from '../svg';
import './style.css';

const isMobile = mobileCheck();

interface IProps {
  onCloseDrawer: () => void;
  visible: boolean;
  profile: any;
  form: any;
  userId: string;
  openInviteMemberModal: any;
}

const Redemption: React.FC<IProps> = ({
  onCloseDrawer,
  visible,
  profile,
  form: { getFieldDecorator, validateFieldsAndScroll },
  userId,
  openInviteMemberModal,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const submit = () => {
    if (!profile.usedAppSumoCode || profile.usedAppSumoCode <= 10) {
      validateFieldsAndScroll((err: any, values: any) => {
        if (!err) {
          setLoading(true);
          const firestore = firebase.firestore();
          firestore
            .collection('redemption')
            .doc(values.code)
            .get()
            .then((doc: any) => {
              if (!doc.exists) {
                setIsError(true);
                setSuccess(false);
                setLoading(false);
              } else {
                if (doc.data().isUsed) {
                  setIsError(true);
                  setSuccess(false);
                  setLoading(false);
                } else {
                  if (profile.isManagedUserQuotaInOrganization) {
                    firestore
                      .collection('organization')
                      .where('created_by', '==', userId)
                      .get()
                      .then((organizationDetail: any) => {
                        if (
                          organizationDetail &&
                          organizationDetail.docs &&
                          organizationDetail.docs.length
                        ) {
                          const organizationDetailDocs = organizationDetail.docs[0];
                          const organizationDetailData = organizationDetailDocs.data();
                          const organizationDetailId = organizationDetailDocs.id;
                          if (organizationDetailData.usedAppSumoCode <= 10) {
                            if (organizationDetailData.plan === 'digitalAgency') {
                              // 157286400 = 150 gb
                              // 104857600 = 100 gb
                              // 1048576 = 1 gb

                              const userQuota = organizationDetailData.userQuota;
                              if (organizationDetailData.usedAppSumoCode === 9) {
                                userQuota.storageLimit =
                                  1073741824 -
                                  (userQuota.maxStorageLimit - userQuota.maxStorageLimit);
                                userQuota.maxStorageLimit = 1073741824;
                              } else {
                                userQuota.storageLimit = organizationDetailData.usedAppSumoCode
                                  ? organizationDetailData.userQuota.maxStorageLimit +
                                    104857600 -
                                    (userQuota.maxStorageLimit - userQuota.maxStorageLimit)
                                  : 157286400 -
                                    (userQuota.maxStorageLimit - userQuota.maxStorageLimit);
                                userQuota.maxStorageLimit = organizationDetailData.usedAppSumoCode
                                  ? organizationDetailData.userQuota.maxStorageLimit + 104857600
                                  : 157286400;
                              }
                              userQuota.totalUser = 10 + userQuota.totalUser;

                              const usedAppSumoCode =
                                1 +
                                (organizationDetailData.usedAppSumoCode
                                  ? organizationDetailData.usedAppSumoCode
                                  : 2);
                              firestore
                                .collection('redemption')
                                .doc(values.code)
                                .update({
                                  isUsed: true,
                                  userId,
                                });
                              firestore
                                .collection('organization')
                                .doc(organizationDetailId)
                                .update({
                                  userQuota,
                                  usedAppSumoCode,
                                  appSumoId: values.code,
                                });
                            } else {
                              const userPlan =
                                organizationDetailData.plan === 'freelancer'
                                  ? 'productTeam'
                                  : 'digitalAgency';
                              firestore
                                .collection('pricingQuota')
                                .get()
                                .then((snapshot: any) => {
                                  const pricingData = snapshot.docs.map((pricingQuotaDoc: any) =>
                                    pricingQuotaDoc.data(),
                                  );
                                  if (pricingData && pricingData[0] && pricingData[0][userPlan]) {
                                    const userQuota = pricingData[0][userPlan];
                                    userQuota.maxCanvasesCount = userQuota.canvasesCount;
                                    userQuota.maxInboundCallLimit = userQuota.inboundCallLimit;
                                    userQuota.maxOutboundCallLimit = userQuota.outboundCallLimit;
                                    userQuota.maxChromeExtensionScreenshots =
                                      userQuota.chromeExtensionScreenshots;
                                    userQuota.maxStorageLimit = userQuota.storageLimit;
                                    userQuota.maxTotalVoiceClips = userQuota.totalVoiceClips;
                                    userQuota.maxTotalVideoComments = userQuota.totalVideoComments;
                                    userQuota.maxTotalScreenRecordings =
                                      userQuota.totalScreenRecordings;
                                    userQuota.maxTotalUser = userQuota.totalUser;

                                    if (userPlan === 'digitalAgency') {
                                      userQuota.maxChromeExtensionScreenshots = -1;
                                      userQuota.maxTotalVoiceClips = -1;
                                      userQuota.maxTotalVideoComments = -1;
                                      userQuota.maxTotalScreenRecordings = -1;
                                      userQuota.chromeExtensionScreenshots = 100000000;
                                      userQuota.totalVoiceClips = 100000000;
                                      userQuota.totalVideoComments = 100000000;
                                      userQuota.totalScreenRecordings = 100000000;
                                    }
                                    firestore
                                      .collection('redemption')
                                      .doc(values.code)
                                      .update({
                                        isUsed: true,
                                        userId,
                                      });
                                    const usedAppSumoCode =
                                      1 +
                                      (organizationDetailData.usedAppSumoCode
                                        ? organizationDetailData.usedAppSumoCode
                                        : 2);
                                    firestore
                                      .collection('organization')
                                      .doc(organizationDetailId)
                                      .update({
                                        userQuota,
                                        usedAppSumoCode,
                                        appSumoId: values.code,
                                        plan: userPlan,
                                      });
                                  }
                                });
                            }
                            statsAction('APPSUMO_CODE_USED', firestore);
                            trackAnalytics('Settings', 'Redeemed App Sumo Code');
                            setIsError(false);
                            setSuccess(true);
                            setLoading(false);
                          }
                        }
                      });
                  } else if (
                    profile.plan === 'digitalAgency' &&
                    (!profile.usedAppSumoCode || profile.usedAppSumoCode <= 10)
                  ) {
                    // 157286400 = 150 gb
                    // 104857600 = 100 gb
                    // 1048576 = 1 gb
                    const userQuota = profile.userQuota;
                    if (profile.usedAppSumoCode === 9) {
                      userQuota.storageLimit =
                        1073741824 - (userQuota.maxStorageLimit - userQuota.maxStorageLimit);
                      userQuota.maxStorageLimit = 1073741824;
                    } else {
                      userQuota.storageLimit = profile.usedAppSumoCode
                        ? profile.userQuota.maxStorageLimit +
                          104857600 -
                          (userQuota.maxStorageLimit - userQuota.maxStorageLimit)
                        : 157286400 - (userQuota.maxStorageLimit - userQuota.maxStorageLimit);
                      userQuota.maxStorageLimit = profile.usedAppSumoCode
                        ? profile.userQuota.maxStorageLimit + 104857600
                        : 157286400;
                    }
                    userQuota.totalUser = 10 + userQuota.totalUser;
                    const usedAppSumoCode =
                      1 + (profile.usedAppSumoCode ? profile.usedAppSumoCode : 2);

                    firestore
                      .collection('redemption')
                      .doc(values.code)
                      .update({
                        isUsed: true,
                        userId,
                      });
                    firestore
                      .collection('users')
                      .doc(userId)
                      .update({
                        userQuota,
                        usedAppSumoCode,
                      });
                  } else {
                    const userPlan =
                      profile.plan === 'freelancer' ? 'productTeam' : 'digitalAgency';
                    firestore
                      .collection('pricingQuota')
                      .get()
                      .then((snapshot: any) => {
                        const pricingData = snapshot.docs.map((docTemp: any) => docTemp.data());
                        if (pricingData && pricingData[0] && pricingData[0][userPlan]) {
                          const userQuota = pricingData[0][userPlan];
                          userQuota.maxCanvasesCount = userQuota.canvasesCount;
                          userQuota.maxInboundCallLimit = userQuota.inboundCallLimit;
                          userQuota.maxOutboundCallLimit = userQuota.outboundCallLimit;
                          userQuota.maxChromeExtensionScreenshots =
                            userQuota.chromeExtensionScreenshots;
                          userQuota.maxStorageLimit = userQuota.storageLimit;
                          userQuota.maxTotalVoiceClips = userQuota.totalVoiceClips;
                          userQuota.maxTotalVideoComments = userQuota.totalVideoComments;
                          userQuota.maxTotalScreenRecordings = userQuota.totalScreenRecordings;
                          userQuota.maxTotalUser = userQuota.totalUser;

                          if (userPlan === 'digitalAgency') {
                            userQuota.maxChromeExtensionScreenshots = -1;
                            userQuota.maxTotalVoiceClips = -1;
                            userQuota.maxTotalVideoComments = -1;
                            userQuota.maxTotalScreenRecordings = -1;
                            userQuota.chromeExtensionScreenshots = 100000000;
                            userQuota.totalVoiceClips = 100000000;
                            userQuota.totalVideoComments = 100000000;
                            userQuota.totalScreenRecordings = 100000000;
                          }
                          firestore
                            .collection('redemption')
                            .doc(values.code)
                            .update({
                              isUsed: true,
                              userId,
                            });
                          firestore
                            .collection('users')
                            .doc(userId)
                            .update({
                              plan: userPlan,
                              userQuota,
                            });
                        }
                      });
                  }
                  statsAction('APPSUMO_CODE_USED', firestore);
                  trackAnalytics('Settings', 'Redeemed App Sumo Code');
                  setIsError(false);
                  setSuccess(true);
                  setLoading(false);
                }
              }
            });
        }
      });
    }
  };
  return (
    <div>
      <Drawer
        title='AppSumo'
        placement='right'
        closable={false}
        onClose={() => onCloseDrawer()}
        visible={visible}
        style={{ position: 'absolute', overflowY: 'auto' }}
        width={isMobile ? '90vw' : '75vw'}
        bodyStyle={{ marginLeft: isMobile ? '20px' : undefined }}
      >
        <div className='drawer-close-btn' onClick={() => onCloseDrawer()}>
          <Close />
        </div>
        <ColorContext.Consumer>
          {({ theme }) => (
            <div
              className={
                isMobile
                  ? 'edit-profile-container-mobile'
                  : 'edit-profile-container edit-redemption-container'
              }
              style={{ color: theme.text.color2 }}
            >
              <div className='appsomo-message'>
                If you're one of the lucky few that purchased more than one AppSumo code, then
                you've come to the right place. Simply enter your second code in the form below and
                we'll stack it on the top of your existing account to upgrade it to an Agency
                package.
              </div>
              <Form className='profile-input'>
                <Typography.Text strong={true}>Redeem new AppSumo code</Typography.Text>
                <Form.Item>
                  {getFieldDecorator('code', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'Please input AppSumo code!',
                        whitespace: true,
                      },
                    ],
                  })(<Zinput placeholder='AppSumo code' />)}
                </Form.Item>
                <div className='profile-btn-container'>
                  <Zbutton
                    primary
                    btnColors={theme}
                    onClick={submit}
                    loading={loading}
                    disabled={loading || profile.usedAppSumoCode === 10}
                  >
                    REDEEM
                  </Zbutton>
                  {profile.plan !== 'freelancer' && profile.isInitialUser && (
                    <Zbutton
                      onClick={openInviteMemberModal}
                      primary
                      btnColors={theme}
                      style={{ 'font-size': '14px' }}
                    >
                      Invite Users
                    </Zbutton>
                  )}
                </div>
                <Form.Item>
                  {errorMessage && (
                    <Alert
                      className='err-alert'
                      message='Changes Failed'
                      description={errorMessage}
                      type='error'
                      closable
                      showIcon
                      onClose={() => setErrorMessage('')}
                    />
                  )}
                  {success && (
                    <Alert
                      className='err-alert'
                      message={`Congratulations!`}
                      description={`Your AppSumo Code has been redeemed successfully and your account has been upgraded to Agency package.`}
                      type='success'
                      closable
                      showIcon
                      onClose={() => setSuccess(false)}
                    />
                  )}
                  {isError && (
                    <Alert
                      className='err-alert'
                      message={`Sorry!`}
                      description={`Your AppSumo Code has been expired or invalid, please try another.`}
                      type='error'
                      closable
                      showIcon
                      onClose={() => setIsError(false)}
                    />
                  )}
                </Form.Item>
              </Form>
            </div>
          )}
        </ColorContext.Consumer>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: { firebase: { auth: any } }) => {
  return {
    userId: state.firebase.auth.uid,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(Form.create<IProps>()(Redemption));
