import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Checkbox } from 'antd';
import { Link } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/storage';
import {
  createProject as createProjectAction,
  reset as resetAction,
} from '../../store/actions/projectActions';
// import RecordAudio from '../projects/Chat/RecordAudio';
import './style.css';
import { mobileCheck } from '../../utils';
import { signIn as signInAction } from '../../store/actions/authActions';
import smartgeeksLogo from '../../static/smarkgeeks-logo.svg';
import { Logo } from '../svg/Logo';
// import { transcript } from '../../store/actions/recordingAction';
const { REACT_APP_FIREBASE_CLOUD_FUNCTION_URL } = process.env;

interface Project {
  title: string;
  project_url: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
}

interface ICreds {
  email: string;
  password: string;
}

const CallbackCanvas = (props: any) => {
  const submitBtn: any = useRef(null);
  const [isSubmittedForm, setIsSubmittedForm] = useState(false);
  const [checkedTermAndCond, setCheckedTermAndCond] = useState(false);
  const [isAllowAccess, setIsAllowAccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isShowCallbackRequestMsg, setIsShowCallbackRequestMsg] = useState(false);
  // @ts-ignore
  const params = (new URL(document.location)).searchParams;
  const isSmartGeeks = params.get("sm") || false;
  // const [isAllowMicrophonePermission, setIsAllowMicrophonePermission] = useState(true);
  const { createProject, form } = props; // history, 

  //   const selectedWorkflowId = 'open-1dd67dc9-caac-4fd1-bf1d-b8f3b9797d2f';
  //   const selectedMasterProjectId = 'selectedMasterProjectId== hKyDrhnVQsZVWSnnobMC';
  //   const memberEmail = 'manrajparmar793+raj1@gmail.com';
  //   const selectedMasterProject = {
  //     member_ids: ['zfLjovANzjaBcItZ4WsgmxHy84231'],
  //   };
  const selectedWorkflowId = props.match.params.workflowId || '664d3444-5685-4577-aa6f-99fddda4dfd3'; // interview canvas workflow id
  // const selectedWorkflowId = 'open-0e9136e8-8893-481e-9198-7cc753b4167f'; // dummpy
  // 'open-754507f5-fd5e-4bc0-9842-4593f3792468';
  // 'open-664d3444-5685-4577-aa6f-99fddda4dfd3';
  const selectedMasterProjectId = props.match.params.projectId || 'h6mJwL1cqZKOfA1Ktlvx'; // interview type master project id (need to confirm)
  // const selectedMasterProjectId = 'UrR8uwZo7yfjw3Y0KfJW'; // dummy
  const memberEmail = 'aemal@zooc.io';
  // const selectedMasterProject = {
  //   member_ids: ['sK4OQ22hYHcSWw4O0LPW3j8zY3K3'],
  // };

  console.log("selectedWorkflowId:", selectedWorkflowId)
  const isMobile = mobileCheck();
  const FormItem = Form.Item;
  const tailFormItemLayout = {
    wrapperCol: {
      //   sm: {
      //     offset: 4,
      //     span: 16,
      //   },
      //   xs: {
      //     offset: 0,
      //     span: 24,
      //   },
    },
  };

  useEffect(() => {
    false &&
      props.signIn({
        email: 'interviewer@gmail.com',
        password: 'interviewer@123',
      });
    // const firestore = firebase.firestore();
    //   firestore
    //     .collection('masterProjects')
    //     .doc(selectedMasterProjectId)
    //     .get()
    //     .then((doc: any) => {
    //     })
  }, []);

  useEffect(() => {
    if (selectedMasterProjectId) {
      const firestore = firebase.firestore();
      firestore
        .collection('masterProjects')
        .doc(selectedMasterProjectId)
        .get()
        .then(async (doc: any) => {
          const selectedMasterProject = doc.data();
          firestore
            .collection('organization')
            .where('created_by', '==', selectedMasterProject.created_by)
            .get()
            .then((organizationData: any) => {
              if (organizationData.docs.length) {
                const currentOrganizationData = organizationData.docs[0].data();
                let userQuota: any = currentOrganizationData.userQuota;
                if (userQuota && userQuota.outboundCallLimit && (userQuota.outboundCallLimit > 0 || userQuota.outboundCallLimit === -1)) {
                  setIsAllowAccess(true);
                  console.log("outboundCallLimit:", userQuota.outboundCallLimit);
                } else {
                  console.log("outbound quota has been reached")
                  setIsAllowAccess(false);
                }
              } else {
                console.log("organization not exist")
                setIsAllowAccess(false);
              }
            }).catch((error: any) => {
              console.log("error:", error)
              setIsAllowAccess(false);
            })
        }).catch((error: any) => {
          console.log("error:", error)
          setIsAllowAccess(false);
        })
    }
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setIsSubmittedForm(true);
    form.validateFields(async (err: any, values: any) => {
      console.log("err:", err);
      if (!err && checkedTermAndCond) {
        // clearAudio();
        //  createInterviewTask(audioURL);
        const firestore = firebase.firestore();
        firestore
          .collection('masterProjects')
          .doc(selectedMasterProjectId)
          .get()
          .then(async (doc: any) => {
            const selectedMasterProject = doc.data();
            const { fullName, email = '', phone = '' } = values;
            const firestore = firebase.firestore();
            const fullNameChat = await firestore.collection('chat').add({
              count: 1,
              messages: [
                {
                  attachedFileName: '',
                  attachment: '',
                  audioUrl: '',
                  blobURL: '',
                  email: '',
                  feedback: `Request callback user details: Phone: ${phone}, Name: ${fullName}, Email: ${email}`,
                  originalImage: '',
                  pageIDValue: '',
                  photoURL: '',
                  sender: '',
                  thumbnail: '',
                  time: new Date().getTime(),
                  uid: phone,
                  userAgent: '',
                  uuid: '51d6d78d-e246-49a0-b81d-d50f1asdfsdfse',
                  videoUrl: '',
                },
                // {
                //   attachedFileName: '',
                //   attachment: '',
                //   audioUrl: '',
                //   blobURL: '',
                //   email: '',
                //   feedback: `Name: ${fullName}`,
                //   originalImage: '',
                //   pageIDValue: '',
                //   photoURL: '',
                //   sender: fullName,
                //   thumbnail: '',
                //   time: new Date().getTime(),
                //   uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                //   userAgent: '',
                //   uuid: '51d6d78d-e246-49a0-b81d-d50f1ac9477b',
                //   videoUrl: '',
                // },
                // {
                //   attachedFileName: '',
                //   attachment: '',
                //   audioUrl: '',
                //   blobURL: '',
                //   email: '',
                //   feedback: `Email: ${email}`,
                //   originalImage: '',
                //   pageIDValue: '',
                //   photoURL: '',
                //   sender: fullName,
                //   thumbnail: '',
                //   time: new Date().getTime(),
                //   uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                //   userAgent: '',
                //   uuid: 'c42b6651-a07d-4a82-a570-bc57f966d8bf',
                //   videoUrl: '',
                // },
                // {
                //   attachedFileName: '',
                //   attachment: '',
                //   audioUrl: '',
                //   blobURL: '',
                //   email: '',
                //   feedback: `Phone: ${phone}`,
                //   originalImage: '',
                //   pageIDValue: '',
                //   photoURL: '',
                //   sender: fullName,
                //   thumbnail: '',
                //   time: new Date().getTime(),
                //   uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                //   userAgent: '',
                //   uuid: 'e943acad-e253-4a45-a164-a4837733caef',
                //   videoUrl: '',
                // },
                // {
                //   attachedFileName: '',
                //   attachment: '',
                //   audioUrl: '',
                //   blobURL: '',
                //   email: '',
                //   feedback: `Country: ${country}`,
                //   originalImage: '',
                //   pageIDValue: '',
                //   photoURL: '',
                //   sender: fullName,
                //   thumbnail: '',
                //   time: new Date().getTime(),
                //   uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                //   userAgent: '',
                //   uuid: 'dfc90c6c-58ec-418b-bbf4-6aca1d751c39',
                //   videoUrl: '',
                // },
                // {
                //   attachedFileName: '',
                //   attachment: '',
                //   audioUrl: '',
                //   blobURL: '',
                //   email: '',
                //   feedback: `Company: ${company}`,
                //   originalImage: '',
                //   pageIDValue: '',
                //   photoURL: '',
                //   sender: fullName,
                //   thumbnail: '',
                //   time: new Date().getTime(),
                //   uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                //   userAgent: '',
                //   uuid: 'dfc90c6c-58ec-418b-bbf4-6aca1d751c39',
                //   videoUrl: '',
                // },
                // {
                //   attachedFileName: '',
                //   attachment: '',
                //   audioUrl: '',
                //   blobURL: '',
                //   email: '',
                //   feedback: `Upwork/Freelancer Profile URL: ${upworkProfileURL}`,
                //   originalImage: '',
                //   pageIDValue: '',
                //   photoURL: '',
                //   sender: fullName,
                //   thumbnail: '',
                //   time: new Date().getTime(),
                //   uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                //   userAgent: '',
                //   uuid: 'dfc90c6c-58ec-418b-bbf4-6aca1d751c39',
                //   videoUrl: '',
                // },
              ],
            });
            // const emailChat = await firestore.collection('chat').add({
            //   messages: [],
            //   count: 0,
            // });
            // const phoneChat = await firestore.collection('chat').add({
            //   messages: [],
            //   count: 0,
            // });
            // const countryChat = await firestore.collection('chat').add({
            //   messages: [],
            //   count: 0,
            // });
            const projectData = {
              isOutboundCall: true,
              title: `${fullName}`,
              project_url: '',
              member_emails: [email],
              project_tags: [],
              type: 'text',
              // isInterviewCanvas: true,
              isPublicUser: true,
              workflowId: selectedWorkflowId,
              masterProjectId: selectedMasterProjectId,
              archived: 'no',
              isPublic: true,
              userNotes: `<p>Phone: ${phone}</p><p>Name: ${fullName}</p><p>Email: ${email}</p>`,
              threads: [
                {
                  createdAt: new Date().getTime(),
                  fromLeftPercentage: 7.352939241162668,
                  id: fullNameChat.id,
                  isResolve: false,
                  mediaTime: 1.173333,
                  member: {
                    email: memberEmail,
                    image: '',
                    name: `Inbound Call and Candidate Info`,
                  },
                  message: `Name: ${fullName}`,
                  page: null,
                  readUserEmails: [memberEmail],
                  replyCount: 0,
                  sequenceNo: 1,
                },
                // {
                //   createdAt: new Date().getTime(),
                //   fromLeftPercentage: 7.352939241162668,
                //   id: emailChat.id,
                //   isResolve: false,
                //   mediaTime: 1.173333,
                //   member: {
                //     email: memberEmail,
                //     image: '',
                //     name: 'Candidate Info',
                //   },
                //   message: `Email: ${email}`,
                //   page: null,
                //   readUserEmails: [memberEmail],
                //   replyCount: 0,
                //   sequenceNo: 2,
                // },
                // {
                //   createdAt: new Date().getTime(),
                //   fromLeftPercentage: 7.352939241162668,
                //   id: phoneChat.id,
                //   isResolve: false,
                //   mediaTime: 1.173333,
                //   member: {
                //     email: memberEmail,
                //     image: '',
                //     name: 'Candidate Info',
                //   },
                //   message: `Phone: ${phone}`,
                //   page: null,
                //   readUserEmails: [memberEmail],
                //   replyCount: 0,
                //   sequenceNo: 3,
                // },
                // {
                //   createdAt: new Date().getTime(),
                //   fromLeftPercentage: 7.352939241162668,
                //   id: countryChat.id,
                //   isResolve: false,
                //   mediaTime: 1.173333,
                //   member: {
                //     email: memberEmail,
                //     image: '',
                //     name: 'Candidate Info',
                //   },
                //   message: `Country: ${country}`,
                //   page: null,
                //   readUserEmails: [memberEmail],
                //   replyCount: 0,
                //   sequenceNo: 4,
                // },
              ],
            };
            createProject(projectData, '', selectedMasterProject).then(async (insertedId: any) => {
              console.log("success, inserted id:", insertedId);
              // await transcript(props.firebaseAuthToken, {
              //   audioURL: '',
              //   chatId: fullNameChat.id,
              //   inboudCall: true,
              //   selectedWorkflowId: selectedWorkflowId,
              //   selectedMasterProjectId: selectedMasterProjectId,
              //   selectedProjectId: insertedId
              // })
              setLoading(false);
              if (insertedId) {
                setIsShowCallbackRequestMsg(true);
//                fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/storeTranscript?isDummy=true`)
                // fetch(`http://localhost:4400/storeTranscript?isDummy=true`)
                // fetch(`${process.env.REACT_APP_PROXY_SERVER}/storeTranscript?isDummy=true`, {
                //   method: 'post',
                //   headers: {
                //     Accept: 'application/json',
                //     'Content-Type': 'application/json',
                //     'Access-Control-Allow-Origin': '*',
                //     // Authorization: `Bearer ${firebaseToken}`,
                //   },
                //   body: JSON.stringify({
                //     selectedMasterProjectId: selectedMasterProject.id,
                //     selectedWorkflowId: selectedWorkflowId,
                //     callbackProjectId: insertedId
                //   }),
                // })
                // .then((result) => result.json())
                // .then((result: any) => {
                //   console.log("result:", result)
                // })
                fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/batchCall`, {
                  method: 'post',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    // Authorization: `Bearer ${firebaseToken}`,
                  },
                  body: JSON.stringify({
                    selectedMasterProjectId: selectedMasterProject.id,
                    selectedWorkflowId: selectedWorkflowId,
                    callbackProjectId: insertedId,
                    authUserId: props.auth.uid
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    console.log("data", data)
                  })
                  .catch(() => {
                    return { error: true, message: 'Something went wrong.' };
                  });


                // history.push(`/text/${insertedId}?threadId=${fullNameChat.id}`);
              }
            }).catch((error: any) => {
              console.log("error:", error)
              setLoading(false);
            })
          });
      } else {
        setLoading(false);
      }
    });
  };

  if (!isAllowAccess) {
    return <p>Inbound call quota limit has been reached, Please contact to oranization admin to upgrade plan.</p>
  }

  if (isShowCallbackRequestMsg) {
    return <div style={{ fontFamily: 'Arial, sans-serif', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f0f0f0' }}>
      <div style={{ margin: '15px', maxWidth: '600px', padding: '20px', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
        <h1>Thank you for your callback request.</h1>
        <p>We have received your request for a callback. You will receive a call shortly.</p>
      </div>
    </div>
  }

  return (
    <div
      className='interview-page'
      style={
        isMobile
          ? { width: '90%', margin: '10px 20px' }
          : { width: '500px', display: 'grid', margin: 'auto' }
      }
    >

      {isSmartGeeks ?
        <img src={smartgeeksLogo} style={{ margin: '20px auto 0px auto' }} /> :
        <div style={{ margin: '18px auto 0px auto' }}><Logo /></div>
      }
      <p style={{ marginTop: isSmartGeeks ? '20px' : '10px' }}>
        <b>Instruction:</b> Please fill this form and click on the Submit button. Shortly our AI agent will call you to discuss the details.
      </p>
      <Form onSubmit={handleSubmit}>
        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('fullName', {
            rules: [
              {
                message: 'Full Name Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Full Name' />)}
        </FormItem>
        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('email', {
            rules: [
              {
                message: 'Email Please!',
                required: true,
                type: 'email',
              },
            ],
            initialValue: '',
          })(<Input className='ant-input' placeholder={`Email`} />)}
        </FormItem>
        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('phone', {
            rules: [
              {
                message: 'Phone Number Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Phone Number (+49...)' />)}
        </FormItem>
        <p style={{marginBottom: '10px', marginRight: 'auto', marginLeft: 'auto', width: isMobile ? '90%' : '450px', textAlign: 'left'}}>
          Provide phone number with country code using plus for example for Germany +49…
        </p> 

        <FormItem style={{margin: 0, padding: 0}}>
          <div
            className="logged-in-container"
            style={{ display: "flex", alignItems: "center", margin: 0, padding: 0 }}
          >
            <Checkbox
              style={{ padding: "0px 9px 0px 0px" }}
              onChange={(e: any) => {
                setCheckedTermAndCond(e.target.checked);
              }}
              checked={checkedTermAndCond}
            />
            <label
              className="check-label"
              onClick={() => {
                setCheckedTermAndCond(!checkedTermAndCond);
              }}
              style={{marginBottom: 0}}
            >
              I agree all{" "}
              <span>
                <Link
                  to="/terms-and-conditions"
                  style={{ color: "#000", textDecoration: "underline" }}
                >
                  terms and conditions.
                </Link>
              </span>
            </label>
          </div>
        </FormItem>
        {!checkedTermAndCond && isSubmittedForm && (
          <p
            style={{ color: "#d32f2f", marginLeft: "14px" }}
          >
            Please tick term and conditions checkbox to proceed.
          </p>
        )}

        {/* <InterviewAudioRecorder
          blobObject={blobObject}
          setBlobObject={setBlobObject}
          timer={timer}
          setTimer={setTimer}
          isRecording={isRecording}
          setRecording={setRecording}
          loading={loading}
          setLoading={setLoading}
          percentage={percentage}
          setPercentage={setPercentage}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
          //  setIsAllowMicrophonePermission={setIsAllowMicrophonePermission}
          maxRecordTime={2}
          projectID={'dasdasd'}
          //  setLoading={() => null}
          onClose={() => null}
          showPercentage
          onSubmit={createInterviewTask}
          background={'rgb(236, 245, 255)'}
        /> */}
        <input style={{ visibility: 'hidden' }} type='submit' value="Callback" ref={submitBtn} />

        {/* {false && ( */}
        { loading ? <div className='gif-loader' />
        : <Form.Item>
          <Button disabled={loading} ref={submitBtn} type='primary' htmlType='submit' style={{ background: `rgb(0, 29, 173)`, width: '50%', height: '50px', 'fontSize': '18px' }}>
            Submit
          </Button>
        </Form.Item>
        }
        {/* )} */}
        {/* { !isAllowMicrophonePermission && <>
                <div>
                Unable to access microphone, please allow microphone permission in order to record audio and try again with refresh the page
                </div>
                <img style={
                    isMobile
                        ? { width: '90%', marginTop: '10px' }
                        : { width: '500px', marginTop: '10px' }
                } src="/images/allow-microphone.png" alt="Allow microphone" />
                <img style={
                    isMobile
                        ? { width: '90%', margin: '10px 20px' }
                        : { width: '500px', display: 'grid', margin: 'auto' }
                } src="/images/allow-microphone-in-zooc.png" alt="Allow microphone in zooc" />
                </>} */}
      </Form>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    propsLoading: state.project.loading,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetLoader: () => dispatch(resetAction()),
    signIn: (creds: ICreds) => dispatch(signInAction(creds)),
    createProject: (project: Project, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create()(CallbackCanvas as any)) as any;



