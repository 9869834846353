import {
  Button,
  Drawer,
  Form,
  Icon,
  Input,
  Modal,
  // Progress,
  Radio,
  Select,
  Tooltip,
} from 'antd';
import firebase from 'firebase/app';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import {
  addVideoProject as addVideoProjectAction,
  createProject as createProjectAction,
  editProject as editProjectAction,
  reset as resetAction,
} from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import { InputEvent } from '../../types/syntheticEventsTypes';
import { defaultTag } from '../../utils';
import {
  addThumnailURLInProject,
  createThumbnailImg,
  isDataURL,
  mobileCheck,
  sendImageToStore,
  trackAnalytics,
} from '../../utils';
import RecordAudioFile from '../recording/RecordAudioFile';
import VideoRecord from '../recording/VideoRecord';
import EmailMultipleSelect from '../shared/EmailMultipleSelect';
import ImageDragDrop from '../shared/ImageDragDrop';
import TagsMultipleSelect from '../shared/TagsMultipleSelect';
import TeamList from '../team';
const firebaseInstance: any = firebase;
const { REACT_APP_IS_AWS_REAL_TIME_SAVING = 'true' } = process.env;
const isAWSRealTimeSaving = REACT_APP_IS_AWS_REAL_TIME_SAVING.trim() === 'true' ? true : false;

const { Option } = Select;
const isMobile = mobileCheck();

interface MemberProps {
  email: string;
  status: string;
  name: string;
}

interface TagsProps {
  tags: string;
  status: string;
  name: string;
}

interface MemberOptionProps {
  email: string;
  name: string;
}

interface TagsOptionProps {
  tags: string;
  name: string;
}

interface Project {
  title: string;
  project_url: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
  imageType: string;
  jiraIssueUrl: string;
  trelloIssueUrl: string;
  project_base64_image: any;
  appendVideo: string;
  thumnail_url: any;
}
interface IDispatchProps {
  resetLoader: () => any;
  createProject: (param: Project, firebaseAuthToken: string) => any;
  editProject: (
    id: string,
    title: string,
    member_emails: string[],
    project_tags: string[],
    type: string,
    imageType: string,
    jiraIssueUrl: string,
    trelloIssueUrl: string,
  ) => any;
}

interface IDispatchState {
  propsLoading: boolean;
  profile: any;
  firebaseAuthToken: string;
}

interface IProps {
  isOpenSteps?: boolean | undefined;
  closeCreateProject?: any;
  memberEMailEditable: boolean;
  visible: boolean;
  setVisible: (param: boolean) => void;
  title: string;
  edit?: boolean;
  project?: {
    id: string;
    project_url: string;
    title: string;
    member_emails: string[];
    project_tags: string[];
    type: string;
    imageType?: string;
    jiraIssueUrl: string;
    trelloIssueUrl: string;
  };
  addVideoProject?: any;
  organization?: any;
}
type ComponentProps = IDispatchState & IDispatchProps & IProps;
export const ProjectForm: FunctionComponent<ComponentProps> = ({
  closeCreateProject,
  visible,
  setVisible,
  title,
  edit,
  createProject,
  editProject,
  project,
  propsLoading,
  profile,
  memberEMailEditable,
  resetLoader,
  addVideoProject,
  firebaseAuthToken,
  organization,
}): JSX.Element => {
  let firebaseToken = '';
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = organization.selectOrganizationDetails.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  const titleRef: any = useRef(null);
  const [allEmails, setAllEmails] = useState<any>([]);
  const [allTags, setAllTags] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageThumbnailUrl, setImageThumbnailUrl] = useState('');
  const [videoLocalUrl, setVideoLocalUrl] = useState('');

  const [id, setId] = useState('');
  const [projectTitle, setProjectTitle] = useState('');
  const [projectType, setProjectType] = useState('image');
  const [memberEmails, setMemberEmails] = useState<any>([]);
  const [projectTags, setProjectTags] = useState<any>([]);
  const [invalidEmailsError, setInvalidEmailsError] = useState('');
  const [invalidTagsError, setInvalidTagsError] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isBase64ImageValid, setIsBase64ImageValid] = useState(true);
  const [imageType, setImageType] = useState(isMobile ? 'computer' : 'base64');
  const [sendingImage, setSendingImage] = useState(false);
  const [jiraIssueUrl, setJiraIssueUrl] = useState('');
  const [trelloIssueUrl, setTrelloIssueUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [isRocording, setIsRecording] = useState(false);
  const [thumbnailBlob, setThumbnailBlob] = useState('');
  const [appendVideo, setAppendVideo] = useState('');
  const [localAudioUrl, setLocalAudioUrl] = useState('');
  // const [percentage, setPercentage] = useState(0);
  // const [sendingAudio, setSendingAudio] = useState(false);
  const [projectS3URL, setProjectS3URL] = useState('');
  const REACT_APP_IS_BETA =
    process &&
    process.env &&
    process.env.REACT_APP_IS_BETA &&
    process.env.REACT_APP_IS_BETA.trim() === 'true'
      ? true
      : false;
  const onDrawerClose = () => {
    setDrawerVisible(false);
  };
  useEffect(() => {
    // if (visible && isOpenSteps) {
    //   setRun(true);
    //   setStepIndex(0);
    // }
    resetLoader();
    if (projectType === 'image') {
      titleRef && titleRef.current && titleRef.current.focus && titleRef.current.focus();
    }
  }, [visible]);

  useEffect(() => {
    if (project) {
      setId(project.id);
      setProjectTitle(project.title);
      const allMemberEmails = project.member_emails || [];
      const allProjectTags = project.project_tags || [];
      setAllEmails(allMemberEmails);
      setAllTags(allProjectTags);
      setMemberEmails(allMemberEmails);
      setProjectTags(allProjectTags);
      setProjectType(project.type ? project.type : 'image');
      setImageType(project.imageType ? project.imageType : 'base64');
      setJiraIssueUrl(project.jiraIssueUrl ? project.jiraIssueUrl : '');
      setTrelloIssueUrl(project.trelloIssueUrl ? project.trelloIssueUrl : '');
      if (project && project.type === 'image') {
        setImageUrl(project.project_url);
      } else if (project && project.type === 'video') {
        setProjectS3URL(project.project_url ? project.project_url : '');
        setUrl(project.project_url);
      } else {
        setUrl(project.project_url);
      }
    }
  }, [project]);

  useEffect(() => {
    if (!propsLoading && loading) {
      handleOk();
    } else {
      setLoading(propsLoading);
    }
  }, [propsLoading]);

  const handleOk = () => {
    closeCreateProject && closeCreateProject();
    setLoading(false);
    setVisible(false);
    setImageUrl('');
    setUrl('');
    setId('');
    setProjectTitle('');
    setMemberEmails([]);
    setProjectTags([]);
    setAllEmails([]);
    setAllTags([]);
    setProjectType('image');
    setImageType('base64');
    setVideoUrl('');
    // setSendingAudio(false);
    // setPercentage(0);
    setLocalAudioUrl('');
  };

  const handleSubmit = async (firebaseAudioUrl?: any) => {
    const currentProjectTitle = projectTitle
      ? projectTitle
      : projectType === 'image'
      ? 'Image'
      : projectType === 'liveWebsite'
      ? 'Live Website'
      : projectType === 'video'
      ? 'Screen Recording'
      : projectType === 'webcam'
      ? 'Video Recording'
      : projectType === 'audio'
      ? 'Audio Recording'
      : 'White Board';
    if (!edit) {
      const firebaseVideoUrl = projectS3URL;
      if (!isAWSRealTimeSaving && (projectType === 'video' || projectType === 'webcam')) {
        addVideoProject({
          videoLocalUrl,
          projectType,
          currentProjectTitle,
          allTags,
          appendVideo: appendVideo || '',
        });
        handleOk();
      } else {
        trackAnalytics('Canvas', 'Created a canvas', projectType);
        const projectData: any = {
          title: currentProjectTitle,
          project_url:
            projectType === 'image'
              ? imageUrl
              : projectType === 'liveWebsite'
              ? url
              : projectType === 'video'
              ? firebaseVideoUrl
              : projectType === 'webcam'
              ? firebaseVideoUrl
              : projectType === 'audio'
              ? firebaseAudioUrl
              : window.location.origin.replace('app.', '')+'/whiteboard/',
          member_emails: memberEmails || [],
          project_tags: allTags || [],
          type: projectType,
          imageType,
          jiraIssueUrl: '',
          trelloIssueUrl: '',
          project_base64_image: thumbnailBlob,
          appendVideo,
          blobURL: videoUrl ? videoUrl : '',
        };

        if (imageThumbnailUrl) {
          projectData.thumnail_url = imageThumbnailUrl;
          setImageThumbnailUrl('');
        }

        createProject(projectData, firebaseAuthToken).then(async (insertedId: any) => {
          const projectTypeLabel = defaultTag(projectType || 'liveWebsite');
          if (insertedId) {
            const slackMessage = `${profile.firstName}${' '}${
              profile.lastName
            } created ${projectTypeLabel} type project ${currentProjectTitle}`;
            const notificationId = uuid();
            const projectURL = `${window.location.href}project/${insertedId}?notificationId=${notificationId}`;
            let userFirebaseAuthToken = firebaseAuthToken;
            const fiebaseInstance: any = firebase;
            if (
              !firebaseAuthToken &&
              fiebaseInstance &&
              fiebaseInstance.auth() &&
              fiebaseInstance.auth().currentUser
            ) {
              userFirebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
            }
            userFirebaseAuthToken &&
              sendMessageToSlack(
                userFirebaseAuthToken,
                `${slackMessage}`,
                insertedId,
                projectURL,
                profile.initials ? profile.initials : '',
                notificationId,
              );
            if (isAWSRealTimeSaving && videoUrl) {
              addThumnailURLInProject(videoUrl, insertedId);
            }
            handleOk();
          }
        });
      }
    } else {
      trackAnalytics('Canvas', 'Edited a canvas', projectType);
      editProject(
        id,
        currentProjectTitle,
        memberEmails,
        allTags,
        projectType,
        imageType,
        jiraIssueUrl,
        trelloIssueUrl,
      ).then(() => {
        handleOk();
      });
    }
  };

  const handleUrlChange = (e: InputEvent): void => {
    setUrl(e.target.value);
  };
  const onChangeProjectType = (e: any): void => {
    trackAnalytics('Canvas', `Changed project type`, e);
    setProjectType(e);
    if (e === 'image') {
      titleRef && titleRef.current && titleRef.current.focus && titleRef.current.focus();
    }
  };
  const updateValidEmails = (emails: any) => {
    setMemberEmails(emails);
  };
  const updateValidTags = (tags: any) => {
    setProjectTags(tags);
    if (tags.length > 0) {
      trackAnalytics('Canvas', 'Tags added', tags);
    }
  };

  const updateInvalidEmailsError = (error: any) => {
    setInvalidEmailsError(error);
  };
  const updateInvalidTagsError = (error: any) => {
    setInvalidTagsError(error);
  };

  const handleTitleChange = (e: InputEvent): void => {
    setProjectTitle(e.target.value);
  };

  const handleAppendVideo = (e: InputEvent): void => {
    setAppendVideo(e.target.value);
  };

  const updateAllEmails = (addedEmails: any) => {
    setAllEmails(addedEmails || []);
  };
  const updateAllTags = (addedTags: any) => {
    setAllTags(addedTags || []);
  };

  const memberEmailOptions: MemberOptionProps[] = [];
  if (profile && profile.emails) {
    const { emails } = profile;
    emails.forEach((obj: MemberProps) => {
      if (obj.status === 'ACTIVE') {
        memberEmailOptions.push({ email: obj.email, name: obj.name });
      }
    });
  }
  const projectTagsOptions: TagsOptionProps[] = [];
  if (profile && profile.tags) {
    const { tags } = profile;
    tags.forEach((obj: TagsProps) => {
      if (obj.status === 'ACTIVE') {
        projectTagsOptions.push({ tags: obj.tags, name: obj.name });
      }
    });
  }

  const checkDisabled = () => {
    if (edit && title) {
      return false;
    }
    if (projectType === 'image') {
      return !(imageUrl && title);
    } else if (projectType === 'whiteBoard') {
      return false;
    } else if (projectType === 'video' || projectType === 'webcam') {
      return !(videoLocalUrl || projectS3URL);
    } else if (projectType === 'audio' && localAudioUrl) {
      return false;
    } else {
      return !(url && title);
    }
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const onChangeImageType = (event: any) => {
    trackAnalytics('Canvaas', 'Image type changed', event.target.value);
    setImageType(event.target.value);
    setImageUrl('');
    if (projectType === 'image') {
      titleRef && titleRef.current && titleRef.current.focus && titleRef.current.focus();
    }
  };
  // const handleAudioSubmit = () => {
  //   setSendingAudio(true);
  // };
  const handleVideoUrl = async (videoBlobUrl: any, videoS3URL: string) => {
    if (isAWSRealTimeSaving) {
      setVideoUrl(videoBlobUrl);
      setProjectS3URL(videoS3URL);
    } else {
      setVideoUrl(videoBlobUrl);
      setVideoLocalUrl(videoBlobUrl);
    }
  };
  return (
    <>
      <Drawer
        title='Team Members'
        placement='right'
        closable={false}
        onClose={onDrawerClose}
        visible={drawerVisible}
        className={'sidebar-team-member-drawer'}
      >
        <TeamList />
      </Drawer>
      <Modal
        visible={visible}
        title={title}
        onOk={handleOk}
        width='500px'
        onCancel={() => {
          if (videoUrl || isRocording || localAudioUrl) {
            Modal.confirm({
              content: '',
              title: 'Are you sure, your recording will be lost?',
              async onOk() {
                firebaseToken = firebaseAuthToken;
                if (!firebaseToken) {
                  firebaseToken = await firebaseInstance.auth().currentUser.getIdToken(true);
                }
                handleOk();
              },
            });
          } else {
            handleOk();
          }
        }}
        footer={[
          <Button
            key='back'
            onClick={() => {
              if (videoUrl || isRocording || localAudioUrl) {
                Modal.confirm({
                  content: '',
                  title: 'Are you sure, your recording will be lost?',
                  async onOk() {
                    firebaseToken = firebaseAuthToken;
                    if (!firebaseToken) {
                      firebaseToken = await firebaseInstance.auth().currentUser.getIdToken(true);
                    }
                    handleOk();
                  },
                });
              } else {
                handleOk();
              }
            }}
          >
            Cancel
          </Button>,
          <Button
            className='step-create-project-submit'
            key='submit'
            type='primary'
            htmlType='submit'
            loading={loading}
            disabled={checkDisabled()}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <div
          className='container poject-form'
          onPaste={(e: any) => {
            if (projectType === 'image' && (imageType === 'base64' || imageType === 'computer')) {
              const items = e && (e.clipboardData || e.originalEvent.clipboardData).items;
              let blob = null;
              for (const item of items) {
                if (item.type.indexOf('image') === 0) {
                  blob = item.getAsFile();
                }
              }
              if (blob !== null) {
                setSendingImage(true);
                const reader = new FileReader();
                reader.onload = async (eData: any) => {
                  if (isDataURL(eData.target.result)) {
                    // Create Thubnail Here
                    const thumbnailBase64Img = await createThumbnailImg(eData.target.result);
                    setThumbnailBlob(thumbnailBase64Img);

                    const originalImg: any = await sendImageToStore(eData.target.result);
                    originalImg && setImageUrl(originalImg);
                    setSendingImage(false);
                  } else {
                    setIsBase64ImageValid(true);
                    setSendingImage(false);
                  }
                };
                reader.readAsDataURL(blob);
                e.stopPropagation();
                e.preventDefault();
              }
            }
          }}
        >
          <div className='select-project-type'>
            <Select
              disabled={edit}
              value={projectType}
              className={'project-type-selection'}
              defaultValue='liveWebsite'
              dropdownStyle={{ position: 'fixed' }}
              style={{ width: '100%' }}
              onChange={onChangeProjectType}
            >
              <Option value='image'>
                <Icon type='file-image' /> {'  Image'}
              </Option>
              {REACT_APP_IS_BETA && (
                <Option value='liveWebsite'>
                  <Icon type='global' /> {'  Live Website'}
                </Option>
              )}
              {REACT_APP_IS_BETA && (
                <Option value='whiteBoard'>
                  <Icon type='edit' /> {'  Whiteboard'}
                </Option>
              )}
              {!isMobile && (
                <Option value='video'>
                  <Icon type='fullscreen' />
                  {'  Screen Recording'}
                </Option>
              )}
              <Option value='webcam'>
                <Icon type='play-circle' />
                {'  Video Recording'}
              </Option>

              <Option value='audio'>
                <Icon type='audio' />
                {'  Audio Recording'}
              </Option>
            </Select>
          </div>
          {!isBase64ImageValid && (
            <p className='error'>
              String does not match format 'base64', Please paste proper base64 image.
            </p>
          )}
          <Form className='white'>
            <Form.Item style={{ display: projectType === 'liveWebsite' ? 'block' : 'none' }}>
              <Input
                className={'step-project-url'}
                type='text'
                id='project_url'
                onPressEnter={handleSubmit}
                onChange={handleUrlChange}
                value={url}
                placeholder='Website URL'
                required
                disabled={edit}
              />
            </Form.Item>

            <Radio.Group
              className={'pb-10 step-select-project-image-type'}
              disabled={edit}
              onChange={onChangeImageType}
              value={imageType}
              style={{ display: projectType === 'image' ? 'block' : 'none' }}
            >
              {!isMobile && (
                <Radio style={radioStyle} value={'base64'}>
                  Paste base64 image
                </Radio>
              )}
              <Radio style={radioStyle} value={'computer'}>
                Upload image from your {isMobile ? 'phone' : 'computer'}
              </Radio>
              <Radio style={radioStyle} value={'url'}>
                Upload image from a URL
              </Radio>
            </Radio.Group>

            <Form.Item className={projectType === 'image' ? 'mb-10' : ''}>
              <Input
                className={'step-project-title'}
                type='text'
                id='project_title'
                onChange={handleTitleChange}
                value={projectTitle}
                placeholder='Canvas Title'
                ref={titleRef}
                required
                autoFocus
              />
            </Form.Item>
          </Form>
          {(projectType === 'video' || projectType === 'webcam') &&
            profile.email &&
            profile.email.includes('@reactgeeks.com') && (
              <Input
                className={'step-project-title'}
                type='text'
                id='project_title'
                onChange={handleAppendVideo}
                value={appendVideo}
                placeholder='Append a video at the end'
                ref={titleRef}
                required
              />
            )}

          {projectType === 'image' && imageType === 'url' && (
            <Form.Item>
              <Input
                type='text'
                id='url-type-image'
                onChange={async (e: any) => {
                  setImageUrl(e.target.value);
                }}
                value={imageUrl}
                placeholder='Image URL'
                required
                disabled={edit}
              />
            </Form.Item>
          )}

          {projectType === 'image' && imageType === 'base64' && (
            <div className={'pb-10'}>
              <p>Paste base64 image </p>
              <div>Steps:</div>
              <div>
                1. Press{' '}
                {`${
                  /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
                    ? 'Ctrl+Shift+CMD+4'
                    : 'PrtScrn / Ctrl+PrtScrn / Windows+PrtScrn / Alt+PrtScrn / Fn+PrtScrn'
                }`}{' '}
                to take screenshot
              </div>
              <div>2. Paste it on this dialog</div>
              {sendingImage ? <div className='gif-loader small-loader' /> : ''}
              {imageUrl && (
                <div className={'drop-image-preview'}>
                  <img height={'50px'} src={imageUrl} />
                </div>
              )}
            </div>
          )}

          {projectType === 'image' && imageType === 'computer' && (
            <div className={'image-drag-drop'}>
              <ImageDragDrop
                edit={edit}
                url={imageUrl}
                sendFeedback={(image: any, thumbnailImage: any) => {
                  image && setImageUrl(image);
                  thumbnailImage && setImageThumbnailUrl(thumbnailImage);
                }}
                getThumbnailBlob={(blob: any) => {
                  setThumbnailBlob(blob);
                }}
              />
              {sendingImage ? <div className='gif-loader small-loader' /> : ''}
            </div>
          )}
          {memberEmailOptions && memberEmailOptions.length > 0 && (
            <>
              <div className={'step-share-with'}>
                <span>Share with:</span>
                <EmailMultipleSelect
                  allEmails={allEmails}
                  updateAllEmails={updateAllEmails}
                  memberEMailEditable={memberEMailEditable}
                  options={memberEmailOptions}
                  type={'multiple'}
                  emails={memberEmails}
                  updateValidEmails={updateValidEmails}
                  invalidEmailsError={invalidEmailsError}
                  updateInvalidEmailsError={updateInvalidEmailsError}
                />
              </div>

              <div>
                <p>
                  Type a team member name or{' '}
                  <span className={'invite-new-member'} onClick={() => setDrawerVisible(true)}>
                    invite new members
                  </span>
                </p>
              </div>
            </>
          )}

          {projectType === 'video' && !edit && (
            <VideoRecord
              loading={loading}
              isRecordModal={'screen'}
              sendToFirebase={handleVideoUrl}
              setIsRecording={setIsRecording}
              maxRecordTime={userQuota && userQuota.screenRecordingProjectTime}
              firebaseAuthToken={firebaseAuthToken}
              setVideoUrl={setVideoUrl}
            />
          )}

          {projectType === 'webcam' && !edit && (
            <VideoRecord
              loading={loading}
              isRecordModal={'video'}
              sendToFirebase={handleVideoUrl}
              maxRecordTime={userQuota && userQuota.webRecordingProjectTime}
              setIsRecording={setIsRecording}
              firebaseAuthToken={firebaseAuthToken}
              setVideoUrl={setVideoUrl}
            />
          )}
          {projectType === 'audio' && !edit && (
            <>
              <RecordAudioFile
                maxRecordTime={userQuota && userQuota.recordVoiceClipTime}
                large
                showPercentage
                loading={loading}
                setLoading={setLoading}
                onSubmitWithPercentage={(firebaseUrl: string) => {
                  setLoading(true);
                  handleSubmit(firebaseUrl);
                }}
              />
              {/* <MobileRecordAudio
                recordVoiceClipTime={userQuota.recordVoiceClipTime}
                onSubmit={handleSubmit} /> */}
              {/*
              <RecordAudio
                maxRecordTime={userQuota && userQuota.recordVoiceClipTime}
                onClose={() => null}
                projectID={'audioTypeProject'}
                hideCrossIcon
                setLoading={setLoading}
                showPercentage={true}
                onAudioStop={setLocalAudioUrl}
                setPercentage={(percentageTemp: any) => setPercentage(percentageTemp)}
                // onSubmit={(firebaseUrl: string) => setFirebaseAudioUrl(firebaseUrl)}
                onSubmitWithPercentage={(firebaseUrl: string) => {
                  handleSubmit(firebaseUrl);
                }}
                hideSendButton
                sendAudioCall={sendingAudio}
                firebaseAuthToken={firebaseAuthToken}
              />
              {percentage > 0 && (
                <Progress percent={percentage} status={percentage === 100 ? 'success' : 'active'} />
              )} */}
            </>
          )}
        </div>

        <div className={'step-tags'}>
          <span>Tags:</span>

          <span className='info-circle-icon m-l-5'>
            <Tooltip
              placement='top'
              title={'Provide as many terms as you want so you can search them later'}
            >
              <Icon className='tags-tooltip' type='info-circle' />
            </Tooltip>
          </span>
          <TagsMultipleSelect
            allTags={allTags}
            updateAllTags={updateAllTags}
            memberEMailEditable={memberEMailEditable}
            options={projectTagsOptions}
            type={'tags'}
            tags={projectTags}
            updateValidTags={updateValidTags}
            invalidTagsError={invalidTagsError}
            updateInvalidTagsError={updateInvalidTagsError}
          />
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, any>) => {
  return {
    resetLoader: () => dispatch(resetAction()),
    createProject: (project: Project, firebaseAuthToken: string) =>
      dispatch(createProjectAction(project, firebaseAuthToken)),
    editProject: (
      id: string,
      title: string,
      memberEmails: string[],
      project_tags: string[],
      type: string,
      imageType: string,
      jiraIssueUrl: string,
      trelloIssueUrl: string,
    ) =>
      dispatch(
        editProjectAction(
          id,
          title,
          memberEmails,
          project_tags,
          type,
          imageType,
          jiraIssueUrl,
          trelloIssueUrl,
        ),
      ),
    addVideoProject: (project: any) => dispatch(addVideoProjectAction(project)),
  };
};

const mapStateToProps = (state: any) => {
  return {
    propsLoading: state.project.loading,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    organization: state.organization,
  };
};

export default connect<IDispatchState, IDispatchProps, IProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectForm);
