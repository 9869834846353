import React from 'react';

export const AudioRecordIcon = () => {
    return <div className="audio-icon-wrapper-circle cursor">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1C11.2044 1 10.4413 1.31607 9.87868 1.87868C9.31607 2.44129 9 3.20435 9 4V12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V4C15 3.20435 14.6839 2.44129 14.1213 1.87868C13.5587 1.31607 12.7956 1 12 1Z" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 10V12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12V10" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 19V23" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 23H16" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </div>
}

export const AudioBackIcon = () => {
    return <div className="cursor">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 14L4 9L9 4" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 20V13C20 11.9391 19.5786 10.9217 18.8284 10.1716C18.0783 9.42143 17.0609 9 16 9H4" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </div>
}

export const AudioDeleteIcon = () => {
    return <div className="cursor">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 6H5H21" stroke="#EB5757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#EB5757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 11V17" stroke="#EB5757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 11V17" stroke="#EB5757" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    </div>
}

export const AudioPausedIcon = () => {
    return <div className="audio-icon-wrapper-circle cursor">
        <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="2" height="20" rx="1" fill="#333333" />
            <rect x="12" width="2" height="20" rx="1" fill="#333333" />
        </svg>
    </div>
}

export const AudioResumeIcon = () => {
    return <div className="audio-icon-wrapper-circle cursor">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#EB5757" />
        </svg>
    </div>
}

export const AudioStoppedIcon = () => {
    return <div className="audio-icon-wrapper-circle cursor">
        <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.89193 18.2865C2.40582 18.6024 1.91387 18.6209 1.41609 18.3419C0.918315 18.0628 0.668941 17.6312 0.667969 17.0469V1.95312C0.667969 1.36979 0.917343 0.938124 1.41609 0.658124C1.91484 0.378124 2.40679 0.396596 2.89193 0.713541L14.7773 8.26042C15.2148 8.55208 15.4336 8.96528 15.4336 9.5C15.4336 10.0347 15.2148 10.4479 14.7773 10.7396L2.89193 18.2865Z" fill="#333333" />
        </svg>
    </div>
}

export const AudioSendIcon = () => {
    return <div className="cursor">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5300_22559)">
            <path d="M12.1415 3V18.5563" stroke="#00239F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.1421 3L21.3345 22.0919L12.1421 18.5563L2.94975 22.0919L12.1421 3Z" stroke="#00239F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_5300_22559">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
    </div>
}

