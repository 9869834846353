import { Button, Drawer, Icon, Popover, Tooltip } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { trackAnalytics } from '../../utils';
import { mobileCheck } from '../../utils';
import SubscriptionPlan from '../subscription-plan';
import ProjectForm from './ProjectForm';

const isMobile = mobileCheck();

interface IStateProps {
  auth: { uid: string };
  profile: any;
  organization: any;
}
type ComponentProps = IStateProps & { isWriteAccess: boolean };
const CreateProject: FunctionComponent<ComponentProps> = ({
  auth,
  profile,
  isWriteAccess,
  organization,
}): JSX.Element => {
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = organization.selectOrganizationDetails.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  const [dialogState, setDialogState] = useState(false);
  const [visible, setVisible] = useState(false);
  const [subscriptionDrawerVisible, setSubscriptionDrawerVisible] = useState(false);
  const hide = () => {
    setVisible(false);
  };
  if (!auth.uid) {
    return <Redirect to='/signin' />;
  }

  const { created_at } = profile;
  const isAddProjectAccess =
    created_at &&
    isWriteAccess &&
    userQuota &&
    userQuota.canvasesCount &&
    (userQuota.canvasesCount <= userQuota.maxCanvasesCount || userQuota.canvasesCount === -1);
  const content = (
    <div>
      <p>
        {userQuota.canvasesCount > userQuota.maxCanvasesCount
          ? 'You have reached to maximum limit of current plan'
          : "You don't have access to create a canvas."}
      </p>
      <p>
        Please{' '}
        <a
          href='#'
          onClick={() => {
            hide();
            setSubscriptionDrawerVisible(true);
          }}
        >
          upgrade your plan
        </a>
      </p>
    </div>
  );
  return (
    <span style={{ marginRight: '15px' }}>
      <Drawer
        title='Subscription'
        placement='right'
        closable={false}
        onClose={() => {
          setSubscriptionDrawerVisible(false);
        }}
        visible={subscriptionDrawerVisible}
        className={'sidebar-team-member-drawer'}
      >
        <SubscriptionPlan />
      </Drawer>
      <Popover
        placement='bottomRight'
        content={content}
        title={'Create new Contact!'}
        visible={visible}
        onVisibleChange={(currentVisible) => {
          if (Object.keys(userQuota).length) {
            isAddProjectAccess ? setVisible(false) : setVisible(currentVisible);
          }
        }}
      >
        <Tooltip
          placement='bottom'
          title={isAddProjectAccess ? (isMobile ? 'Add New Canvas' : null) : null}
        >
          <Button
            disabled={isAddProjectAccess ? false : true}
            className={'step-add-new-project'}
            type='primary'
            shape='circle'
            size='large'
            onClick={() => {
              if (isAddProjectAccess) {
                trackAnalytics('Canvas', `Add project clicked`);
                isAddProjectAccess && setDialogState(true);
              }
            }}
          >
            <Icon
              onClick={() => isAddProjectAccess && setDialogState(true)}
              style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
              type='plus'
            />
          </Button>
        </Tooltip>
      </Popover>
      <ProjectForm
        memberEMailEditable={true}
        visible={dialogState}
        setVisible={setDialogState}
        title='Create New Contact'
      />
    </span>
  );
};
const mapStateToProps = (state: {
  firebase: { auth: { uid: string }; profile: any };
  auth: any;
  organization: any;
}) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    organization: state.organization,
  };
};

export default connect<IStateProps, never, {}>(mapStateToProps)(CreateProject);
