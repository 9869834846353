import 'ant-design-pro/dist/ant-design-pro.css';
import AvatarList from 'ant-design-pro/lib/AvatarList';
import { Avatar, Tooltip } from 'antd'; // Dropdown, Icon, Menu,
import ColorContext from 'context/ColorContext';
import moment from 'moment';
import React from 'react';
import { IThread } from 'types/projectTypes';
import {
  checkImageAndGetProjectURL,
  getDateToolTip,
  getProjectImageURL,
  mobileCheck,
  trackAnalytics,
} from '../../../utils';
import {
  Audio,
  AudioIcon,
  ChatIconSVG,
  ImagePlaceholder,
  PDFIcon,
  PdfSmallIcon,
  RecordVideoIcon,
  TextCanvasIcon,
  TextIcon,
  VideoIcon,
  VideoTaskIcon,
  WebsiteIcon,
  WhiteBoard,
  WhiteBoardIcon,
} from '../../svg';
import './style.css';

// const members = [
//   {
//     image: 'https://gw.alipayobjects.com/zos/rmsportal/zOsKZmFRdUtvpqCImOVY.png',
//     name: 'Test 1',
//   },
//   {
//     image: 'https://gw.alipayobjects.com/zos/rmsportal/sfjbOqnsXXJgNCjCzDBL.png',
//     name: 'Test 2',
//   },
//   {
//     image: '',
//     name: 'Test 3',
//   },
// ];

const ProjectTile = (props: any) => {
  const {
    projectDetail,
    history,
    masterProjectName,
    provided,
    snapshot,
    internalScroll,
    index,
  } = props;
  const { createdAtUTC } = projectDetail;
  const date = moment(createdAtUTC).fromNow() || '';
  const dateToolTip = getDateToolTip(createdAtUTC);
  const projectImageURL = getProjectImageURL(projectDetail);
  const isMobile = mobileCheck();

  const getProjectSVG = (projectType: string) => {
    let ProjectSVG: any = '';
    switch (projectType) {
      case 'audio':
        ProjectSVG = <Audio />;
        break;
      case 'whiteboard':
        ProjectSVG = <WhiteBoard />;
        break;
      case 'pdf':
        ProjectSVG = <PDFIcon />;
        break;
      case 'text':
        ProjectSVG = <TextCanvasIcon />;
        break;
      case 'video':
      case 'videoByURL':
        // videoRecord/videoUpload/videoByURL
        ProjectSVG = <VideoTaskIcon />;
        break;
      case 'videoRecord':
        ProjectSVG = <RecordVideoIcon />;
        break;
      case 'website':
        ProjectSVG = <WebsiteIcon />;
        break;
      case 'videoUpload':
        ProjectSVG = <VideoIcon />;
        break;
    }
    return ProjectSVG;
  };

  const getProjectThumbnailIcon = (projectType: string) => {
    let ProjectIcon: any = '';
    switch (projectType) {
      case 'audio':
        ProjectIcon = <AudioIcon />;
        break;
      case 'video':
      case 'videoByURL':
      case 'videoRecord':
        ProjectIcon = <VideoTaskIcon />;
        break;
      case 'videoUpload':
        ProjectIcon = <VideoIcon />;
        break;
      case 'whiteboard':
        ProjectIcon = <WhiteBoardIcon />;
        break;
      case 'pdf':
        ProjectIcon = <PdfSmallIcon />;
        break;
      case 'text':
        ProjectIcon = <TextIcon />;
        break;
      case 'website':
        ProjectIcon = <WebsiteIcon />;
        break;
      case 'image':
        ProjectIcon = <ImagePlaceholder />;
        break;
    }
    return ProjectIcon;
  };
  const { type, title, members = [], tags = [], threads = [], ticketNumber = 1 } = projectDetail;
  const threadUnResolveThreads = threads.filter((thread: IThread) => !thread.isResolve);

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div
          className={`${isMobile ? 'Mtile-container' : 'tile-container'} ${internalScroll &&
            snapshot.isDraggingOver &&
            'skewed'}`}
          onClick={(e: any) => {
            e.stopPropagation();
            trackAnalytics('Canvas', 'Project tile Clicked');
            history.push(checkImageAndGetProjectURL(projectDetail));
          }}
          key={index}
          ref={internalScroll ? provided.innerRef : null}
          {...(internalScroll ? { ...provided.draggableProps } : {})}
          {...(internalScroll ? { ...provided.dragHandleProps } : {})}
          style={{
            backgroundImage: `url(${projectImageURL})`,
            background: theme.backgrounds.color3,
            userSelect: 'none',
            ...(internalScroll ? { ...provided.draggableProps.style } : {}),
          }}
        >
          <div
            className={`${isMobile ? 'Mtile-image' : 'tile-image'} ${type === 'website' &&
              'website'} gray-background ${
              type === 'video' ||
              type === 'videoUpload' ||
              type === 'videoRecord' ||
              type === 'videoByURL'
                ? 'tile-video'
                : ''
            } ${type === 'audio' ? 'tile-audio' : ''} ${
              type === 'audio' || type === 'audio' || type === 'whiteboard' || type === 'pdf'
                ? 'gray-background'
                : ''
            }`}
            style={{
              backgroundImage: `url(${projectImageURL})`,
              backgroundColor: theme.backgrounds.color3,
            }}
          >
            {!projectImageURL && getProjectSVG(type && type.trim())}
          </div>
          <div className={`tile-content ${isMobile ? 'Mtile-content' : 'tile-content-component'}`}>
            <div className='top-section'>
              <div className='project-name'>
                <span className='project-title'>
                  {title ? title.slice(0, 40) + (title.length > 40 ? '...' : '') : 'Live website'}
                </span>
                <Tooltip title={dateToolTip} placement='topLeft'>
                  <span className='project-time'>{date}</span>
                </Tooltip>
              </div>
              <div className='project-thred'>
                <ChatIconSVG />
                <div className='project-thred-count'>{threadUnResolveThreads.length}</div>
              </div>
            </div>
            <div className='middle-section'>
              <div className='project-members'>
                <AvatarList size={15}>
                  {members.map((member: { name: string; image?: string }, memberIndex: number) => {
                    const { name = '', image } = member;
                    if (image) {
                      return <AvatarList.Item key={memberIndex} tips={name} src={image} />;
                    } else {
                      return (
                        <Tooltip title={name} key={memberIndex}>
                          <li className='antd-pro-avatar-list-avatarItem'>
                            <Avatar key={memberIndex}>
                              {`${name && name[0] && name[0].toUpperCase()}${
                                name && name[1] ? name[1].toUpperCase() : ''
                              }`}
                            </Avatar>
                          </li>
                        </Tooltip>
                      );
                    }
                  })}
                </AvatarList>
              </div>
              {/* <div style={{ position: 'absolute' }}>
                <Dropdown
                  overlay={
                    <Menu>

                    </Menu>
                  }
                  placement='topRight'
                >
                  <Icon onClick={(e) => e.stopPropagation()} type='more' />
                </Dropdown>
              </div> */}
            </div>
            <div className='third-section'>
              <div className='project-prefix'>
                <div className='project-prefix-svg'>
                  {getProjectThumbnailIcon(type && type.trim())}
                </div>
                <div className='project-prefix-label'>
                  {masterProjectName && masterProjectName[0].toUpperCase()}
                  {masterProjectName && masterProjectName[1]
                    ? masterProjectName[1].toUpperCase()
                    : ''}
                  -{ticketNumber}
                </div>
              </div>
              <div className='project-tag-container project-tile-tag'>
                {tags.slice(0, 10).map((tag: { title: string; color: string }, tagIndex: number) => {
                  const { color } = tag;
                  return (
                    <Tooltip title={tag.title} key={tagIndex}>
                      <div className='project-tag-color' style={{ backgroundColor: color }} />
                    </Tooltip>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </ColorContext.Consumer>
  );
};

export default ProjectTile;
