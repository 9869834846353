import { Avatar, Dropdown, Menu, Drawer } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { signOut as signOutAction } from '../../store/actions/authActions';
import { IAuthAction } from '../../types/authTypes';
import { IOrgProject } from '../../types/projectTypes';
import { trackAnalytics, showFullFeature } from '../../utils';
import { mobileCheck } from '../../utils';
import ProfileDrawer from '../auth/ProfileDrawer';
import UsageDrawer from '../auth/UsageDrawer';
import RedemptionDrawer from '../redemptions';
import UserDrawer from '../users';
import OrganizationDrawer from './branding/OrganizationDrawer';
import IntegrationDrawer from './IntegrationDrawer';
import { ContactUsDrawer } from '../shared/ContactUsDrawer';
import { SubscriptionDrawer } from '../shared/SubscriptionDrawer';

import './profilemenu.css';

const isMobile = mobileCheck();

interface IProps {
  profile: any;
  signOut?: () => void;
  getOrgProjects: () => void;
  organizationDetail: any;
  selectedOrgProject: IOrgProject;
  handleSelectedProject: (project: IOrgProject) => void;
  selfMasterProjects: any;
  changeOrganization: any;
  auth: any;
  selectedOrganizationIndex: number;
  openInviteMemberModal: any;
  selectedOrganizationData: any;
}

const ProfileMenu: React.FC<IProps> = ({
  profile,
  signOut,
  organizationDetail,
  selectedOrgProject,
  handleSelectedProject,
  getOrgProjects,
  selfMasterProjects,
  changeOrganization,
  auth,
  selectedOrganizationIndex,
  openInviteMemberModal,
  selectedOrganizationData,
}) => {
  const isShowFullFeature = showFullFeature();
  const [integrationDrawerVisible, setIntegrationDrawerVisible] = useState(false);
  const [profileDrawerVisible, setProfileDrawerVisible] = useState(false);
  const [usageDrawerVisible, setUsageDrawerVisible] = useState(false);
  const [redemptionDrawerVisible, setRedemptionDrawerVisible] = useState(false);
  const [orgDrawerVisible, setOrgDrawerVisible] = useState(false);
  const [userDrawerVisible, setUserDrawerVisible] = useState(false);
  const [contactUsDrawerVisible, setContactUsDrawerVisible] = useState(false);
  const [tutorialDrawerVisible, setTutorialDrawerVisible] = useState(false);
  const [subscriptionDrawerVisible, setSubscriptionDrawerVisible] = useState(false);

  const onCloseIntegrationDrawer = () => {
    trackAnalytics('Settings', 'Closed Integration Drawer');
    setIntegrationDrawerVisible(false);
  };

  const onCloseProfileDrawer = () => {
    trackAnalytics('Settings', 'Closed Profile Drawer');
    setProfileDrawerVisible(false);
  };

  const onCloseUsageDrawer = () => {
    trackAnalytics('Settings', 'Closed Usage Drawer');
    setUsageDrawerVisible(false);
  };

  const onCloseUserDrawer = () => {
    trackAnalytics('Settings', 'Closed User Drawer');
    setUserDrawerVisible(false);
  };

  const onCloseRedemptionDrawer = () => {
    trackAnalytics('Settings', 'Closed Redemption Drawer');
    setRedemptionDrawerVisible(false);
  };

  const onCloseOrgDrawer = () => {
    trackAnalytics('Settings', 'Closed Ogranization Drawer');
    setOrgDrawerVisible(false);
  };

  const logout = () => {
    trackAnalytics('Settings', 'Signout clicked');
    signOut!();
  };

  const { photoURL, initials = [] } = profile; // adminIds

  const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');
  let selectedOrganizationMatchedIndex = organizationDetail.findIndex(
    (og: any) => og.id === selectedOrganizationId,
  );
  if (selectedOrganizationMatchedIndex === -1) {
    selectedOrganizationMatchedIndex = 0;
  }
  
  const menu = (
    <Menu>
      <Menu.Item
        className='profile-dropdown-item'
        onClick={() => {
          trackAnalytics('Settings', 'Profile Option Clicked');
          setProfileDrawerVisible(true);
        }}
      >
        Profile
      </Menu.Item>
      { isShowFullFeature && <Menu.Item
        className='profile-dropdown-item'
        onClick={() => {
          trackAnalytics('Settings', 'Usage Option Clicked');
          setUsageDrawerVisible(true);
        }}
      >
        Usage
      </Menu.Item>
      }
      {/* {!(adminIds && adminIds[0]) && ( */}
      { isShowFullFeature && <Menu.Item
        className='profile-dropdown-item'
        onClick={() => {
          trackAnalytics('Settings', 'Users Option Clicked');
          setUserDrawerVisible(true);
        }}
      >
        Users
      </Menu.Item> }
      {/* )} */}
      { isShowFullFeature && <Menu.Item
        className='profile-dropdown-item'
        onClick={() => {
          trackAnalytics('Settings', 'AppSumo code Option Clicked');
          setRedemptionDrawerVisible(true);
        }}
      >
        AppSumo code
      </Menu.Item>
      }
      {isShowFullFeature && !isMobile && (
        <Menu.Item
          className='profile-dropdown-item'
          onClick={() => setIntegrationDrawerVisible(true)}
        >
          Integration
        </Menu.Item>
      )}
      {isShowFullFeature && <Menu.Item
        className='profile-dropdown-item'
        onClick={() => window.open('https://zooc.tawk.help/article/chrome-extension-beta', '_blank')}
      >
        Chrome Extension
      </Menu.Item>
      }
      { isShowFullFeature && <Menu.Item
        className='profile-dropdown-item'
        onClick={() => {
          trackAnalytics('Settings', 'Projects Option Clicked');
          setOrgDrawerVisible(true);
        }}
      >
        Projects
      </Menu.Item>
      }
      <Menu.Item
        className='profile-dropdown-item'
        onClick={() => {
          window.open(isShowFullFeature ? 'https://zooc.tawk.help' : 'mailto:a+supercalls@smartgeeks.io', '_blank');
        }}
      >
        Help
      </Menu.Item>
      { isShowFullFeature && organizationDetail && organizationDetail[selectedOrganizationMatchedIndex] &&  organizationDetail[selectedOrganizationMatchedIndex].created_by === auth.uid && <Menu.Item
        className='profile-dropdown-item'
        onClick={() => {
          setSubscriptionDrawerVisible(true);
        }}
      >
        Subsciption
      </Menu.Item>
      }
      { isShowFullFeature && <Menu.Item
        className='profile-dropdown-item'
        onClick={() => {
          setContactUsDrawerVisible(true);
        }}
      >
        Contact
      </Menu.Item>
      }
       <Menu.Item className='profile-dropdown-item' onClick={() => {
        setTutorialDrawerVisible(true)
       }}>
        Tutorial
      </Menu.Item>
      {/* <Menu.Item className='profile-dropdown-item'>Billing</Menu.Item> */}
      <Menu.Item className='profile-dropdown-item sign-out' onClick={logout}>
        Sign Out
      </Menu.Item>
    </Menu>
  );

  // const memberList = (organizationDetail && organizationDetail[selectedOrganizationMatchedIndex] &&  organizationDetail[selectedOrganizationMatchedIndex].members) || [];

  return (
    <div className='profile'>
      <Dropdown overlay={menu} placement='topLeft'>
        <div className='profile-image-container'>
          <div className='online' />
          {photoURL ? (
            <div className='background-image' style={{ backgroundImage: `url(${photoURL})` }}></div>
          ) : (
            <Avatar className='profile-avatar profile-bottom-image-avatar' shape='square' size={50}>
              {initials}
            </Avatar>
          )}
        </div>
      </Dropdown>
      <ContactUsDrawer 
        visible={contactUsDrawerVisible}
        onCloseDrawer={setContactUsDrawerVisible}
      />
      <IntegrationDrawer
        onCloseDrawer={onCloseIntegrationDrawer}
        visible={integrationDrawerVisible}
        selectedOrganizationData={selectedOrganizationData}
      />
      <ProfileDrawer
        onCloseDrawer={onCloseProfileDrawer}
        visible={profileDrawerVisible}
        profile={profile}
        selfMasterProjects={selfMasterProjects}
        organizationDetail={organizationDetail}
      />
      <UsageDrawer
        onCloseDrawer={onCloseUsageDrawer}
        visible={usageDrawerVisible}
        organizationDetail={organizationDetail}
        profile={profile}
      />
      <RedemptionDrawer
        onCloseDrawer={onCloseRedemptionDrawer}
        visible={redemptionDrawerVisible}
        profile={profile}
        openInviteMemberModal={openInviteMemberModal}
      />
      <UserDrawer
        onCloseDrawer={onCloseUserDrawer}
        visible={userDrawerVisible}
        profile={profile}
        organizationDetail={organizationDetail}
        auth={auth}
        selectedOrganizationData={
          (organizationDetail && organizationDetail[selectedOrganizationIndex]) || { userQuota: {} }
        }
      />
      <SubscriptionDrawer
        onCloseDrawer={setSubscriptionDrawerVisible}
        visible={subscriptionDrawerVisible}
        profile={profile}
        organizationDetail={organizationDetail}
      />
      <OrganizationDrawer
        onCloseDrawer={onCloseOrgDrawer}
        visible={orgDrawerVisible}
        getOrgProjects={getOrgProjects}
        organizationDetail={organizationDetail}
        selectedOrgProject={selectedOrgProject}
        handleSelectedProject={handleSelectedProject}
        changeOrganization={changeOrganization}
        selectedOrganizationIndex={selectedOrganizationIndex}
        selectedOrganizationData={selectedOrganizationData}
      />

      <Drawer
        title='Quick Introduction of SuperCalls'
        placement='right'
        closable={true}
        width='75vw'
        onClose={() => {
          trackAnalytics('Settings', 'Closed Tutorial Drawer');
          setTutorialDrawerVisible(false);
        }}
        visible={tutorialDrawerVisible}
      >
        <div className='embed-container'>
            <iframe
              // src='https://www.youtube.com/embed/EXaUC-uhWRI?rel=0'
              src='https://www.youtube.com/embed/oouLDEwZAEA?rel=0'
              frameBorder='0'
              allowFullScreen
            ></iframe>
        </div>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, IAuthAction>) => {
  return {
    signOut: () => dispatch(signOutAction()),
  };
};
// @ts-ignore
export default withRouter<IProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    // @ts-ignore
  )(ProfileMenu),
);
