import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, notification, Checkbox, Upload, List } from 'antd';
import { Link } from "react-router-dom";
import TextArea from 'antd/lib/input/TextArea';
import firebase from 'firebase/app';
import 'firebase/storage';
import {
  createProject as createProjectAction,
  reset as resetAction,
} from '../../store/actions/projectActions';
// import RecordAudio from '../projects/Chat/RecordAudio';
import InterviewAudioRecorder from '../recording/InterviewAudioRecorder';
import './style.css';
import { mobileCheck } from '../../utils';
import { signIn as signInAction } from '../../store/actions/authActions';
import smartgeeksLogo from '../../static/smarkgeeks-logo.svg';
import { Logo } from '../svg/Logo';
import { transcript } from '../../store/actions/recordingAction';

interface Project {
  title: string;
  project_url: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
}

interface ICreds {
  email: string;
  password: string;
}

const InterviewCanvasDialog = (props: any) => {
  const submitBtn: any = useRef(null);
  const [isDetails, setIsDetails]: any = useState(false);
  const [resumeFile, setResumeFile] = useState('');

  const [blobObject, setBlobObject]: any = useState({});
  const [timer, setTimer] = useState(0);
  const [isRecording, setRecording] = useState(false);

  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [resumeDetails, setResumeDetails] = useState('');
  const [isAllowAccess, setIsAllowAccess] = useState(true);
  const [checkedTermAndCond, setCheckedTermAndCond] = useState(false);
  const [isSubmittedForm, setIsSubmittedForm] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  // @ts-ignore
  const params = (new URL(document.location)).searchParams;
  const isSmartGeeks = params.get("sm") || false;
  // const [isAllowMicrophonePermission, setIsAllowMicrophonePermission] = useState(true);
  const { history, createProject, form } = props;

  //   const selectedWorkflowId = 'open-1dd67dc9-caac-4fd1-bf1d-b8f3b9797d2f';
  //   const selectedMasterProjectId = 'selectedMasterProjectId== hKyDrhnVQsZVWSnnobMC';
  //   const memberEmail = 'manrajparmar793+raj1@gmail.com';
  //   const selectedMasterProject = {
  //     member_ids: ['zfLjovANzjaBcItZ4WsgmxHy84231'],
  //   };
  const selectedWorkflowId = props.match.params.workflowId || '664d3444-5685-4577-aa6f-99fddda4dfd3'; // interview canvas workflow id
  // const selectedWorkflowId = 'open-0e9136e8-8893-481e-9198-7cc753b4167f'; // dummpy
  // 'open-754507f5-fd5e-4bc0-9842-4593f3792468';
  // 'open-664d3444-5685-4577-aa6f-99fddda4dfd3';
  const selectedMasterProjectId = props.match.params.projectId || 'h6mJwL1cqZKOfA1Ktlvx'; // interview type master project id (need to confirm)
  // const selectedMasterProjectId = 'UrR8uwZo7yfjw3Y0KfJW'; // dummy
  const memberEmail = 'aemal@zooc.io';
  // const selectedMasterProject = {
  //   member_ids: ['sK4OQ22hYHcSWw4O0LPW3j8zY3K3'],
  // };
  console.log("List:", fileList)
  console.log("selectedWorkflowId:", selectedWorkflowId)
  const isMobile = mobileCheck();
  const FormItem = Form.Item;
  const tailFormItemLayout = {
    wrapperCol: {
      //   sm: {
      //     offset: 4,
      //     span: 16,
      //   },
      //   xs: {
      //     offset: 0,
      //     span: 24,
      //   },
    },
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const detail = urlParams.get('detail') || false;
    setIsDetails(detail);
    false &&
      props.signIn({
        email: 'interviewer@gmail.com',
        password: 'interviewer@123',
      });
    // const firestore = firebase.firestore();
    //   firestore
    //     .collection('masterProjects')
    //     .doc(selectedMasterProjectId)
    //     .get()
    //     .then((doc: any) => {
    //     })
  }, []);

  useEffect(() => {
    if (selectedMasterProjectId) {
      const firestore = firebase.firestore();
      firestore
        .collection('masterProjects')
        .doc(selectedMasterProjectId)
        .get()
        .then(async (doc: any) => {
          const selectedMasterProject = doc.data();
          firestore
            .collection('organization')
            .where('created_by', '==', selectedMasterProject.created_by)
            .get()
            .then((organizationData: any) => {
              if (organizationData.docs.length) {
                const currentOrganizationData = organizationData.docs[0].data();
                let userQuota: any = currentOrganizationData.userQuota;
                if (userQuota && userQuota.outboundCallLimit && (userQuota.outboundCallLimit > 0 || userQuota.outboundCallLimit === -1)) {
                  setIsAllowAccess(true);
                  console.log("outboundCallLimit:", userQuota.outboundCallLimit);
                } else {
                  console.log("outbound quota has been reached")
                  setIsAllowAccess(false);
                }
              } else {
                console.log("organization not exist")
                setIsAllowAccess(false);
              }
            }).catch((error: any) => {
              console.log("error:", error)
              setIsAllowAccess(false);
            })
        }).catch((error: any) => {
          console.log("error:", error)
          setIsAllowAccess(false);
        })
    }
  }, [])

  const createInterviewTask = () => {
    submitBtn.current && submitBtn.current.click();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsSubmittedForm(true);
    form.validateFields(async (err: any, values: any) => {
      console.log("err:", err);
      if (!err && checkedTermAndCond) {
        setLoading(true);
        const fileName = 'file' + new Date().getTime();
        const storageRef = firebase
          .storage()
          .ref()
          .child(fileName);

        const firebaseUploadObject: any = storageRef.put(blobObject.blob);
        firebaseUploadObject.on(
          'state_changed',
          (snapshot: any) => {
            const progress: any = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setPercentage(progress.toFixed(2));
          },
          () => {
            notification.error({
              description: '',
              message: 'upload cancelled!',
            });
            setLoading(false);
            clearAudio();
          },
          () => {

            return firebaseUploadObject.snapshot.ref.getDownloadURL().then((downloadURL: any) => {
              // clearAudio();
              const audioURL = downloadURL;
              //  createInterviewTask(audioURL);
              const firestore = firebase.firestore();
              firestore
                .collection('masterProjects')
                .doc(selectedMasterProjectId)
                .get()
                .then(async (doc: any) => {
                  const selectedMasterProject = doc.data();
                  const { fullName, email = '', phone = '', country, company, upworkProfileURL } = values;
                  const firestore = firebase.firestore();
                  let messages = [];
                  if (resumeFile) {
                    messages = [
                      {
                        attachedFileName: '',
                        attachment: '',
                        audioUrl: audioURL,
                        blobURL: '',
                        email: '',
                        feedback: `Recording: ${audioURL}`,
                        originalImage: '',
                        pageIDValue: '',
                        photoURL: '',
                        sender: '',
                        thumbnail: '',
                        time: new Date().getTime(),
                        uid: phone,
                        userAgent: '',
                        uuid: '51d6d78d-e246-49a0-b81d-d50f1asdfsdfse',
                        videoUrl: '',
                      },
                      {
                        attachedFileName: 'Resume',
                        attachment: resumeFile,
                        audioUrl: '',
                        blobURL: '',
                        email: '',
                        feedback: ``,
                        originalImage: '',
                        pageIDValue: '',
                        photoURL: '',
                        sender: '',
                        thumbnail: '',
                        time: new Date().getTime(),
                        uid: phone,
                        userAgent: '',
                        uuid: '51d6d78d-e246-49a0-b81d-d50f1asdfsdfse',
                        videoUrl: '',
                      }
                    ]
                  } else {
                    messages = [{
                      attachedFileName: '',
                      attachment: '',
                      audioUrl: audioURL,
                      blobURL: '',
                      email: '',
                      feedback: `Recording: ${audioURL}`,
                      originalImage: '',
                      pageIDValue: '',
                      photoURL: '',
                      sender: '',
                      thumbnail: '',
                      time: new Date().getTime(),
                      uid: phone,
                      userAgent: '',
                      uuid: '51d6d78d-e246-49a0-b81d-d50f1asdfsdfse',
                      videoUrl: '',
                    }]
                  }
                  const fullNameChat = await firestore.collection('chat').add({
                    count: 4,
                    messages: messages,
                  });
                  // const emailChat = await firestore.collection('chat').add({
                  //   messages: [],
                  //   count: 0,
                  // });
                  // const phoneChat = await firestore.collection('chat').add({
                  //   messages: [],
                  //   count: 0,
                  // });
                  // const countryChat = await firestore.collection('chat').add({
                  //   messages: [],
                  //   count: 0,
                  // });
                  const projectData = {
                    isOutboundCall: true,
                    title: `${fullName}`,
                    project_url: '',
                    member_emails: [email],
                    project_tags: [],
                    type: 'text',
                    // isInterviewCanvas: true,
                    isPublicUser: true,
                    workflowId: selectedWorkflowId,
                    masterProjectId: selectedMasterProjectId,
                    archived: 'no',
                    isPublic: true,
                    userNotes: `<p>Phone: ${phone}</p><p>Name: ${fullName}</p><p>Email: ${email}</p><p>Country: ${country}</p><p>Company: ${company}</p><p>Upwork/Freelancer Profile URL: ${upworkProfileURL}</p><p>More Details: ${resumeDetails}</p>`,
                    threads: [
                      {
                        createdAt: new Date().getTime(),
                        fromLeftPercentage: 7.352939241162668,
                        id: fullNameChat.id,
                        isResolve: false,
                        mediaTime: 1.173333,
                        member: {
                          email: memberEmail,
                          image: '',
                          name: `Inbound Call and Candidate Info`,
                        },
                        message: `Name: ${fullName}`,
                        page: null,
                        readUserEmails: [memberEmail],
                        replyCount: 0,
                        sequenceNo: 1,
                      },
                      // {
                      //   createdAt: new Date().getTime(),
                      //   fromLeftPercentage: 7.352939241162668,
                      //   id: emailChat.id,
                      //   isResolve: false,
                      //   mediaTime: 1.173333,
                      //   member: {
                      //     email: memberEmail,
                      //     image: '',
                      //     name: 'Candidate Info',
                      //   },
                      //   message: `Email: ${email}`,
                      //   page: null,
                      //   readUserEmails: [memberEmail],
                      //   replyCount: 0,
                      //   sequenceNo: 2,
                      // },
                      // {
                      //   createdAt: new Date().getTime(),
                      //   fromLeftPercentage: 7.352939241162668,
                      //   id: phoneChat.id,
                      //   isResolve: false,
                      //   mediaTime: 1.173333,
                      //   member: {
                      //     email: memberEmail,
                      //     image: '',
                      //     name: 'Candidate Info',
                      //   },
                      //   message: `Phone: ${phone}`,
                      //   page: null,
                      //   readUserEmails: [memberEmail],
                      //   replyCount: 0,
                      //   sequenceNo: 3,
                      // },
                      // {
                      //   createdAt: new Date().getTime(),
                      //   fromLeftPercentage: 7.352939241162668,
                      //   id: countryChat.id,
                      //   isResolve: false,
                      //   mediaTime: 1.173333,
                      //   member: {
                      //     email: memberEmail,
                      //     image: '',
                      //     name: 'Candidate Info',
                      //   },
                      //   message: `Country: ${country}`,
                      //   page: null,
                      //   readUserEmails: [memberEmail],
                      //   replyCount: 0,
                      //   sequenceNo: 4,
                      // },
                    ],
                  };
                  createProject(projectData, '', selectedMasterProject).then(async (insertedId: any) => {
                    console.log("success, inserted id:", insertedId);
                    await transcript(props.firebaseAuthToken, {
                      audioURL: audioURL,
                      chatId: fullNameChat.id,
                      inboudCall: true,
                      selectedWorkflowId: selectedWorkflowId,
                      selectedMasterProjectId: selectedMasterProjectId,
                      selectedProjectId: insertedId
                    })
                    setLoading(false);
                    if (insertedId) {
                      history.push(`/text/${insertedId}?threadId=${fullNameChat.id}`);
                    }
                  }).catch((error: any) => {
                    console.log("error:", error)
                    setLoading(false);
                  })
                });
            });
          },
        );
      } else {
        setLoading(false);
      }
    });
  };

  const clearAudio = () => {
    setBlobObject({});
    setRecording(false);
    setIsPaused(false);
    const playButton: any = document.getElementById('record');
    if (playButton) {
      playButton.style.display = 'flex';
    }
    const ResumeButton: any = document.getElementById('resumeAudioRecord');
    ResumeButton.style.display = 'none';
    const settingsButton: any = document.getElementById('mySidenav');
    if (settingsButton) {
      settingsButton.style.display = 'flex';
    }
    setTimer(0);
  };

  if (!isAllowAccess) {
    return <p>Inbound call quota limit has been reached, Please contact to oranization admin to upgrade plan.</p>
  }

  const handleFileUpload = async (file: any) => {
    try {
      const storageRef = firebase
        .storage().ref();
      const fileRef = storageRef.child(file.name);
      const snapshot = await fileRef.put(file);
      const downloadURL = await snapshot.ref.getDownloadURL();
      console.log('File uploaded successfully');
      console.log('Download URL:', downloadURL);
      setResumeFile(downloadURL);
      setFileList([{ uid: file.uid, name: file.name }]);
    } catch (error) {
      console.log('Failed to upload file');
    }
  };

  const handleChange = (info: any) => {
    let newFileList = [...info.fileList];
    if (newFileList.length > 1) {
      newFileList = [newFileList.pop()]; // Keep only the last file if multiple files are uploaded
    }
    setFileList(newFileList);
  };

  const handleRemove = async () => {
    try {
      const storageRef = firebase
        .storage().ref();
      const fileRef = storageRef.child(fileList[0].name);
      await fileRef.delete();
      console.log('File removed successfully');
      setFileList([]);
    } catch (error) {
      console.log('Failed to remove file');
    }
  };

  const customRequest = ({ file }: any) => {
    if (fileList.length === 0) {
      handleFileUpload(file);
    } else {
      handleFileUpload(file);
      console.log('Only one file can be uploaded at a time');
    }
  };

  return (
    <div
      className='interview-page'
      style={
        isMobile
          ? { width: '90%', margin: '10px 20px' }
          : { width: '500px', display: 'grid', margin: 'auto' }
      }
    >

      {isSmartGeeks ?
        <img src={smartgeeksLogo} style={{ margin: '20px auto 0px auto' }} /> :
        <div style={{ margin: '18px auto 0px auto' }}><Logo /></div>
      }
      <p style={{ marginTop: isSmartGeeks ? '20px' : '10px' }}>
        <b>Instruction:</b> Please fill this form and record your voice. In the recording please
        give an introduction about your self and your career. Record at least 1 minute long, but
        feel free to utilize the entire length of the audio recording.
      </p>
      <Form onSubmit={handleSubmit}>
        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('fullName', {
            rules: [
              {
                message: 'Full Name Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Full Name' />)}
        </FormItem>
        {isDetails == 'true' ? (
          <FormItem
            {...tailFormItemLayout}
            style={{
              marginBottom: 10,
              marginRight: 'auto',
              marginLeft: 'auto',
              width: isMobile ? '90%' : '450px',
            }}
          >
            {form.getFieldDecorator('email', {
              rules: [
                {
                  message: 'Email Please!',
                  required: true,
                  type: 'email',
                },
              ],
              initialValue: '',
            })(<Input className='ant-input' placeholder={`Email`} />)}
          </FormItem>
        ) : null}
        {isDetails == 'true' ? (
          <FormItem
            {...tailFormItemLayout}
            style={{
              marginBottom: 10,
              marginRight: 'auto',
              marginLeft: 'auto',
              width: isMobile ? '90%' : '450px',
            }}
          >
            {form.getFieldDecorator('phone', {
              rules: [
                {
                  message: 'Phone Number Please!',
                  required: true,
                },
              ],
            })(<Input className='ant-input' type='text' placeholder='Phone Number' />)}
          </FormItem>
        ) : null}
        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('country', {
            rules: [
              {
                message: 'Country Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Country' />)}
        </FormItem>

        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('company', {
            rules: [
              {
                message: 'Company Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Company' />)}
        </FormItem>

        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('upworkProfileURL', {
            rules: [
              {
                message: 'Upwork/Freelancer Profile URL Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Upwork/Freelancer Profile URL' />)}
        </FormItem>

        <div style={{
          marginBottom: 10,
          marginRight: 'auto',
          marginLeft: 'auto',
          width: isMobile ? '90%' : '450px',
        }}>
          <TextArea
            value={resumeDetails}
            onChange={(e) => setResumeDetails(e.target.value)}
            style={{ marginTop: '10px' }}
            placeholder='Resume Details'
            rows={10}
          />
        </div>

        <Upload
          fileList={fileList}
          customRequest={customRequest}
          onChange={handleChange}
          onRemove={handleRemove}
          showUploadList={false}
        >
          <Button>Upload Resume File</Button>
        </Upload>

        {fileList.length > 0 && (
          <List
            style={{ marginTop: '20px' }}
            bordered
            dataSource={fileList}
            renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
          />
        )}

        {/* <List
          style={{ marginTop: '20px' }}
          bordered
          dataSource={fileList}
          renderItem={item => (
            <List.Item>
              {item}
            </List.Item>
          )}
        /> */}

        <FormItem style={{ margin: 0, padding: 0 }}>
          <div
            className="logged-in-container"
            style={{ display: "flex", alignItems: "center", margin: 0, padding: 0 }}
          >
            <Checkbox
              style={{ padding: "0px 9px 0px 0px" }}
              onChange={(e: any) => {
                setCheckedTermAndCond(e.target.checked);
              }}
              checked={checkedTermAndCond}
            />
            <label
              className="check-label"
              onClick={() => {
                setCheckedTermAndCond(!checkedTermAndCond);
              }}
              style={{ marginBottom: 0 }}
            >
              I agree all{" "}
              <span>
                <Link
                  to="/terms-and-conditions"
                  style={{ color: "#000", textDecoration: "underline" }}
                >
                  terms and conditions.
                </Link>
              </span>
            </label>
          </div>
        </FormItem>
        {!checkedTermAndCond && isSubmittedForm && (
          <p
            style={{ color: "#d32f2f", marginLeft: "14px" }}
          >
            Please tick term and conditions checkbox to proceed.
          </p>
        )}
        <div style={{ paddingTop: '10px' }}>
          <InterviewAudioRecorder
            blobObject={blobObject}
            setBlobObject={setBlobObject}
            timer={timer}
            setTimer={setTimer}
            isRecording={isRecording}
            setRecording={setRecording}
            loading={loading}
            setLoading={setLoading}
            percentage={percentage}
            setPercentage={setPercentage}
            isPaused={isPaused}
            setIsPaused={setIsPaused}
            //  setIsAllowMicrophonePermission={setIsAllowMicrophonePermission}
            maxRecordTime={2}
            projectID={'dasdasd'}
            //  setLoading={() => null}
            onClose={() => null}
            showPercentage
            onSubmit={createInterviewTask}
            background={'rgb(236, 245, 255)'}
          />
        </div>

        <input style={{ visibility: 'hidden' }} type='submit' ref={submitBtn} />

        {false && (
          <Form.Item>
            <Button ref={submitBtn} type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        )}
        {/* { !isAllowMicrophonePermission && <>
                <div>
                Unable to access microphone, please allow microphone permission in order to record audio and try again with refresh the page
                </div>
                <img style={
                    isMobile
                        ? { width: '90%', marginTop: '10px' }
                        : { width: '500px', marginTop: '10px' }
                } src="/images/allow-microphone.png" alt="Allow microphone" />
                <img style={
                    isMobile
                        ? { width: '90%', margin: '10px 20px' }
                        : { width: '500px', display: 'grid', margin: 'auto' }
                } src="/images/allow-microphone-in-zooc.png" alt="Allow microphone in zooc" />
                </>} */}
      </Form>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    propsLoading: state.project.loading,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetLoader: () => dispatch(resetAction()),
    signIn: (creds: ICreds) => dispatch(signInAction(creds)),
    createProject: (project: Project, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create()(InterviewCanvasDialog as any)) as any;
