import { DownOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, List, Menu, Modal, notification } from 'antd';
import firebase from 'firebase/app';
import React from 'react';
import { connect } from 'react-redux';
import { mobileCheck } from '../../utils';
import { Delete } from '../svg';
import './members.css';

const isMobile = mobileCheck();

const MemberListModel = (props: {
  selectedMasterProject: any;
  title: string;
  visible: boolean;
  setVisible: () => void;
  auth: { uid: string };
}) => {
  const { visible, setVisible, title, selectedMasterProject, auth } = props;

  const roleMenu = (index: number) => {
    const menu = (
      <Menu>
        <Menu.Item
          className='project-dropdown-item'
          key='admin'
          onClick={() => {
            changeMemberRole(index, 'Admin');
          }}
        >
          Admin
        </Menu.Item>
        <Menu.Item
          className='project-dropdown-item'
          key='2'
          onClick={() => {
            changeMemberRole(index, 'Can comment');
          }}
        >
          Can comment
        </Menu.Item>
        <Menu.Item
          className='project-dropdown-item'
          key='3'
          onClick={() => {
            changeMemberRole(index, 'Can edit');
          }}
        >
          Can edit
        </Menu.Item>
      </Menu>
    );
    return menu;
  };

  const changeMemberRole = (memberIndex: number, selectedRole: string) => {
    const members = selectedMasterProject.members;
    members[memberIndex].role = selectedRole;
    const firestore = firebase.firestore();
    const updatedTimeStamp = new Date().getTime();
    return firestore
      .collection('masterProjects')
      .doc(selectedMasterProject.id)
      .set(
        {
          members,
          updatedTimeStamp,
        },
        { merge: true },
      )
      .catch(() => {
        notification.error({
          message: 'Something went wrong, please try again',
        });
      });
  };

  const removeMemeber = (item: { name: string; email: string, id: string }) => {
    Modal.confirm({
      content: '',
      title: `Are you sure, you want to delete "${item.name ? item.name : item.email}" user?`,
      onOk() {
        const oldMembers = selectedMasterProject.members || [];
        const oldEmails = selectedMasterProject.member_emails || [];
        const newMembers = oldMembers.filter(
          ({ id }: { id: string }) => id !== item.id
        );
        const newEmails = oldEmails.filter((email: string) => email !== item.email);
        const firestore = firebase.firestore();
        const updatedTimeStamp = new Date().getTime();
        return firestore
          .collection('masterProjects')
          .doc(selectedMasterProject.id)
          .set(
            {
              members: newMembers,
              member_emails: newEmails,
              updatedTimeStamp,
            },
            { merge: true },
          );
      },
    });
  };

  return (
    <div className='add-member-form'>
      <Modal
        visible={visible}
        title={<span className='memeber-list-title'>{title}</span>}
        onOk={setVisible}
        onCancel={() => setVisible()}
        footer={null}
      >
        <div style={{ margin: isMobile ? '0px' : undefined }} className='member-list-container'>
          <List
            className='collaborators-list'
            itemLayout='horizontal'
            dataSource={selectedMasterProject.members}
            renderItem={(
              item: {
                id: string;
                email: string;
                status: string;
                image?: string;
                name: string;
                role: string;
              },
              index: number,
            ) => (
              <List.Item
                actions={[
                  <Dropdown overlay={roleMenu(index)}>
                    <Button className='member-list-role member-role-dropdown'>
                      {item.role ? item.role : 'Not selected'}
                      <DownOutlined />
                    </Button>
                  </Dropdown>,
                  <span
                    className={item.id === auth.uid ? 'self-user' : 'delete-icon'}
                    onClick={() => removeMemeber(item)}
                  >
                    <Delete />
                  </span>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    item.status === 'PENDING' ? (
                      <Avatar icon='user' />
                    ) : item.image ? (
                      <Avatar src={item.image} />
                    ) : (
                      <Avatar>{item.name[0].toUpperCase()}</Avatar>
                    )
                  }
                  title={<a href='#'>{item.status === 'PENDING' ? item.email : item.name}</a>}
                  description={
                    item.status === 'ACTIVE' ? item.email : item.status && item.status.toLowerCase()
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(MemberListModel as any) as any;
