import {  Modal } from 'antd';
import React, { FC, Fragment } from 'react';
import ColorContext from '../../context/ColorContext';

interface IProps {
  visible: boolean;
  handleCancel: () => void;
}

const CallInitiatedModal: FC<IProps> = ({
  visible,
  handleCancel
}) => {

  return (
    <Modal
      visible={visible}
      onOk={handleCancel}
      onCancel={() => handleCancel()}
      footer={null}
    >
      <ColorContext.Consumer>
        {({ theme }) => (
          <div  style={{ color: theme.text.color2 }}>
              <Fragment>
                <h1>Bulk Call Initiated Successfully</h1>
                <div style={{margin : '20px 0'}}>
                  All the contacts are being called now by an AI bot, upon their response the contacts will be sorted in their respective columns.
                </div>
              </Fragment>
            
          </div>
        )}
      </ColorContext.Consumer>
    </Modal>
  );
};

export default CallInitiatedModal;
