import React from 'react';
import './TermsAndConditions.css';
import { Logo } from '../components/svg/Logo';
import { withRouter } from 'react-router-dom';

interface Props { }

const isMobileView = () => {
  if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
    // the user is using a mobile device, so redirect to the mobile version of the website
    return true;
  }
  return false;
};

const TermsAndConditions: React.FC<Props> = ({ history }: any) => {

  const handleGoBack = () => {
    history.goBack(); // This will navigate back one step in the history stack
  };
  
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: isMobileView() ? '0' : '20px 20vw',
    }}>
      <div className="logo-container">
      <a
        // href={"/"}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          color: "#000",
          padding: '24px 0px'
        }}
      >
        <Logo/>
      </a>
      </div>
      {/* <Header isShowLogoutButton={false} /> */}
      <a style={{ color: 'rgb(0, 29, 173)'}} className="terms-and-condition-go-back" onClick={handleGoBack} >Go Back</a>
      <div className="terms-and-conditions-content">
        <h3>Terms and Conditions</h3>
        <p>
          Welcome to our app! We're happy you've chosen to use our service. Please
          read these Terms and Conditions carefully before using our app.
        </p>
        <p>
          By accessing or using our app, you agree to be bound by these Terms and
          Conditions. If you do not agree with these Terms and Conditions, you may
          not use our app.
        </p>
        <p>
          Our app uses artificial intelligence technology provided by ChatGPT and
          Assembly AI to provide voice recognition and chatbot services. This
          technology may collect and process user data, such as voice recordings,
          text inputs, and other information, for the purpose of improving our
          app's functionality and providing personalized user experiences.
        </p>
        <p>
          We take the privacy and security of your data seriously. Our app uses
          Firebase for data storage, which may involve transferring your data to
          servers located in the United States. By using our app, you consent to
          this transfer of data and acknowledge that it may be subject to US laws
          and regulations.
        </p>
        <p>
          Please note that we may update these Terms and Conditions from time to
          time, and your continued use of our app after any such changes
          constitutes your acceptance of the new Terms and Conditions.
        </p>
        <p>
          Our app is provided on an "as is" basis, and we do not make any
          warranties or representations regarding its functionality, accuracy, or
          reliability. We shall not be liable for any damages arising from your
          use or inability to use our app, or for any errors or omissions in our
          app's content.
        </p>
        <p>
          By using our app, you agree to indemnify and hold us harmless from any
          claims, damages, or expenses arising out of your use of our app or your
          violation of these Terms and Conditions.
        </p>
        <p>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of [insert your country or state]. Any dispute arising
          from or related to these Terms and Conditions shall be subject to the
          exclusive jurisdiction of the courts in [insert your country or state].
        </p>
        <p>
          If you have any questions or concerns about these Terms and Conditions,
          please contact us at [insert your contact information].
        </p>
      </div>
    </div>
  );
};

export default withRouter(TermsAndConditions);