import { Modal } from 'antd';
import firebase from 'firebase';
import React, { useState } from 'react';
import { ITag } from 'types/projectTypes';
import ColorContext from '../../../context/ColorContext';
import { Zbutton } from '../../../primitives';
import Chips from './Chips';
import './tags.css';

interface IProps {
  closeModal: () => void;
  projectId: string;
  visible: boolean;
  tagList: ITag[];
  addedTags: ITag[];
}

interface IData {
  value: string;
  label: string;
  color: string;
}

const CanvasTagDialog: React.FC<IProps> = ({
  closeModal,
  visible,
  tagList,
  projectId,
  addedTags,
}) => {
  const [selectedOption, setSelectedOption] = useState<IData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const tags = selectedOption.map((opt: any) => {
    return { color: opt.color, title: opt.label, id: opt.value };
  });

  const handleSubmitTag = () => {
    setIsLoading(true);
    const firestore = firebase.firestore();
    return firestore
      .collection('projects')
      .doc(projectId)
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          const data = doc.data();
          if (data.tags && tags) {
            const selectedtags = tags;
            const selectedtagsIds = selectedtags.map((selectedtag: any) => selectedtag.id);
            firestore
              .collection('projects')
              .doc(doc.id)
              .set({ tags, selectedtagsIds }, { merge: true })
              .then(() => {
                setSelectedOption([]);
                setIsLoading(false);
                closeModal();
              })
              .catch(() => {
                setIsLoading(false);
                return false;
              });
          } else if (tags) {
            const selectedtagsIds = tags.map((selecteTag: any) => selecteTag.id);
            firestore
              .collection('projects')
              .doc(doc.id)
              .set({ tags, selectedtagsIds }, { merge: true })
              .then(() => {
                setSelectedOption([]);
                setIsLoading(false);
                closeModal();
              })
              .catch(() => {
                setIsLoading(false);
                return false;
              });
          }
        }
      });
  };

  return (
    <div>
      <Modal visible={visible} onOk={closeModal} onCancel={() => closeModal()} footer={null}>
        <ColorContext.Consumer>
          {({ theme }) => (
            <div className='tag-dialog-container' style={{ color: theme.text.color2 }}>
              <h2>Tags</h2>
              <p>Tags can be used to easily categorize and filter canvases.</p>
              <Chips
                tagList={tagList}
                setSelectedOption={setSelectedOption}
                addedTags={addedTags}
              />
              <div className='add-tag-btn-container' style={{ marginTop: 50 }}>
                <Zbutton
                  secondary
                  btnColors={theme}
                  style={{ marginRight: 10 }}
                  onClick={() => closeModal()}
                >
                  Cancel
                </Zbutton>
                <Zbutton
                  key='submit'
                  primary
                  btnColors={theme}
                  loading={isLoading}
                  onClick={handleSubmitTag}
                >
                  Done
                </Zbutton>
              </div>
            </div>
          )}
        </ColorContext.Consumer>
      </Modal>
    </div>
  );
};

export default CanvasTagDialog;
