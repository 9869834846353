import styled from '@emotion/styled';
import React, { Component } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { IMasterProject, IworkflowCanvas } from '../types/projectTypes';
import Column from './column';

const Container = styled.div`
  /* min-height: 100vh; */
  /* like display:flex but will allow bleeding over the window width */
  /* min-width: 100vw; */
  display: inline-flex;
`;

interface IOwnProps {
  workflowCanvas: IworkflowCanvas[];
  selectedMasterProject: IMasterProject;
  history: any;
  onDragEnd: (result: any) => void;
  setActiveColumnId: any;
  activeColumnId: any;
  loader: any;
  selectedOrganizationData: any;
}

class Board extends Component<IOwnProps, any> {
  /* eslint-disable react/sort-comp */
  static defaultProps = {
    isCombineEnabled: false,
  };

  render() {
    const {
      workflowCanvas,
      onDragEnd,
      selectedMasterProject,
      history,
      setActiveColumnId,
      activeColumnId,
      loader,
      selectedOrganizationData,
    } = this.props;
    const board = (
      <Droppable
        droppableId='board'
        type='COLUMN'
        direction='horizontal'
        ignoreContainerClipping={false}
        isCombineEnabled={false}
      >
        {(provided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            {workflowCanvas.map((workflowStep, workflowIndex) => {
              const { id, color = '', step, canvases = [],batchCall, promptDescription = ''  } = workflowStep;
              return (
                <Column
                  history={history}
                  selectedMasterProject={selectedMasterProject}
                  workflowIndex={workflowIndex}
                  key={id}
                  id={id}
                  index={workflowIndex}
                  step={step}
                  canvases={canvases}
                  color={color}
                  isScrollable={false}
                  isCombineEnabled={false}
                  setActiveColumnId={setActiveColumnId}
                  activeColumnId={activeColumnId}
                  loader={loader}
                  batchCall={batchCall}
                  promptDescription={promptDescription}
                  selectedOrganizationData={selectedOrganizationData}
                />
              );
            })}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    );

    return (
      <div className={`kanbanContainer`}>
        <DragDropContext onDragEnd={onDragEnd}>{board}</DragDropContext>
      </div>
    );
  }
}

export default Board;
