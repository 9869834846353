import { notification } from 'antd';
import firebase from 'firebase';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';
import { mobileCheck } from '../../utils';
import './index.css';
const isMobile = mobileCheck();

const TextDetail = (props: any) => {
  const { userNotes, projectId } = props;
  const [textareaValue, setTextAreaValue] = useState('');

  // Function to replace all matched dates in the string
  function replaceDatesWithLocalTimezone(str: any) {
    const dateRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/g; // Use global flag to match all occurrences
    const dateFormat = 'MMM Do [at] h:mm:ss A';
    return str.replace(dateRegex, (matchedDate : any) => {
      // Parse the matched date using moment
      const date = moment.utc(matchedDate, "YYYY-MM-DD HH:mm:ss");
      
      // Convert to local timezone using JavaScript Date object
      const localDate = new Date(date.format());
    
      // Format the local date using moment
      return moment(localDate).format(dateFormat);
    });
  }

  useEffect(() => {
    const str = userNotes;
    // Replace dates in the string and log the result
    const rebuiltStr = replaceDatesWithLocalTimezone(str);
    setTextAreaValue(rebuiltStr);
    // @ts-ignore
    // getCharCount
  }, [userNotes]);

  const setData = (value: any) => {
    if (value.length < 1048576) {
      setTextAreaValue(value);
      const firestore = firebase.firestore();
      firestore
        .collection('projects')
        .doc(projectId)
        .update({
          userNotes: value,
        });
    } else {
      notification.error({
        duration: 0,
        message: 'Character limit react!',
      });
    }
  };
  return (
    <div
      style={{
        height: '100%',
        overflow: 'hidden',
        width: isMobile ? 'calc(100% - 5px)' : undefined,
        marginLeft: isMobile ? '5px' : undefined,
      }}
    >
      <SunEditor
        onChange={setData}
        placeholder='Start typing...'
        setContents={textareaValue}
        enable={true}
        enableToolbar={true}
        height={isMobile ? 'calc(100vh - 227px)' : 'calc(100vh - 162px)'}
        setOptions={{
          katex,
          plugins,
          buttonList: [
            isMobile ? '' : ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            isMobile ? '' : ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            isMobile ? '' : ['fontColor', 'hiliteColor', 'textStyle'],
            isMobile ? '' : ['removeFormat'],
            '/', // Line break
            isMobile ? '' : ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            isMobile ? '' : ['table', 'link', 'image', 'video', 'audio', 'math'], // You must add the 'katex' library at options to use the 'math' plugin. // You must add the "imageGalleryUrl".
            /** ['imageGallery'] */
            isMobile ? '' : ['fullScreen', 'showBlocks', 'codeView'],
            isMobile ? '' : ['preview', 'print'],
            isMobile ? '' : ['save', 'template'],
          ],
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    null,
  ),
)(TextDetail);
