import { Button, Dropdown, Icon, Input, Menu, Modal, notification, Popover, Tooltip } from 'antd';
import firebase from 'firebase';
import Mousetrap from 'mousetrap';
import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { connect } from 'react-redux';
import { eventConstant } from '../../../constant';
import { IMemberUser, ITag, Iworkflow } from '../../../types/projectTypes';
import { copyURL, sendMessageToIframe, trackAnalytics } from '../../../utils';
import CanvasMemberDialog from '../../dashboard/members/CanvasMemberDialog';
import MemberAvtar from '../../dashboard/MembersAvtar';
import CanvasTagDialog from '../../dashboard/tags/CanvasTagDialog';
import DropDownCustom from '../../dropDown';
import CreateJiraIssueForm from '../../jira-integration/CreateIssueForm';
import { BackIcon, DropdownArrow, TripleDot } from '../../svg';
import CreateTrelloIssueForm from '../../trello-integration/CreateIssueForm';
import {
  Arrow,
  Box,
  Circle,
  Cover,
  Erase,
  Line,
  Pencil,
  Record,
  Redo,
  Text,
  Undo,
} from '../CanvasSvgs';
import CreatePassword from './CreatePassword';
import './header.css';
import { showFullFeature } from '../../../utils';


const CanvasHeader = ({
  tags,
  workflow,
  members,
  title,
  projectId,
  canvasWorkflowId,
  history,
  archived,
  tagList,
  memberList,
  iframe,
  setDrawing,
  isWriteAccess,
  showControls,
  keyboardEvent,
  undoOrRedoValue,
  jiraIssueUrl,
  backgroundColor,
  setBackgroundColor,
  activeTab,
  setActiveTab,
  trelloIssueUrl,
  match,
  masterProjectId,
  canvasDetails,
  isCanvasOwner,
  isMobile,
  uid,
  selectedOrganizationData,
  isInterviewCanvas,
}: {
  tags: ITag[];
  workflow: Iworkflow[];
  members: IMemberUser[];
  title: string;
  projectId: string;
  canvasWorkflowId: string;
  history: any;
  archived: string;
  tagList: ITag[];
  memberList: IMemberUser[];
  iframe: any;
  setDrawing: any;
  isWriteAccess: boolean;
  showControls: boolean;
  keyboardEvent: string;
  undoOrRedoValue: any;
  jiraIssueUrl: string;
  backgroundColor: string;
  setBackgroundColor: any;
  activeTab: string;
  setActiveTab: any;
  trelloIssueUrl: string;
  match: any;
  masterProjectId: string;
  canvasDetails: any;
  isCanvasOwner: boolean;
  isMobile: boolean;
  uid: string;
  selectedOrganizationData: any;
  isInterviewCanvas?: boolean;
}) => {
  const titleRef: any = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const [canvasTitle, setCanvasTitle] = useState(title);
  const [canvasTagModalVisible, setCanvasTagModalVisible] = useState(false);
  const [canvasMemberModalVisible, setCanvasMemberModalVisible] = useState(false);
  const [toggleCommentryMode, setToggleCommentryMode] = useState(true);
  const [undoDisabled, setUndoDisabled] = useState(false);
  const [redoDisabled, setRedoDisabled] = useState(false);
  const [displayColorPicker, setdisplayColorPicker] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState('');
  const [tagModalTooltipArr, setTagModalTooltipArr] = useState([]);

  const firestore = firebase.firestore();

  const setCommentryMode = (toggleCommentryModeValue: boolean) => {
    setDrawing(false);
    setToggleCommentryMode(toggleCommentryModeValue);
  };
  useEffect(() => {
    if (showControls && keyboardEvent) {
      if (toggleCommentryMode) {
        keyboardEvent &&
          trackAnalytics('Project Detail', `Keybaord shortcut event to ${keyboardEvent}`);
        switch (keyboardEvent) {
          case 'commenting':
            sendMessageToIframe(iframe, eventConstant.ADD_FEEDBACK, {});
            setDrawing(false);
            setActiveTab('commenting');
            break;
          case 'drawArrows':
            setDrawingMode('drawArrows');
            break;
          case 'freeDraw':
            setDrawingMode('freeDraw');
            break;
          case 'drawCircle':
            setDrawingMode('drawCircle');
            break;
          case 'drawRect':
            setDrawingMode('drawRect');
            break;
          case 'drawLine':
            setDrawingMode('drawLine');
            break;
          case 'addText':
            setDrawingMode('addText');
            break;
          default:
            break;
        }
      }
      if (keyboardEvent === 'browse') {
        setCommentryMode(!toggleCommentryMode);
        const event = eventConstant.HIDE_COMMENTS;
        sendMessageToIframe(iframe, event, {});
      } else if (keyboardEvent === 'comment') {
        setCommentryMode(!toggleCommentryMode);
        const event = eventConstant.SHOW_COMMENTS;
        setActiveTab('commenting');
        sendMessageToIframe(iframe, event, {});
      }
    }
  }, [keyboardEvent]);

  const eventHandler = (e: any) => {
    const eventData = e.data;
    const event = eventData.event;
    switch (event) {
      // case eventConstant.LOADED_IFRAME:
      //   this.openChat();
      //   this.setState({
      //     isLoadedIframe: true,
      //   });
      //   // sendMessageToIframe(this.props.iframe, eventConstant.ADD_FEEDBACK, {});
      //   break;
      case eventConstant.BROWSER_MODE:
        setToggleCommentryMode(false);

        break;
      case eventConstant.COMMENTRY_MODE:
        setToggleCommentryMode(true);
        break;
      default:
    }
  };

  useEffect(() => {
    Mousetrap.bind(
      ['b', 'B', 'c', 'C', 'd', 'D', 'a', 'A', 'x', 'X', 'r', 'R', 'l', 'L', 't', 'T'],
      (e: any) => {
        if (toggleCommentryMode) {
          switch (e.keyCode) {
            case 99:
            case 67:
              sendMessageToIframe(iframe, eventConstant.ADD_FEEDBACK, {});
              setDrawing(false);
              setActiveTab('commenting');
              trackAnalytics('Project Detail', `Keybaord shortcut event to commenting`);
              break;
            case 100:
            case 68:
              setDrawingMode('freeDraw');
              trackAnalytics('Project Detail', `Keybaord shortcut event to freeDraw`);
              break;
            case 97:
            case 65:
              setDrawingMode('drawArrows');
              trackAnalytics('Project Detail', `Keybaord shortcut event to drawArrows`);
              break;
            case 120:
            case 88:
              setDrawingMode('drawRect');
              trackAnalytics('Project Detail', `Keybaord shortcut event to drawRect`);
              break;
            case 114:
            case 82:
              setDrawingMode('drawCircle');
              trackAnalytics('Project Detail', `Keybaord shortcut event to drawCircle`);
              break;
            case 108:
            case 76:
              setDrawingMode('drawLine');
              trackAnalytics('Project Detail', `Keybaord shortcut event to drawLine`);
              break;
            case 116:
            case 84:
              setDrawingMode('addText');
              trackAnalytics('Project Detail', `Keybaord shortcut event to addText`);
              break;
            default:
              break;
          }
        }
        if (e.keyCode === 98 || e.keyCode === 66) {
          setCommentryMode(!toggleCommentryMode);
          let event = eventConstant.HIDE_COMMENTS;
          if (toggleCommentryMode) {
            event = eventConstant.SHOW_COMMENTS;
            setActiveTab('commenting');
          }
          sendMessageToIframe(iframe, event, {});
        }
      },
    );
    Mousetrap.bind(['command+z', 'ctrl+z', 'command+shift+z', 'ctrl+shift+z'], (e: any) => {
      if (e.shiftKey && !redoDisabled) {
        undoOrRedo(false);
      } else if (!e.shiftKey && !undoDisabled) {
        undoOrRedo(true);
      }
    });
    window.addEventListener('message', eventHandler, false);
    if (match.path === '/c/:id') {
      setActiveTab('freeDraw');
    }
  }, []);

  useEffect(() => {
    if (undoOrRedoValue) {
      setUndoDisabled(undoOrRedoValue.undo);
      setRedoDisabled(undoOrRedoValue.redo);
    }
  }, [undoOrRedoValue]);
  const [jiraDrawerVisible, setJiraDrawerVisible] = useState(false);
  const [trelloDrawerVisible, setTrelloDrawerVisible] = useState(false);

  const setJiraVisible = () => {
    setJiraDrawerVisible(false);
  };

  useEffect(() => {
    if (isWriteAccess && activeTab === 'commenting') {
      sendMessageToIframe(iframe, eventConstant.ADD_FEEDBACK, {});
      setDrawing(false);
      trackAnalytics('Project Detail', 'add new comments clicked');
    }
  }, [activeTab]);
  const setTrelloVisible = () => {
    setTrelloDrawerVisible(false);
  };

  const handleCloseModale = () => {
    setCanvasTagModalVisible(false);
  };

  const handleCloseMemberModal = () => {
    setCanvasMemberModalVisible(false);
  };

  const updateCanvasTitle = (value: string, isUpdate: boolean) => {
    if (!uid) {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Please Sign Up to continue!',
        onOk: () => {
          history.push('/signup');
        },
      });
    } else {
      if (isUpdate) {
        firestore
          .collection('projects')
          .doc(projectId)
          .update({
            title: value,
          });
      } else {
        setCanvasTitle(value);
      }
    }
  };

  const shareMenu = () => {
    const jiraTitle = jiraIssueUrl ? 'Open in Jira' : 'Export to Jira';
    const trelloTitle = trelloIssueUrl ? 'Open in Trello' : 'Export to Trello';
    const isShowFullFeature = showFullFeature();
    const projectMenu = isShowFullFeature ? ['Copy Link', 'Add Members', jiraTitle, trelloTitle] : ['Copy Link'];
    const menu = (
      <Menu>
        {projectMenu.map((step: string) => (
          <Menu.Item
            className='project-dropdown-item'
            key='1'
            onClick={() => {
              if (step === 'Copy Link') {
                trackAnalytics('Canvas Detail', 'Copy Link Clicked');
                copyURL(window.location.href);
              } else if (step === jiraTitle) {
                if (jiraTitle === 'Export to Jira') {
                  if (!uid) {
                    Modal.confirm({
                      content: '',
                      okText: 'Yes',
                      cancelText: 'No',
                      title: 'Please Sign Up to continue!',
                      onOk: () => {
                        history.push('/signup');
                      },
                    });
                  } else {
                    trackAnalytics('Canvas Detail', 'Export to Jira Clicked');
                    setJiraDrawerVisible(true);
                  }
                } else if (jiraTitle === 'Open in Jira') {
                  trackAnalytics('Canvas Detail', 'Open in Jira Clicked');
                  window.open(jiraIssueUrl, '_blank');
                }
              } else if (step === trelloTitle) {
                if (!uid) {
                  Modal.confirm({
                    content: '',
                    okText: 'Yes',
                    cancelText: 'No',
                    title: 'Please Sign Up to continue!',
                    onOk: () => {
                      history.push('/signup');
                    },
                  });
                } else {
                  if (trelloTitle === 'Export to Trello') {
                    trackAnalytics('Canvas Detail', 'Export to Trello Clicked');
                    setTrelloDrawerVisible(true);
                  } else if (trelloTitle === 'Open in Trello') {
                    window.open(trelloIssueUrl, '_blank');
                  }
                }
              } else if (step === 'Add Members') {
                if (!uid) {
                  Modal.confirm({
                    content: '',
                    okText: 'Yes',
                    cancelText: 'No',
                    title: 'Please Sign Up to continue!',
                    onOk: () => {
                      history.push('/signup');
                    },
                  });
                } else {
                  trackAnalytics('Canvas Detail', 'Add Members Clicked');
                  setCanvasMemberModalVisible(true);
                }
              }
            }}
          >
            {step}
          </Menu.Item>
        ))}
      </Menu>
    );
    return menu;
  };

  const changeWorkflow = (workflowId: string) => {
    // Modal.confirm({
    //   content: '',
    //   okText: 'Yes',
    //   cancelText: 'No',
    //   title: 'Do you want to change the workflow step?',
    //   onOk: () => {
    const updatedWorkflow = workflow.map((workflowData: any) => {
      if (workflowData.id === canvasWorkflowId) {
        workflowData.total = workflowData.total - 1;
        return workflowData;
      } else if (workflowData.id === workflowId) {
        workflowData.total = workflowData.total + 1;
        return workflowData;
      } else {
        return workflowData;
      }
    });
    const updatedTimeStamp = new Date().getTime();
    firestore
      .collection('masterProjects')
      .doc(masterProjectId)
      .update({
        workflow: updatedWorkflow,
        updatedTimeStamp,
      });

    firestore
      .collection('projects')
      .doc(projectId)
      .update({
        workflowId,
      });
    //   },
    // });
  };

  const setDrawingMode = (drawingType: string, hideDrawing?: boolean) => {
    if (!uid) {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Please Sign Up to continue!',
        onOk: () => {
          history.push('/signup');
        },
      });
    } else {
      if (hideDrawing) {
        const event = eventConstant.HIDE_DRAWINGS;
        sendMessageToIframe(iframe, event, {});
        setDrawing(false);
      } else {
        if (drawingType === 'clearAll') {
          Modal.confirm({
            content: '',
            title: 'Delete all Annotations?',
            onOk: () => {
              const event = eventConstant.TOGGLE_DRAWING;
              sendMessageToIframe(iframe, event, {
                drawingType,
              });
              setDrawing(true);
              setActiveTab('freeDraw');
            },
          });
        } else {
          const event = eventConstant.TOGGLE_DRAWING;
          sendMessageToIframe(iframe, event, {
            drawingType,
          });
          setDrawing(true);
          setActiveTab(drawingType);
        }
      }
    }
  };

  const archivedCanvas = () => {
    if (!uid) {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Please Sign Up to continue!',
        onOk: () => {
          history.push('/signup');
        },
      });
    } else {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: `Do you want to ${archived === 'yes' ? 'restore' : 'archive'} this canvas?`,
        onOk: () => {
          const updatedWorkflow = workflow.map((workflowData: any) => {
            if (workflowData.id === canvasWorkflowId) {
              workflowData.total =
                archived === 'yes' ? workflowData.total + 1 : workflowData.total - 1;
              return workflowData;
            } else {
              return workflowData;
            }
          });
          const updatedTimeStamp = new Date().getTime();
          firestore
            .collection('masterProjects')
            .doc(masterProjectId)
            .update({
              workflow: updatedWorkflow,
              updatedTimeStamp,
            });

          firestore
            .collection('projects')
            .doc(projectId)
            .update({
              archived: archived === 'yes' ? 'no' : 'yes',
            });
        },
      });
    }
  };

  const actionMenu = () => {
    const menu = (
      <Menu>
        {['Delete Canvas', 'Tags', archived === 'yes' ? 'Restore' : 'Archive'].map(
          (action: string, index: any) => (
            <Menu.Item
              className='project-dropdown-item'
              key={'1' + index}
              onClick={() => {
                if (action === 'Delete Canvas') {
                  trackAnalytics('Canvas Detail', 'Delete Canvas Clicked in Menu');
                  deleteCanvas();
                } else if (action === 'Archive' || action === 'Restore') {
                  trackAnalytics('Canvas Detail', action + ' Clicked in Menu');
                  archivedCanvas();
                } else if (action === 'Tags') {
                  trackAnalytics('Canvas Detail', 'Tags Clicked in Menu');
                  setCanvasTagModalVisible(true);
                }
              }}
            >
              {action}
            </Menu.Item>
          ),
        )}
        {isCanvasOwner && (
          <Menu.Item
            key='password'
            className='project-dropdown-item'
            onClick={() => setShowPasswordField(projectId)}
          >
            {'Add password'}
          </Menu.Item>
        )}
        {isCanvasOwner && (
          <Menu.Item
            key='public'
            className='project-dropdown-item'
            onClick={() => {
              if (!canvasDetails.isPublic) {
                notification.success({
                  message: 'Your canvas is now visible to everyone with the canvas link.',
                });
              }
              changeVisibility(projectId, canvasDetails.isPublic);
            }}
          >
            {canvasDetails.isPublic ? 'Make Private' : 'Make Public'}
          </Menu.Item>
        )}
      </Menu>
    );
    return menu;
  };

  const deleteCanvas = () => {
    if (!uid) {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Please Sign Up to continue!',
        onOk: () => {
          history.push('/signup');
        },
      });
    } else {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Do you want to delete this canvas?',
        onOk: () => {
          // firestore
          //   .collection('projects')
          //   .doc(projectId)
          //   .get()
          //   .then((project: any) => {
          //     const projectData = project.data();
          //     if (projectData) {
          //
          //     }
          //   })
          // return true;
          /*
          Decrease workflow count
          Decrease canvas count
          Decrease totalVoiceClips
          Decrease totalVideoComments
          Decrease totalScreenRecordings
          Decrease storage limit
          */
          firestore
            .collection('projects')
            .doc(projectId)
            .delete()
            .then(() => {
              const updatedWorkflow = workflow.map((workflowData: any) => {
                if (workflowData.id === canvasWorkflowId) {
                  workflowData.total = workflowData.total - 1;
                  return workflowData;
                } else {
                  return workflowData;
                }
              });
              const updatedTimeStamp = new Date().getTime();
              firestore
                .collection('masterProjects')
                .doc(masterProjectId)
                .update({
                  workflow: updatedWorkflow,
                  updatedTimeStamp,
                })
                .then(() => {
                  const path = window.localStorage.getItem('dashboardViewPath') === '/pt/:id' ? `/pt/${masterProjectId}` :  `/pk/${masterProjectId}`;
                  history.push(path);
                });
            });
        },
      });
    }
  };

  const undoOrRedo = (isUndo: boolean) => {
    const event = eventConstant.UNDO_OR_REDO;
    sendMessageToIframe(iframe, event, {
      isUndo,
    });
  };

  const workstepsMenu = () => {
    const menu = (
      <Menu className='workstep-menu'>
        {workflow.map((workflowSteps: Iworkflow) => {
          const { step, id, color } = workflowSteps;
          return (
            <Menu.Item
              onClick={() => {
                changeWorkflow(id);
              }}
              className='workstep-menu-item project-dropdown-item'
              key={id}
            >
              <div
                className='workstep-menu-item-radio'
                style={{
                  backgroundColor: id === canvasWorkflowId ? color : 'transparent',
                  border: `1px solid ${color}`,
                }}
              ></div>
              {step}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    return menu;
  };

  const getSelectedWorkflow = () => {
    const matchedWorkflow = workflow.find((workflowStep) => workflowStep.id === canvasWorkflowId);
    const { color = '', step = 'Empty' } = matchedWorkflow || {};
    return (
      <Button>
        <div
          className='workstep-menu-item-radio'
          style={{ backgroundColor: color, border: `1px solid ${color}` }}
        ></div>
        {/* <Radio defaultChecked={true} disabled={true}></Radio> */}
        {step}
        <DropdownArrow />
      </Button>
    );
  };

  const changeVisibility = (projectIdTemp: any, isPublic: any) => {
    firestore
      .collection('projects')
      .doc(projectIdTemp)
      .set(
        {
          isPublic: !isPublic,
        },
        { merge: true },
      );
  };

  const renderControls = () => {
    return (
      <>
        <div className='canvas-header-background-controls' style={{ backgroundImage: `${Cover}` }}>
          <Tooltip placement='bottom' title={'Add New Drawings (d)'}>
            <span
              onClick={() => {
                if (isWriteAccess) {
                  trackAnalytics('Project Detail', `editing tab clicked freeDraw`);
                  setDrawingMode('freeDraw');
                }
              }}
              className='c-p'
            >
              <Pencil active={activeTab === 'freeDraw'} />
            </span>
          </Tooltip>

          <Tooltip placement='bottom' title={'Add New Boxes (x)'}>
            <span
              onClick={() => {
                if (isWriteAccess) {
                  setDrawingMode('drawRect');
                  trackAnalytics('Project Detail', `editing tab clicked Boxes`);
                }
              }}
              className='c-p'
            >
              <Box active={activeTab === 'drawRect'} />
            </span>
          </Tooltip>

          <Tooltip placement='bottom' title={'Add New Lines (l)'}>
            <span
              onClick={() => {
                if (isWriteAccess) {
                  setDrawingMode('drawLine');
                  trackAnalytics('Project Detail', `editing tab clicked drawLine`);
                }
              }}
              className='c-p'
            >
              <Line active={activeTab === 'drawLine'} />
            </span>
          </Tooltip>
          <Tooltip placement='bottom' title={'Add New Arrows (a)'}>
            <span
              onClick={() => {
                if (isWriteAccess) {
                  trackAnalytics('Project Detail', `editing tab clicked Arrows`);
                  setDrawingMode('drawArrows');
                }
              }}
              className='c-p'
            >
              <Arrow active={activeTab === 'drawArrows'} />
            </span>
          </Tooltip>
          <Tooltip placement='bottom' title={'Add New Circles (r)'}>
            <span
              onClick={() => {
                if (isWriteAccess) {
                  setDrawingMode('drawCircle');
                  trackAnalytics('Project Detail', `editing tab clicked Circles`);
                }
              }}
              className='c-p'
            >
              <Circle active={activeTab === 'drawCircle'} />
            </span>
          </Tooltip>

          <Tooltip placement='bottom' title='Add New Comments (c)'>
            <span
              onClick={() => {
                if (isWriteAccess) {
                  setDrawingMode('addText');
                  trackAnalytics('Project Detail', `editing tab clicked addText`);
                }
              }}
              className='c-p'
            >
              <Text active={activeTab === 'addText'} />
            </span>
          </Tooltip>

          <Tooltip placement='bottom' title=''>
            <span onClick={() => setdisplayColorPicker(true)} className='c-p'>
              <Record color={backgroundColor} />
            </span>

            {displayColorPicker ? (
              <div style={{ position: 'absolute', zIndex: 2 }}>
                <div
                  style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}
                  onClick={() => setdisplayColorPicker(false)}
                />
                <SketchPicker
                  color={backgroundColor}
                  onChange={(color: any) => {
                    const event = 'DRAWING_COLOR';
                    sendMessageToIframe(iframe, event, {
                      color: color.hex,
                    });
                    setBackgroundColor(color.hex);
                  }}
                />
              </div>
            ) : null}
          </Tooltip>
          <span
            onClick={() => {
              if (isWriteAccess) {
                undoOrRedo(true);
                trackAnalytics('Project Detail', `editing tab clicked undo`);
              }
            }}
            className='c-p'
          >
            <Undo disable={undoDisabled} />
          </span>
          <span
            onClick={() => {
              if (isWriteAccess) {
                undoOrRedo(false);
                trackAnalytics('Project Detail', `editing tab clicked redo`);
              }
            }}
            className='c-p'
          >
            <Redo disable={redoDisabled} />
          </span>
          <span
            onClick={() => {
              if (isWriteAccess) {
                setDrawingMode('clearAll');
                trackAnalytics('Project Detail', `editing tab clicked clearAll`);
              }
            }}
            className='c-p'
          >
            <Erase />
          </span>
        </div>
      </>
    );
  };
  const renderMenu = () => {
    return (
      <>
        {!isInterviewCanvas && (
          <div className='canvas-workflow-dropdown'>
            {isMobile ? (
              <DropDownCustom
                buttonComponent={() => getSelectedWorkflow()}
                position={'bottom-left'}
                items={workstepsMenu()}
              />
            ) : (
              <Dropdown overlay={workstepsMenu()}>{getSelectedWorkflow()}</Dropdown>
            )}
          </div>
        )}
        {!isInterviewCanvas && (
          <div className='canvas-share-dropdown'>
            {isMobile ? (
              <DropDownCustom
                buttonComponent={() => (
                  <Button>
                    Share
                    <span style={{ marginLeft: 8 }}>
                      <DropdownArrow />
                    </span>
                  </Button>
                )}
                position={'bottom'}
                items={shareMenu()}
              />
            ) : (
              <Dropdown overlay={shareMenu()}>
                <Button>
                  Share
                  <span style={{ marginLeft: 8 }}>
                    <DropdownArrow />
                  </span>
                </Button>
              </Dropdown>
            )}
          </div>
        )}
      </>
    );
  };
  const renderDropDown = () => {
    return isMobile ? (
      <DropDownCustom
        buttonComponent={() => (
          <div className='canvas-action-dropdown cursor-pointer'>
            <div className='cursor-pointer canvas-action-dropdown-dot'>
              <TripleDot />
            </div>
          </div>
        )}
        position={'bottom'}
        items={actionMenu()}
      />
    ) : (
      <div className='canvas-action-dropdown cursor-pointer'>
        <Dropdown overlay={actionMenu()}>
          <div className='cursor-pointer canvas-action-dropdown-dot'>
            <TripleDot />
          </div>
        </Dropdown>
      </div>
    );
  };

  return (
    <>
      <div className='canvas-header-container'>
        <div
          className={`canvas-header-left-section ${
            isMobile ? 'header-area mobile-canvas-header' : ''
          }`}
        >
          {!isInterviewCanvas && (
            <div
              className='canvas-detail-back cursor-pointer'
              onClick={() => {
                const path = window.localStorage.getItem('dashboardViewPath') === '/pt/:id' ? `/pt/${masterProjectId}` :  `/pk/${masterProjectId}`;
                history.push(path);
              }}
            >
              <BackIcon />
            </div>
          )}
          {!isEdit && (
            <div className={`${!isInterviewCanvas && 'canvas-detail-title'}`}>
              <div
                className={`${!isInterviewCanvas && 'canvas-detail-title-text cursor-pointer'}`}
                onClick={() => {
                  if (!isInterviewCanvas) {
                    setIsEdit(true);
                    updateCanvasTitle(title, false);
                  }
                }}
              >
                {title}
              </div>
              {projectId && (
                <span
                  className='edit-canvas-title cursor-pointer'
                  onClick={() => {
                    titleRef && titleRef.current && titleRef.current && titleRef.current.focus();
                    setIsEdit(true);
                    updateCanvasTitle(title, false);
                  }}
                >
                  Edit
                </span>
              )}
            </div>
          )}
          {isEdit && (
            <div className='canvas-detail-title'>
              <Input
                ref={titleRef}
                className='canvas-title-edit-input'
                placeholder='Enter canvas title'
                value={canvasTitle}
                autoFocus
                onChange={(e: any) => {
                  updateCanvasTitle(e.target.value, false);
                }}
                onBlur={() => {
                  updateCanvasTitle(canvasTitle, true);
                  setIsEdit(false);
                }}
                onKeyPress={(event: any) => {
                  if (event.key === 'Enter') {
                    updateCanvasTitle(canvasTitle, true);
                    setIsEdit(false);
                  }
                }}
              />
            </div>
          )}
          {isMobile && isEdit && (
            <Icon
              onClick={() => {
                updateCanvasTitle(canvasTitle, true);
                setIsEdit(false);
              }}
              type='check-circle'
            />
          )}
          <div className='project-tag-container'>
            {tags.map((tag: { title: string; color: string }, index: number) => {
              const { color } = tag;
              return (
                <Tooltip title={tag.title} key={index}>
                  <Popover
                    content={
                      <div
                        style={{ textAlign: 'end', marginRight: '10px' }}
                        onClick={() =>
                          setTagModalTooltipArr((data: any) => {
                            data[index] = false;
                            return JSON.parse(JSON.stringify(data));
                          })
                        }
                      >
                        x
                      </div>
                    }
                    title={tag.title}
                    trigger='click'
                    visible={isMobile ? tagModalTooltipArr[index] : false}
                    // onVisibleChange={() => setTagModalTooltipArr((data: any) => {
                    //   data[index] = false;
                    //   return data;
                    // })}
                  >
                    <div
                      onClick={() =>
                        isMobile
                          ? setTagModalTooltipArr((data: any) => {
                              data[index] = true;
                              return JSON.parse(JSON.stringify(data));
                            })
                          : setCanvasTagModalVisible(true)
                      }
                      className='project-tag-color canvas-detail-tag'
                      style={{ backgroundColor: color }}
                    />
                  </Popover>
                </Tooltip>
              );
            })}
          </div>

          {isMobile && !isInterviewCanvas && (
            <div className='canvas-header-right-section'>{renderDropDown()}</div>
          )}
          {!isMobile && (
            <div className='canvas-member-avtar'>
              <MemberAvtar
                setCanvasMemberModalVisible={setCanvasMemberModalVisible}
                members={members}
              />
            </div>
          )}
        </div>
        {!isMobile && !isInterviewCanvas && (
          <div className='canvas-header-right-section'>
            {showControls && renderControls()}
            {renderMenu()}
            {renderDropDown()}
          </div>
        )}
      </div>
      {isMobile && (
        <div className={'mobile-canvas-drop-down-container'}>
          {renderMenu()}
          <div className='canvas-member-avtar'>
            <MemberAvtar
              setCanvasMemberModalVisible={setCanvasMemberModalVisible}
              members={members}
            />
          </div>
        </div>
      )}
      <div>
        {/* {showControls && isMobile && <div className='mobile-canvas-controls-container'>
          {renderControls()}
        </div>} */}
      </div>
      <CanvasTagDialog
        visible={canvasTagModalVisible}
        closeModal={handleCloseModale}
        tagList={tagList}
        projectId={projectId}
        addedTags={tags}
      />
      <CreateJiraIssueForm
        visible={jiraDrawerVisible}
        setVisible={setJiraVisible}
        projectId={projectId}
        selectedOrganizationData={selectedOrganizationData}
      />
      <CreateTrelloIssueForm
        visible={trelloDrawerVisible}
        setVisible={setTrelloVisible}
        projectId={projectId}
        selectedOrganizationData={selectedOrganizationData}
      />
      <CanvasMemberDialog
        visible={canvasMemberModalVisible}
        closeModal={handleCloseMemberModal}
        memberList={memberList}
        projectId={projectId}
        addedMembers={members}
      />
      <CreatePassword
        visible={showPasswordField.length > 0}
        setVisible={() => setShowPasswordField('')}
        projectId={showPasswordField}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    undoOrRedoValue: state.project.undoOrRedo,
  };
};

export default connect(
  mapStateToProps,
  null,
)(CanvasHeader as any) as any;
