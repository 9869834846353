import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
 import { copyURL,
    //mobileCheck 
} from '../../utils';

interface CallbackModalI {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
}
// const isMobile = mobileCheck();
const CallbackModal = ({ isOpen, setIsOpen }: CallbackModalI) => {
    const [isCopied, setCopied] = useState(false);
    const [url, setURL] = useState('');
    useEffect(() => {
        if(isOpen){
            setCopied(false);
            const selectedMasterProjectId: string | null = window.localStorage.getItem(
                'selectedMasterProjectId',
              );
              const selectedWorkflowId: string | null = window.localStorage.getItem(
                'selectedWorkflowId',
              );
              const voiceClipURL = `${window.location.origin}/callback/${
                selectedMasterProjectId
            }/${selectedWorkflowId}`;
            setURL(voiceClipURL)
        }
    },[isOpen])

    const copyHandler = () => {
        copyURL(url);
        setCopied(true);
        setTimeout(() => {
        setCopied(false);
        },3000)
    }
    return (
        <Modal
            title='Callback'
            visible={isOpen}
            style={{ textAlign: 'center' }}
            onCancel={() => setIsOpen(false)}
            footer={null}
        >
            <div id='microphone-permission-error'>
                <div>
                Share this URL with prospects who are interested in callback. 
                <div>{url}</div>
                { !isCopied ? 
                <span style={{
                    color: '#001DAD',
                    paddingLeft: '5px',
                    cursor: 'pointer'
                }} onClick={copyHandler}>Copy Link</span> :
                <span style={{
                    color: '#001DAD',
                    paddingLeft: '5px'
                }} >Copied</span>
                }
                </div>
            </div>
        </Modal>
    );
};

export default CallbackModal;