import { Avatar, Tooltip } from 'antd';
import ColorContext from 'context/ColorContext';
import firebase from 'firebase';
import { ZselectedWorkspace, ZselectedWorkspaceWraper } from 'primitives';
import React, { Fragment, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { statsAction } from 'store/actions/authActions';
import { IOrgProject } from 'types/projectTypes';
import { projectColors } from 'utils/dummyData';
import { v4 as uuid } from 'uuid';
import { mobileCheck } from '../../../utils';
import { AddIcon } from '../../svg';

const isMobile = mobileCheck();

interface IProps {
  orgId: string;
  handleSelectedProject: any;
  projects: IOrgProject[];
  selectedProject: IOrgProject;
  getOrgProjects: any;
  profile: any;
  auth: any;
  selectedOrganizationData: any;
}

const OrgProjectList: React.FC<IProps> = ({
  projects,
  handleSelectedProject,
  selectedProject,
  orgId,
  getOrgProjects,
  profile,
  auth,
  selectedOrganizationData,
}) => {
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  const context = useContext(ColorContext);

  useEffect(() => {
    context.handleUpdateColor(selectedProject.projectColors);
  }, [selectedProject.projectColors]);

  const handleSelectProject = (project: IOrgProject, projectId: string) => {
    window.localStorage.setItem('selectedProjectId', projectId);
    handleSelectedProject(project);
    context.handleUpdateColor(project.projectColors);
    getOrgProjects();
  };

  const handelAddProject = () => {
    const projectId: string = uuid();
    const firestore = firebase.firestore();
    firestore
      .collection('organization')
      .doc(orgId)
      .set(
        {
          projects: [
            ...projects,
            {
              projectColors,
              projectName: 'Untitled project',
              id: projectId,
            },
          ],
        },
        { merge: true },
      )
      .then(async () => {
        if (auth && auth.email && auth.uid) {
          const chatId = await firestore.collection('chat').add({
            messages: [],
            count: 0,
          });
          const uniqueId = uuid();
          const { fullName = '' } = profile;
          // await firestore.collection('organization').add({
          //   member_ids: [auth.uid],
          //   members: [
          //     {
          //       id: auth.uid,
          //       name: fullName || '',
          //       image: '',
          //       role: 'Admin',
          //       invite_by: auth.uid,
          //       status: 'ACTIVE',
          //       email: auth.email || '',
          //     },
          //   ],
          //   projects: [
          //     {
          //       projectColors,
          //       projectName: 'Untitled project',
          //       id: projectId,
          //     },
          //   ],
          //   created_by: auth.uid,
          // });
          const updatedTimeStamp = new Date().getTime();
          await firestore.collection('masterProjects').add({
            updatedTimeStamp,
            name: 'Sample Workspace',
            imageUrl: '',
            color: '#BDBDBD',
            imageThumbnailUrl: '',
            created_by: auth.uid,
            chatId: chatId.id,
            workflow: [
              {
                step: 'Prospects',
                total: 0,
                color: '#828282',
                id: `a1${uniqueId}`,
              },
              {
                step: 'Interested',
                total: 0,
                color: ' #2D9CDB',
                id: `a2${uniqueId}`,
              },
              {
                step: 'Not Interested',
                total: 0,
                color: '#F2C94C',
                id: `a3${uniqueId}`,
              },
              {
                step: 'Didn’t Pick',
                total: 0,
                color: '#EB5757',
                id: `a4${uniqueId}`,
              },
              {
                step: 'Completed',
                total: 0,
                color: '#27AE60',
                id: `a5${uniqueId}`,
              },
            ],
            member_emails: [auth.email],
            member_ids: [auth.uid],
            members: [
              {
                id: auth.uid,
                name: fullName || '',
                image: '',
                role: 'Admin',
                invite_by: auth.uid,
                status: 'ACTIVE',
                email: auth.email || '',
              },
            ],
            projectId,
          });
        }

        statsAction('ORGANIZATION_Project_CREATED', firestore);
      })
      .catch((error: any) => {
        console.error('Error adding document: ', error);
      });
  };

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div style={{ width: 80 }} className='project'>
          {projects.map((project) => {
            const { projectName = '', id = '', isDeleted, logoThumbnailUrl } = project;
            return (
              <Fragment key={id}>
                {!isDeleted ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={projectName} placement='right'>
                      <ZselectedWorkspaceWraper
                        bgColor={theme.secondaryBtn.color1}
                        onClick={() => handleSelectProject(project, id)}
                      >
                        {id === selectedProject.id ? (
                          <ZselectedWorkspace bgColor={theme.primaryBtn.color2} />
                        ) : null}
                        {logoThumbnailUrl ? (
                          <div
                            className='brand-img'
                            style={{ backgroundImage: `url(${logoThumbnailUrl})` }}
                          />
                        ) : (
                          <>
                            <Avatar className='project-thumb-image' key={id} shape='square'>
                              {`${projectName && projectName[0].toUpperCase()}${
                                projectName[1] ? projectName[1].toUpperCase() : ''
                              }`}
                            </Avatar>
                          </>
                        )}
                      </ZselectedWorkspaceWraper>
                    </Tooltip>
                    {isMobile ? <span>{projectName} </span> : ''}
                  </div>
                ) : null}
              </Fragment>
            );
          })}
          {!isMobile && (
            <Tooltip
              title={
                userQuota && userQuota.manageMultipleProjects
                  ? 'Add Project'
                  : "You don't have access to create multiple projects."
              }
              placement='right'
            >
              <span
                className={`${
                  userQuota && userQuota.manageMultipleProjects ? '' : 'disabled'
                } project-thumb-image-container add-project`}
                onClick={() => {
                  userQuota && userQuota.manageMultipleProjects && handelAddProject();
                }}
              >
                <AddIcon />
              </span>
            </Tooltip>
          )}
        </div>
      )}
    </ColorContext.Consumer>
  );
};

// export default OrgProjectList;

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    null,
  ),
)(OrgProjectList);
