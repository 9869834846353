import React from 'react';

export const SendMessageIcon = ({ color }: { color: string }) => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        d='M20.1667 1.83333L10.0834 11.9167'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.1667 1.83333L13.75 20.1667L10.0834 11.9167L1.83337 8.25L20.1667 1.83333Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const RecordIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='12' fill='#EB5757' />
    </svg>
  );
};

export const VideoRecordIcon = () => {
  return (
    <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M1.63636 0H11.4545C12.3583 0 13.0909 0.732625 13.0909 1.63636V2.76704L18 0.312511V11.142L13.0909 8.6875V9.81818C13.0909 10.7219 12.3583 11.4545 11.4545 11.4545H1.63636C0.732625 11.4545 0 10.7219 0 9.81818V1.63636C0 0.732625 0.732625 0 1.63636 0ZM13.0909 6.85799L16.3636 8.49433V2.9602L13.0909 4.59655V6.85799ZM1.63636 1.63636V9.81818H11.4545V1.63636H1.63636Z'
        fill='#4F4F4F'
      />
    </svg>
  );
};

export const PauseIcon = () => {
  return (
    <svg width='14' height='20' viewBox='0 0 14 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='5' height='20' rx='2' fill='#333333' />
      <rect x='9' width='5' height='20' rx='2' fill='#333333' />
    </svg>
  );
};

export const PauseIconPlayer = () => {
  return (
    <svg width='45' height='45' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.15' cx='22.5' cy='22.5' r='22.5' fill='black' />
      <rect x='17' y='15' width='3.75' height='15' rx='1.875' fill='#4F4F4F' />
      <rect x='24.6877' y='15' width='3.75' height='15' rx='1.875' fill='#4F4F4F' />
    </svg>
  );
};

export const PlayIconPlayer = () => {
  return (
    <svg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.15' cx='23.46' cy='23.3932' r='22.5' fill='#333333' />
      <path
        d='M30.4604 22.5269C31.1271 22.9118 31.1271 23.8741 30.4604 24.259L20.7104 29.8881C20.0438 30.273 19.2104 29.7919 19.2104 29.0221L19.2104 17.7638C19.2104 16.994 20.0438 16.5128 20.7104 16.8977L30.4604 22.5269Z'
        fill='#4F4F4F'
      />
    </svg>
  );
};

// please use this for video bottom sound Icon
export const SoundIcon = ({ active }: { active: boolean }) => {
  return (
    <svg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.459961' width='22.3616' height='22' rx='5' fill='#828282' fill-opacity='0.75' />
      <path
        d='M17.6411 16.126L16.3906 14.8756C17.3181 13.7978 17.8261 12.4219 17.8216 11C17.8216 8.47131 16.2936 6.39322 14.1135 5.437V6.67323C15.5889 7.52993 16.5853 9.17472 16.5853 11C16.5826 12.0981 16.2195 13.165 15.5519 14.0368L14.7576 13.2425C15.1297 12.5675 15.3497 11.7356 15.3497 11C15.3497 9.90533 14.8707 8.58937 14.1135 7.90945V12.5984L12.8773 11.3622V6.05511C12.8772 5.94331 12.8468 5.83361 12.7894 5.73771C12.7319 5.6418 12.6495 5.56328 12.5509 5.51051C12.4524 5.45774 12.3413 5.43269 12.2296 5.43803C12.118 5.44337 12.0098 5.47891 11.9167 5.54085L8.99988 7.4848L6.51508 5L5.64107 5.87401L16.7671 17L17.6411 16.126ZM11.6411 7.20975V10.126L9.8912 8.37612L11.6411 7.20975ZM6.69618 14.0906H8.36323L11.9161 16.4592C12.0093 16.521 12.1175 16.5566 12.2293 16.562C12.341 16.5675 12.4522 16.5426 12.5509 16.4901C12.6496 16.4372 12.732 16.3586 12.7895 16.2626C12.847 16.1666 12.8773 16.0568 12.8773 15.9449V14.7835L11.6411 13.5472V14.7903L8.89357 12.9582C8.87811 12.9477 8.85895 12.9458 8.84288 12.9378C8.76989 12.898 8.68953 12.8736 8.60676 12.8661C8.58698 12.8642 8.57029 12.8543 8.55051 12.8543H6.69618V9.14567H7.2395L6.14112 8.04729C5.93707 8.14942 5.76537 8.30617 5.64511 8.50009C5.52485 8.69401 5.46076 8.91749 5.45996 9.14567V12.8543C5.45996 13.5361 6.01441 14.0906 6.69618 14.0906Z'
        fill={active ? '#306AE1' : '#F2F2F2'}
      />
    </svg>
  );
};

export const PrevPlay = () => {
  return (
    <svg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M2 5.36577C2.49917 4.71193 3.15071 3.87402 3.57082 3.41293C5.53403 1.25818 7.76148 0 10.5 0C16.8897 0 20 4.41222 20 10H18C18 5.39719 15.6052 2 10.5 2C8.40884 2 6.66775 2.98346 5.04921 4.75991C4.6264 5.22397 3.82048 6.28244 3.26844 7H9V9H0V0H2V5.36577Z'
        fill='#333333'
      />
    </svg>
  );
};

export const NextPlay = () => {
  return (
    <svg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M18 5.36577V0H20V9H11V7H16.7316C16.1795 6.28244 15.3736 5.22397 14.9508 4.75991C13.3323 2.98346 11.5912 2 9.5 2C4.39481 2 2 5.39719 2 10H0C0 4.41222 3.11035 0 9.5 0C12.2385 0 14.466 1.25818 16.4292 3.41293C16.8493 3.87402 17.5008 4.71193 18 5.36577Z'
        fill='#333333'
      />
    </svg>
  );
};

export const Waves = () => {
  return (
    <svg
      width='180'
      height='36'
      viewBox='0 0 180 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0.1 7.1H3.9V35.9H0.1V7.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M4.1 11.1H7.9V35.9H4.1V11.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M8.1 18.1H11.9V35.9H8.1V18.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M12.1 24.1H15.9V35.9H12.1V24.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M16.1 28.1H19.9V35.9H16.1V28.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M20.1 29.1H23.9V35.9H20.1V29.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M24.1 25.1H27.9V35.9H24.1V25.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M28.1 20.1H31.9V35.9H28.1V20.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M32.1 17.1H35.9V35.9H32.1V17.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M36.1 15.1H39.9V35.9H36.1V15.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M40.1 12.1H43.9V35.9H40.1V12.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M44.1 14.1H47.9V35.9H44.1V14.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M48.1 16.1H51.9V35.9H48.1V16.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M52.1 18.1H55.9V35.9H52.1V18.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M56.1 19.1H59.9V35.9H56.1V19.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M60.1 19.1H63.9V35.9H60.1V19.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M64.1 16.1H67.9V35.9H64.1V16.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M68.1 12.1H71.9V35.9H68.1V12.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M72.1 14.1H75.9V35.9H72.1V14.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M76.1 16.1H79.9V35.9H76.1V16.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M80.1 20.1H83.9V35.9H80.1V20.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M84.1 23.1H87.9V35.9H84.1V23.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M88.1 18.1H91.9V35.9H88.1V18.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M92.1 11.1H95.9V35.9H92.1V11.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M96.1 7.1H99.9V35.9H96.1V7.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M100.1 4.1H103.9V35.9H100.1V4.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M104.1 2.1H107.9V35.9H104.1V2.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M108.1 0.1H111.9V35.9H108.1V0.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M112.1 2.1H115.9V35.9H112.1V2.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path d='M116.1 9.1H119.9V35.9H116.1V9.1Z' fill='#306AE1' stroke='white' strokeWidth='0.2' />
      <path
        d='M120.1 15.1H123.9V35.9H120.1V15.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M124.1 22.1H127.9V35.9H124.1V22.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M128.1 27.1H131.9V35.9H128.1V27.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M132.1 31.1H135.9V35.9H132.1V31.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M136.1 33.1H139.9V35.9H136.1V33.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M140.1 34.1H143.9V35.9H140.1V34.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M152.1 34.1H155.9V35.9H152.1V34.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M156.1 32.1H159.9V35.9H156.1V32.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M160.1 29.1H163.9V35.9H160.1V29.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M164.1 26.1H167.9V35.9H164.1V26.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M168.1 24.1H171.9V35.9H168.1V24.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M172.1 21.1H175.9V35.9H172.1V21.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
      <path
        d='M176.1 18.1H179.9V35.9H176.1V18.1Z'
        fill='#306AE1'
        stroke='white'
        strokeWidth='0.2'
      />
    </svg>
  );
};

export const AudioSvg = () => {
  return (
    <svg width='14' height='17' viewBox='0 0 14 17' fill='none'>
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M6.54545 0C8.35293 0 9.81818 1.38385 9.81818 3.09091V8.5C9.81818 10.2071 8.35293 11.5909 6.54545 11.5909C4.73798 11.5909 3.27273 10.2071 3.27273 8.5V3.09091C3.27273 1.38385 4.73798 0 6.54545 0ZM7.36364 14.634V15.4546H9.81818V17H3.27273V15.4546H5.72727V14.634C2.49847 14.2537 0 11.6524 0 8.50001V6.95456H1.63636V8.50001C1.63636 11.0606 3.83424 13.1364 6.54545 13.1364C9.25667 13.1364 11.4545 11.0606 11.4545 8.50001V6.95456H13.0909V8.50001C13.0909 11.6524 10.5924 14.2537 7.36364 14.634ZM5 3.45455C5 2.65122 5.67157 2 6.5 2C7.32843 2 8 2.65122 8 3.45455V8.54545C8 9.34878 7.32843 10 6.5 10C5.67157 10 5 9.34878 5 8.54545V3.45455Z'
        fill='#4F4F4F'
      />
    </svg>
  );
};

export const BulbSvg = () => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.32266 1.87988L4.22266 2.97832L5.85938 4.61426L6.95781 3.51582L5.32187 1.87988H5.32266ZM19.6781 1.87988L18.0414 3.51582L19.1406 4.61426L20.7766 2.9791L19.6781 1.87988ZM12.5 2.36738C12.2422 2.37051 11.9812 2.38535 11.7188 2.41738C11.7109 2.41738 11.7031 2.41582 11.6953 2.41738C8.52656 2.78066 6.00469 5.34082 5.56641 8.49551C5.21641 11.0346 6.26328 13.3369 8.00781 14.8197C8.72141 15.4287 9.20409 16.2645 9.375 17.1869V21.8744H11.1562C11.4281 22.3408 11.9242 22.6557 12.5 22.6557C13.0758 22.6557 13.5719 22.3408 13.8437 21.8744H15.625V18.7494H15.6984V17.8213C15.6984 16.676 16.2937 15.5213 17.2609 14.6236C18.5547 13.3283 19.5312 11.4885 19.5312 9.3752C19.5312 5.51582 16.3547 2.33145 12.5 2.36738ZM12.5 3.92988C15.5195 3.8877 17.9688 6.35957 17.9688 9.3752C17.9688 11.0111 17.2109 12.4518 16.1617 13.5002L16.1867 13.5252C15.144 14.4867 14.4698 15.7821 14.2805 17.1877H10.8633C10.6914 15.8486 10.1172 14.5525 9.03203 13.6229C7.65156 12.451 6.82891 10.6854 7.10391 8.6916C7.44531 6.23066 9.44219 4.25176 11.8891 3.98066C12.0911 3.9525 12.2945 3.93581 12.4984 3.93066L12.5 3.92988ZM1.5625 9.3752V10.9377H3.90625V9.3752H1.5625ZM21.0938 9.3752V10.9377H23.4375V9.3752H21.0938ZM5.85938 15.6986L4.22344 17.3338L5.32266 18.433L6.95703 16.7971L5.85938 15.6986ZM19.1406 15.6986L18.0422 16.7971L19.6773 18.433L20.7766 17.3338L19.1406 15.6986ZM10.9375 18.7502H14.0625V20.3127H10.9375V18.7502Z'
        fill='#464546'
      />
    </svg>
  );
};

export const VideoSvg = () => {
  return (
    <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M1.63636 0H11.4545C12.3583 0 13.0909 0.732625 13.0909 1.63636V2.76704L18 0.312511V11.142L13.0909 8.6875V9.81818C13.0909 10.7219 12.3583 11.4545 11.4545 11.4545H1.63636C0.732625 11.4545 0 10.7219 0 9.81818V1.63636C0 0.732625 0.732625 0 1.63636 0ZM13.0909 6.85799L16.3636 8.49433V2.9602L13.0909 4.59655V6.85799ZM1.63636 1.63636V9.81818H11.4545V1.63636H1.63636Z'
        fill='#4F4F4F'
      />
    </svg>
  );
};

export const ScreenSvg = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M6.18182 13.1364V13.9091H4.63636V15.4545H12.3636V13.9091H10.8182V13.1364H15.4545C16.3081 13.1364 17 12.4444 17 11.5909V1.54545C17 0.691923 16.3081 0 15.4545 0H1.54545C0.691923 0 0 0.691923 0 1.54545V11.5909C0 12.4444 0.691923 13.1364 1.54545 13.1364H6.18182ZM15.4545 11.5909H1.54544V1.54544H15.4545V11.5909Z'
        fill='#4F4F4F'
      />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg width='20' height='22' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.1384 9.79732C1.1384 10.3977 1.62663 10.8859 2.22699 10.8859H7.66994C8.2703 10.8859 8.75853 10.3977 8.75853 9.79732V3.26577H9.84712V2.17718H7.66994V1.08859C7.66994 0.488233 7.18171 0 6.58135 0H3.31558C2.71522 0 2.22699 0.488233 2.22699 1.08859V2.17718H0.0498047V3.26577H1.1384V9.79732ZM3.31558 1.08859H6.58135V2.17718H3.31558V1.08859ZM2.77128 3.26577H7.66994L7.67048 9.79732H2.22699V3.26577H2.77128Z'
        fill='#BDBDBD'
      />
      <path
        d='M3.31531 4.35428H4.4039V8.70864H3.31531V4.35428ZM5.49249 4.35428H6.58108V8.70864H5.49249V4.35428Z'
        fill='#BDBDBD'
      />
    </svg>
  );
};

export const NavigatorIcon = () => {
  return (
    <svg width='26' height='24' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.43396 0C3.12057 0 0.43396 2.68661 0.43396 6C0.43396 9.31339 3.12057 12 6.43396 12C9.74735 12 12.434 9.31339 12.434 6C12.434 2.68661 9.74735 0 6.43396 0ZM6.43396 10.9821C3.68307 10.9821 1.45182 8.75089 1.45182 6C1.45182 3.24911 3.68307 1.01786 6.43396 1.01786C9.18485 1.01786 11.4161 3.24911 11.4161 6C11.4161 8.75089 9.18485 10.9821 6.43396 10.9821Z'
        fill='#BDBDBD'
      />
      <path
        d='M5.79126 3.64302C5.79126 3.81352 5.85899 3.97703 5.97955 4.09759C6.10011 4.21815 6.26362 4.28588 6.43412 4.28588C6.60461 4.28588 6.76813 4.21815 6.88869 4.09759C7.00924 3.97703 7.07697 3.81352 7.07697 3.64302C7.07697 3.47252 7.00924 3.30901 6.88869 3.18845C6.76813 3.06789 6.60461 3.00016 6.43412 3.00016C6.26362 3.00016 6.10011 3.06789 5.97955 3.18845C5.85899 3.30901 5.79126 3.47252 5.79126 3.64302ZM6.75555 5.14302H6.11269C6.05376 5.14302 6.00555 5.19123 6.00555 5.25016V8.89302C6.00555 8.95195 6.05376 9.00016 6.11269 9.00016H6.75555C6.81447 9.00016 6.86269 8.95195 6.86269 8.89302V5.25016C6.86269 5.19123 6.81447 5.14302 6.75555 5.14302Z'
        fill='#BDBDBD'
      />
    </svg>
  );
};

export const FullScreenButtons = () => {
  return (
    <svg width='46' height='22' viewBox='0 0 46 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.7'>
        <rect width='25.203' height='21.7794' rx='5' fill='#9B9B9B' fill-opacity='0.75' />
      </g>
    </svg>
  );
};

export const FullScreenCinemaButtons = () => {
  return (
    <svg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='1.38971' width='12.4236' height='7' stroke='#F2F2F2' />
    </svg>
  );
};

export const AudioBarsSmall = () => {
  return (
    <svg
      width='180'
      height='36'
      viewBox='0 0 180 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.25 7.25H3.75V35.75H0.25V7.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M4.25 11.25H7.75V35.75H4.25V11.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M8.25 18.25H11.75V35.75H8.25V18.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M12.25 24.25H15.75V35.75H12.25V24.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M16.25 28.25H19.75V35.75H16.25V28.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M20.25 29.25H23.75V35.75H20.25V29.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M24.25 25.25H27.75V35.75H24.25V25.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M28.25 20.25H31.75V35.75H28.25V20.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M32.25 17.25H35.75V35.75H32.25V17.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M36.25 15.25H39.75V35.75H36.25V15.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M40.25 12.25H43.75V35.75H40.25V12.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M44.25 14.25H47.75V35.75H44.25V14.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M48.25 16.25H51.75V35.75H48.25V16.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M52.25 18.25H55.75V35.75H52.25V18.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M56.25 19.25H59.75V35.75H56.25V19.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M60.25 19.25H63.75V35.75H60.25V19.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M64.25 16.25H67.75V35.75H64.25V16.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M68.25 12.25H71.75V35.75H68.25V12.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M72.25 14.25H75.75V35.75H72.25V14.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M76.25 16.25H79.75V35.75H76.25V16.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M80.25 20.25H83.75V35.75H80.25V20.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M84.25 23.25H87.75V35.75H84.25V23.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M88.25 18.25H91.75V35.75H88.25V18.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M92.25 11.25H95.75V35.75H92.25V11.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M96.25 7.25H99.75V35.75H96.25V7.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M100.25 4.25H103.75V35.75H100.25V4.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M104.25 2.25H107.75V35.75H104.25V2.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M108.25 0.25H111.75V35.75H108.25V0.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M112.25 2.25H115.75V35.75H112.25V2.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M116.25 9.25H119.75V35.75H116.25V9.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M120.25 15.25H123.75V35.75H120.25V15.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M124.25 22.25H127.75V35.75H124.25V22.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M128.25 27.25H131.75V35.75H128.25V27.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M132.25 31.25H135.75V35.75H132.25V31.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M136.25 33.25H139.75V35.75H136.25V33.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M140.25 34.25H143.75V35.75H140.25V34.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M152.25 34.25H155.75V35.75H152.25V34.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M156.25 32.25H159.75V35.75H156.25V32.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M160.25 29.25H163.75V35.75H160.25V29.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M164.25 26.25H167.75V35.75H164.25V26.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M168.25 24.25H171.75V35.75H168.25V24.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M172.25 21.25H175.75V35.75H172.25V21.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
      <path
        d='M176.25 18.25H179.75V35.75H176.25V18.25Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='0.5'
      />
    </svg>
  );
};

export const AudioBars = () => {
  return (
    <svg
      width={'1051'}
      height='120'
      viewBox='0 0 1051 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1 23.9443H24.3111V119H1V23.9443Z' fill='#ECF5FF' stroke='#306AE1' strokeWidth='2' />
      <path
        d='M24.3111 37.0554H47.6222V119H24.3111V37.0554Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M47.6222 60H70.9333V119H47.6222V60Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M70.9333 79.6667H94.2444V119H70.9333V79.6667Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M94.2444 92.7778H117.556V119H94.2444V92.7778Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M117.556 96.0557H140.867V119H117.556V96.0557Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M140.867 82.9443H164.178V119H140.867V82.9443Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M164.178 66.5557H187.489V119H164.178V66.5557Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M187.489 56.7222H210.8V119H187.489V56.7222Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M210.8 50.1667H234.111V119H210.8V50.1667Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M234.111 40.3333H257.422V119H234.111V40.3333Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M257.422 46.8889H280.733V119H257.422V46.8889Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M280.733 53.4443H304.044V119H280.733V53.4443Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M304.044 60H327.356V119H304.044V60Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M327.356 63.2778H350.667V119H327.356V63.2778Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M350.667 63.2778H373.978V119H350.667V63.2778Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M373.978 53.4443H397.289V119H373.978V53.4443Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M397.289 40.3333H420.6V119H397.289V40.3333Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M420.6 46.8889H443.911V119H420.6V46.8889Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M443.911 53.4443H467.222V119H443.911V53.4443Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M467.222 66.5557H490.533V119H467.222V66.5557Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M490.533 76.3889H513.844V119H490.533V76.3889Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M513.844 60H537.156V119H513.844V60Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M537.156 37.0554H560.467V119H537.156V37.0554Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M560.467 23.9443H583.778V119H560.467V23.9443Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M583.778 14.1111H607.089V119H583.778V14.1111Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M607.089 7.55542H630.4V119H607.089V7.55542Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path d='M630.4 1H653.711V119H630.4V1Z' fill='#ECF5FF' stroke='#306AE1' strokeWidth='2' />
      <path
        d='M653.711 7.55542H677.022V119H653.711V7.55542Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M677.022 30.5H700.333V119H677.022V30.5Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M700.333 50.1667H723.644V119H700.333V50.1667Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M723.644 73.1111H746.956V119H723.644V73.1111Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M746.956 89.5H770.267V119H746.956V89.5Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M770.267 102.611H793.578V119H770.267V102.611Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M793.578 109.167H816.889V119H793.578V109.167Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M816.889 112.444H840.2V119H816.889V112.444Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M886.822 112.444H910.133V119H886.822V112.444Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M910.133 105.889H933.444V119H910.133V105.889Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M933.444 96.0557H956.756V119H933.444V96.0557Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M956.756 86.2222H980.067V119H956.756V86.2222Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M980.067 79.6667H1003.38V119H980.067V79.6667Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path
        d='M1003.38 69.8333H1026.69V119H1003.38V69.8333Z'
        fill='#ECF5FF'
        stroke='#306AE1'
        strokeWidth='2'
      />
      <path d='M1026.69 60H1050V119H1026.69V60Z' fill='#ECF5FF' stroke='#306AE1' strokeWidth='2' />
    </svg>
  );
};

export const PlayIconV2 = () => {
  return (
    <svg width='35' height='35' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.5 16.5L16.5 12L9.5 7.5V16.5ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z'
        fill='#306AE1'
      />
    </svg>
  );
};

export const PauseIconV2 = () => {
  return (
    <svg width='35' height='35' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 16H11V8H9V16ZM13 16H15V8H13V16ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z'
        fill='#306AE1'
      />
    </svg>
  );
};

export const Speed1x = () => {
  return (
    <svg width='35' height='35' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 21C8.61667 21 7.31667 20.7373 6.1 20.212C4.88333 19.6867 3.825 18.9743 2.925 18.075C2.025 17.175 1.31267 16.1167 0.788 14.9C0.263333 13.6833 0.000666667 12.3833 0 11C0 9.61667 0.262667 8.31667 0.788 7.1C1.31333 5.88333 2.02567 4.825 2.925 3.925C3.825 3.025 4.88333 2.31267 6.1 1.788C7.31667 1.26333 8.61667 1.00067 10 1C11.3833 1 12.6833 1.26267 13.9 1.788C15.1167 2.31333 16.175 3.02567 17.075 3.925C17.975 4.825 18.6877 5.88333 19.213 7.1C19.7383 8.31667 20.0007 9.61667 20 11C20 12.3833 19.7373 13.6833 19.212 14.9C18.6867 16.1167 17.9743 17.175 17.075 18.075C16.175 18.975 15.1167 19.6877 13.9 20.213C12.6833 20.7383 11.3833 21.0007 10 21ZM10 19C12.2333 19 14.125 18.225 15.675 16.675C17.225 15.125 18 13.2333 18 11C18 8.76667 17.225 6.875 15.675 5.325C14.125 3.775 12.2333 3 10 3C7.76667 3 5.875 3.775 4.325 5.325C2.775 6.875 2 8.76667 2 11C2 13.2333 2.775 15.125 4.325 16.675C5.875 18.225 7.76667 19 10 19Z'
        fill='#306AE1'
      />
      <path
        d='M7.7666 6.85156V14H6.86328V7.97949L5.04199 8.64355V7.82812L7.625 6.85156H7.7666ZM11.1309 8.7168L12.2881 10.6406L13.46 8.7168H14.5195L12.791 11.3242L14.5732 14H13.5283L12.3076 12.0176L11.0869 14H10.0371L11.8145 11.3242L10.0908 8.7168H11.1309Z'
        fill='#306AE1'
      />
    </svg>
  );
};

export const Speed2x = () => {
  return (
    <svg width='35' height='35' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 21C8.61667 21 7.31667 20.7373 6.1 20.212C4.88333 19.6867 3.825 18.9743 2.925 18.075C2.025 17.175 1.31267 16.1167 0.788 14.9C0.263333 13.6833 0.000666667 12.3833 0 11C0 9.61667 0.262667 8.31667 0.788 7.1C1.31333 5.88333 2.02567 4.825 2.925 3.925C3.825 3.025 4.88333 2.31267 6.1 1.788C7.31667 1.26333 8.61667 1.00067 10 1C11.3833 1 12.6833 1.26267 13.9 1.788C15.1167 2.31333 16.175 3.02567 17.075 3.925C17.975 4.825 18.6877 5.88333 19.213 7.1C19.7383 8.31667 20.0007 9.61667 20 11C20 12.3833 19.7373 13.6833 19.212 14.9C18.6867 16.1167 17.9743 17.175 17.075 18.075C16.175 18.975 15.1167 19.6877 13.9 20.213C12.6833 20.7383 11.3833 21.0007 10 21ZM10 19C12.2333 19 14.125 18.225 15.675 16.675C17.225 15.125 18 13.2333 18 11C18 8.76667 17.225 6.875 15.675 5.325C14.125 3.775 12.2333 3 10 3C7.76667 3 5.875 3.775 4.325 5.325C2.775 6.875 2 8.76667 2 11C2 13.2333 2.775 15.125 4.325 16.675C5.875 18.225 7.76667 19 10 19Z'
        fill='#306AE1'
      />
      <path
        d='M9.45605 13.2578V14H4.80273V13.3506L7.13184 10.7578C7.41829 10.4388 7.63965 10.1686 7.7959 9.94727C7.9554 9.72266 8.06608 9.52246 8.12793 9.34668C8.19303 9.16764 8.22559 8.98535 8.22559 8.7998C8.22559 8.56543 8.17676 8.35384 8.0791 8.16504C7.9847 7.97298 7.84473 7.81999 7.65918 7.70605C7.47363 7.59212 7.24902 7.53516 6.98535 7.53516C6.6696 7.53516 6.40592 7.59701 6.19434 7.7207C5.986 7.84115 5.82975 8.01042 5.72559 8.22852C5.62142 8.44661 5.56934 8.69727 5.56934 8.98047H4.66602C4.66602 8.58008 4.75391 8.21387 4.92969 7.88184C5.10547 7.5498 5.36589 7.28613 5.71094 7.09082C6.05599 6.89225 6.48079 6.79297 6.98535 6.79297C7.43457 6.79297 7.81868 6.87272 8.1377 7.03223C8.45671 7.18848 8.70085 7.40983 8.87012 7.69629C9.04264 7.97949 9.12891 8.31152 9.12891 8.69238C9.12891 8.90072 9.0931 9.1123 9.02148 9.32715C8.95312 9.53874 8.8571 9.75033 8.7334 9.96191C8.61296 10.1735 8.47135 10.3818 8.30859 10.5869C8.14909 10.792 7.97819 10.9938 7.7959 11.1924L5.8916 13.2578H9.45605ZM11.1309 8.7168L12.2881 10.6406L13.46 8.7168H14.5195L12.791 11.3242L14.5732 14H13.5283L12.3076 12.0176L11.0869 14H10.0371L11.8145 11.3242L10.0908 8.7168H11.1309Z'
        fill='#306AE1'
      />
    </svg>
  );
};
