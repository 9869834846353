import { Modal } from 'antd';
import React from 'react';

interface CommentInfoMapper {
  title?: string;
  data: any;
  showPreview: boolean;
  setPreview: () => void;
}

export const CommentInfoModal = ({ title, data, showPreview, setPreview }: CommentInfoMapper) => {
  return (
    <Modal
      title={title || 'Navigator Information'}
      visible={showPreview}
      style={{ textAlign: 'center' }}
      onCancel={setPreview}
      footer={null}
    >
      <div>
        {data && data.trim().startsWith('<span') ? (
          <div
            style={{ padding: '2px' }}
            className='feedback-text chat-window-message chat-window-hovered-background'
            dangerouslySetInnerHTML={{ __html: `<div class='chatarea__messages'>${data}</div>` }}
          />
        ) : (
          <div style={{ textAlign: 'left' }}>{data}</div>
        )}
      </div>
    </Modal>
  );
};
