import { FullscreenExitOutlined } from '@ant-design/icons';
import { Icon, Slider, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { IThread } from '../../types/projectTypes';
import { getVideoDurationFormat, mobileCheck, validateYouTubeUrl } from '../../utils';
import useFullScreen from '../playerComponents/fullscreen';
import SeekBar from '../playerComponents/seekbar';
import {
  AudioBars,
  AudioBarsSmall,
  PauseIconPlayer,
  PlayIconPlayer,
} from '../projects/Chat/ChatSvgs';
// import PauseIcon from '../recording/images/play-icon.png';
import {
  BackwardArrow,
  BackwardMediaIcon,
  ForwardArrow,
  ForwardMediaIcon,
  MaximizePlayerIcon,
  PauseIcon,
  PlayIcon,
  VolumeIcon,
  VolumeMuteSVG,
} from '../svg';
// import { set } from 'react-ga';

interface RenderVideoProps {
  videos: [string];
  iframeClass?: string;
  parentComponent: string;
  isMediaCreatedByLoginUser: boolean;
  createdTime: any;
  blobURL: string;
  pauseVideo?: (currentMediaTime: number) => void;
  changeThread?: (selectedThreadData: IThread, isPlaying: boolean) => void;
  selctedThreadMediaTime?: number;
  setPlayingVideo?: any;
  showVisulizer?: boolean;
  isAudio?: boolean;
  smallPlayer?: boolean;
  projectId?: string;
  isTheaterMode?: boolean;
  changeVideoTime?: any;
}
const RenderVideos = ({
  videos,
  iframeClass = '',
  parentComponent,
  isMediaCreatedByLoginUser,
  createdTime,
  blobURL,
  pauseVideo,
  changeThread,
  selctedThreadMediaTime = 0,
  setPlayingVideo,
  showVisulizer = false,
  smallPlayer = false,
  projectId = '',
  isTheaterMode = false,
  changeVideoTime,
}: RenderVideoProps) => {
  const isMobileView = mobileCheck();
  // let hideVideoControlTimeout: any ='';
  let playerRef: any = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef(null);
  // const [isShowedError, setIsShowedError] = useState(false);
  const [isShowingBlobURL, setIsShowingBlobURL] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMute, setIsMute] = useState(false);
  const [playing, setPlayingValue] = useState(false);
  const [bufferPercentage, setBufferPercentage] = useState(0);
  const [isPlayerMouseOver, setIsPlayerMouseOver] = useState(false);
  const [isVidoControlMouseOver, setIsVideoControlMouseOver] = useState(false);
  // const [isVideoControlHide, setIsVideoControlHide] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoPlayedDuration, setVideoPlayedDuration] = useState(0);
  const [playedPercentage, setPlayedPercentage] = useState(0);
  const [isShowIframe, setIsShowIframe] = useState(
    parentComponent === 'feedbackModal' ? false : true,
  );
  const [videoURL, setVideoURL] = useState('');

  const getVideoElement = React.useCallback(() => videoRef && videoRef.current, []);
  const getPlayerElement = React.useCallback(() => playerRef && playerRef.current, []);
  const fullscreenProps = useFullScreen(getPlayerElement, getVideoElement);
  const { fullscreen, requestFullscreen, exitFullscreen } = fullscreenProps;
  const [speed, setSpeed] = useState(1);
  const playedOnce = useRef(true);

  const setPlaying = (value: boolean) => {
    setPlayingVideo && setPlayingVideo(value);
    setPlayingValue && setPlayingValue(value);
    if (showVisulizer) {
      showVisual();
    }
  };
  const hideLoader = () => {
    if (parentComponent === 'feedbackModal') {
      setIsShowIframe(true);
      setLoading(false);
    }
  };

  const getVideoURL = (videoURLString: string) => {
    if (videoURLString.includes('https://embed.api.video/vod')) {
      const videoURLSlashArray = videoURLString.split('/');
      const apiVideoId = videoURLSlashArray[videoURLSlashArray.length - 1];
      return `https://cdn.api.video/vod/${apiVideoId}/hls/manifest.m3u8`;
    }
    return videoURLString;
  };

  const keyboardKeyHandler = (event: any) => {
    const key = event.key;
    switch (key) {
      case 'ArrowLeft':
        if (playerRef) {
          playerRef.seekTo(videoPlayedDuration - 1);
        }
        break;
      case 'ArrowRight':
        if (playerRef) {
          playerRef && playerRef.seekTo(videoPlayedDuration + 1);
        }
        break;
    }
  };

  useEffect(() => {
    if (selctedThreadMediaTime) {
      const seekMediaTime = selctedThreadMediaTime / videoDuration;
      pauseVideo && pauseVideo(selctedThreadMediaTime);
      setVideoPlayedDuration(selctedThreadMediaTime);
      setPlayedPercentage(seekMediaTime * 100);
      playerRef && playerRef.seekTo(seekMediaTime);
    }
  }, [selctedThreadMediaTime]);

  React.useEffect(() => {
    window.addEventListener('keydown', keyboardKeyHandler);
    return () => {
      window.removeEventListener('keydown', keyboardKeyHandler);
    };
  }, []);

  useEffect(() => {
    const currentUTCTime = moment().utc();
    const minutes = moment(currentUTCTime).diff(moment(createdTime), 'minutes');
    const m3u8VideoURL = videos[0];

    if (blobURL) {
      if (minutes <= 2) {
        setVideoURL(blobURL);
        setIsShowingBlobURL(true);
      } else {
        setVideoURL(videos[0]);
      }
    } else if (m3u8VideoURL.includes('zooc-media-files') && minutes <= 2) {
      const videoSplitArray = m3u8VideoURL.split('/');
      if (videoSplitArray && videoSplitArray[3]) {
        const m3u8VIdeoId = videoSplitArray[3];
        const mp4VideoId = m3u8VIdeoId.replace('.m3u8', '.mp4');
        setIsShowingBlobURL(true);
        setVideoURL(`https://zooc-raw-videos.s3.eu-central-1.amazonaws.com/${mp4VideoId}`);
      }
    } else {
      setIsShowingBlobURL(false);
      setVideoURL(getVideoURL(videos[0]));
    }
  }, [videos, isMediaCreatedByLoginUser, createdTime, blobURL]);

  useEffect(() => {
    if (!playing) {
      pauseVideo && pauseVideo(videoPlayedDuration);
    }
  }, [playing, videoPlayedDuration]);

  // useEffect(() => {
  //   if (playing && !isShowedError && !videoDuration) {
  //     notification.error({
  //       description: '',
  //       message: 'This video is being processed, please wait a few seconds.',
  //     });
  //     setIsShowedError(true);
  //   }
  // }, [playing, isShowedError, videoDuration]);

  const playPauseVideo = () => {
    if (!playing) {
      const pathname = window.location.pathname;
      window.history.pushState({}, document.title, `${pathname}`);
    }
    setPlaying(!playing); // tslint:disable-line
  };

  useEffect(() => {
    if (!isPlayerMouseOver && !isVidoControlMouseOver) {
    //  setIsVideoControlHide(true);
    }
  }, [isPlayerMouseOver, isVidoControlMouseOver]);

  const playerMouseEnter = () => {
   // setIsVideoControlHide(false);
    //  clearTimeout(hideVideoControlTimeout)
    setIsPlayerMouseOver(true);
    // setIsVideoControlMouseOver(false);
  };

  const playerMouseOut = () => {
    setIsPlayerMouseOver(false);
    // hideVideoControlTimeout = setTimeout(() => {
    //   setIsPlayerMouseOver(false);
    // }, 3000);
  };

  const videoControlMouseEnter = () => {
   // setIsVideoControlHide(true);
    setIsPlayerMouseOver(false);
    setIsVideoControlMouseOver(true);
  };

  const videoControlMouseOut = () => {
    setIsVideoControlMouseOver(false);
  };

  const volumeChange = (volumeValue: any) => {
    if (!volumeValue) {
      setIsMute(true);
    } else {
      isMute && setIsMute(false);
    }
    setVolume(volumeValue / 100);
  };
  const seekChange = (seekChangeValue: any) => {
    if (videoDuration) {
      setPlayedPercentage(seekChangeValue);
      playerRef && playerRef.seekTo(seekChangeValue / 100);
      pauseVideo && pauseVideo((videoDuration * seekChangeValue) / 100);
      setVideoPlayedDuration((videoDuration * seekChangeValue) / 100);
    }
  };

  const handleProgress = (progress: { playedSeconds: number; played: number; loaded: number }) => {
    const { playedSeconds, played, loaded } = progress;
    setVideoPlayedDuration(playedSeconds);
    setPlayedPercentage(played * 100);
    loaded && setBufferPercentage(loaded * 100);
    if (played === 1) {
      setPlaying(false);
    }
    pauseVideo && pauseVideo(playedSeconds);
    // We only want to update time slider if we are not currently seeking
  };
  const handleDuration = (duration: number) => {
    setVideoDuration(duration);
  };

  const backwardVideo = (backwordStep: number) => {
    const backwardVideoDuration = videoPlayedDuration - backwordStep;
    if (backwardVideoDuration > 0) {
      playerRef && playerRef.seekTo(backwardVideoDuration);
    } else {
      playerRef && playerRef.seekTo(0);
    }
  };

  const forwordVideo = (forwordStep: number) => {
    const backwardVideoDuration = videoPlayedDuration + forwordStep;
    if (backwardVideoDuration < videoDuration) {
      playerRef && playerRef.seekTo(backwardVideoDuration - 1);
    }
  };

  const VideoControl = () => {
    return (
      <div
        onMouseEnter={videoControlMouseEnter}
        onMouseOut={videoControlMouseOut}
        //  style={{ pointerEvents: 'none', opacity: isPlayerMouseOver ? 1 : 0 }}
        className={
          parentComponent === 'feedbackModal'
            ? 'video-control video-control-chat-window'
            : 'video-control video-canvas-detail'
        }
      >
        <div className='video-control-panel'>
          <div className='valume-control-icons'>
            {isMute ? (
              <span
                className='pointer media-volume-mute-icon'
                onClick={() => {
                  setIsMute(false);
                }}
              >
                <VolumeMuteSVG />
              </span>
            ) : (
              <span
                className='pointer media-volume-icon'
                onClick={() => {
                  setIsMute(true);
                }}
              >
                <VolumeIcon />
              </span>
            )}
            {parentComponent !== 'feedbackModal' && !isMobileView && (
              <Slider
                className='volume-slider'
                tipFormatter={null}
                max={100}
                min={0}
                defaultValue={isMute ? 0 : volume * 100}
                //  value={isMute ? 0 : volume * 100}
                //  onChange={volumeChange}
                onAfterChange={volumeChange}
              />
            )}
            {isShowingBlobURL && (
              <span className='blob-video-duration'>
                {getVideoDurationFormat(videoPlayedDuration)}
              </span>
            )}
          </div>

          <div className='backword-forward'>
            {parentComponent !== 'feedbackModal' && !isShowingBlobURL && (
              <>
                <div className='fast-backward-container'>
                  <Tooltip placement='top' title='Backward 1 Sec'>
                    <div
                      onClick={() => backwardVideo(1)}
                      className={'video-control-icon pointer fast-backward-content'}
                    >
                      <BackwardArrow /> <span className='fast-backward'>1s</span>
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip placement='top' title='Backward'>
                    <div onClick={() => backwardVideo(10)} className={'video-control-icon pointer'}>
                      <BackwardMediaIcon />
                    </div>
                  </Tooltip>
                </div>
              </>
            )}
            <div className='pause-video-icon'>
              {!playing ? (
                <Tooltip placement='top' title='Pause'>
                  <span className='pointer video-play-control-icon' onClick={playPauseVideo}>
                    <PauseIcon />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip placement='top' title='Play'>
                  <span className='pointer video-play-control-icon' onClick={playPauseVideo}>
                    {/* <img height='30px' src={PauseIcon} alt='play' /> */}
                    <PlayIcon />
                  </span>
                </Tooltip>
              )}
            </div>
            {parentComponent !== 'feedbackModal' && !isShowingBlobURL && (
              <>
                <div>
                  <Tooltip placement='top' title='Forward'>
                    <div onClick={() => forwordVideo(31)} className={'video-control-icon pointer'}>
                      <ForwardMediaIcon />
                    </div>
                  </Tooltip>
                </div>
                <div className='fast-forward-container'>
                  <Tooltip placement='top' title='Forward 1 Sec'>
                    <div
                      onClick={() => forwordVideo(2)}
                      className={'video-control-icon pointer fast-forward-content'}
                    >
                      <span className='fast-forward'>1s</span>
                      <ForwardArrow />
                    </div>
                  </Tooltip>
                </div>
              </>
            )}
          </div>

          <div className='setting-secreen'>
            <div style={{ display: 'flex' }}>
              <span
                onClick={() =>
                  setSpeed((currentSpeed: number) => (currentSpeed < 3 ? currentSpeed + 0.5 : 1))
                }
                className={`speed-controls-for-big-player cursor-pointer noselect video-control-icon ${
                  playing ? 'd-flex' : 'd-flex'
                }`}
              >
                {speed + 'x'}
              </span>

              {!fullscreen && (
                <Tooltip placement='top' title='Full Screen'>
                  {/* <FullscreenOutlined
                    onClick={requestFullscreen}
                    className={'video-control-icon pointer'}
                  /> */}
                  <div onClick={requestFullscreen} className={'video-control-icon pointer'}>
                    <MaximizePlayerIcon />
                  </div>
                </Tooltip>
              )}

              {fullscreen && (
                <Tooltip placement='top' title='Full Screen'>
                  <FullscreenExitOutlined
                    onClick={exitFullscreen}
                    className={'video-control-icon pointer'}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        {!isShowingBlobURL && (
          <div className='seek-container'>
            <span className='video-duration'>{getVideoDurationFormat(videoPlayedDuration)}</span>
            <div className='seekbar-wrapper'>
              <SeekBar
                bgcolor={'#306AE1'}
                playedPercentage={playedPercentage}
                bufferPercentage={bufferPercentage}
                seekChange={seekChange}
                id={videos[0]}
                duration={videoDuration}
                playing={playing}
                changeThread={changeThread}
                isTheaterMode={isTheaterMode}
                changeVideoTime={changeVideoTime}
              />
            </div>
            <span className='video-duration'>
              {videoDuration && videoDuration !== Infinity
                ? getVideoDurationFormat(videoDuration)
                : ''}
            </span>
          </div>
        )}
      </div>
    );
  };

  const showVisual = () => {
    if (playedOnce.current) {
      playedOnce.current = false;
    } else {
      return;
    }
    let audio: any = document.getElementById('audio' + 'videoURL');
    // var audio: any = audioRef
    audio = audio.firstChild;
    // audio.crossOrigin = 'anonymous';

    audio.play();
    const context = new AudioContext();
    const src = context.createMediaElementSource(audio);
    const analyser = context.createAnalyser();

    const canvas: any = document.getElementById('canvas' + 'videoURL');
    // canvas.width = window.innerWidth - 320;
    // @ts-ignore
    canvas.width = document.getElementById('videoContainerForVisulizer').clientWidth;
    // canvas.height = window.innerHeight - 123;
    // @ts-ignore
    canvas.height = document.getElementById('videoContainerForVisulizer').clientHeight;
    const ctx = canvas.getContext('2d');

    src.connect(analyser);
    analyser.connect(context.destination);

    analyser.fftSize = 256;

    const bufferLength = analyser.frequencyBinCount;

    const dataArray = new Uint8Array(bufferLength);

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const barWidth = (WIDTH / bufferLength) * 2.5;
    let barHeight;
    let x = 0;

    const renderFrame = () => {
      requestAnimationFrame(renderFrame);

      x = 0;

      analyser.getByteFrequencyData(dataArray);

      ctx.fillStyle = '#ECF5FF';
      ctx.fillRect(0, 0, WIDTH, HEIGHT);

      function drawBorder(xPos: any, yPos: any, width: any, height: any, thickness = 1.5) {
        ctx.fillStyle = '#306AE1';
        ctx.fillRect(
          xPos - thickness,
          yPos - thickness,
          width + thickness * 2,
          height + thickness * 2,
        );
      }

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i];
        drawBorder(x, HEIGHT - barHeight, barWidth, barHeight);
        ctx.fillStyle = '#ECF5FF';
        ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
        x += barWidth + 1;
      }
    };

    audio.play();

    audio.onended = () => {
      setPlaying(false);
    };
    renderFrame();
  };

  return (
    <>
      <div ref={videoRef}>
        <div
          ref={videoRef}
          id={'videoContainerForVisulizer'}
          className={`${showVisulizer ? 'audio-container' : ''} video-container ${
            parentComponent === 'feedbackModal'
              ? iframeClass
              : smallPlayer && !fullscreen
              ? 'height-player-full'
              : ` ${fullscreen || isTheaterMode ? 'maximize-window' : 'minimize-window'}`
          }`}
          style={
            parentComponent === 'projectDetails'
              ? isMobileView
                ? { height: 'calc(100vw)', overflow: 'hidden' }
                : {}
              : { height: '88px', width: '180px', marginLeft: '10px' }
          }
          onMouseEnter={playerMouseEnter}
          onMouseOut={playerMouseOut}
        >
          {isShowingBlobURL && parentComponent !== 'feedbackModal' && (
            <div className='project-blob-processing'>This video is being processed</div>
          )}
          {showVisulizer && <canvas id={'canvas' + 'videoURL'}></canvas>}
          {!playing && showVisulizer && (
            <div style={{ position: 'absolute', bottom: '-4px', left: 0 }}>
              {smallPlayer ? <AudioBarsSmall /> : <AudioBars />}
            </div>
          )}
          <ReactPlayer
            playbackRate={speed}
            config={{
              file: {
                forceAudio: showVisulizer,
                attributes: {
                  crossOrigin: 'anonymous',
                },
              },
            }}
            progressInterval={1000}
            mute={isMute}
            onClick={() => {
              setPlayingValue((playingTemp: boolean) => {
                setPlayingVideo && setPlayingVideo(!playingTemp);
                setPlayingValue && setPlayingValue(!playingTemp);
                if (showVisulizer) {
                  showVisual();
                }
                if (!playingTemp) {
                  const pathname = window.location.pathname;
                  window.history.pushState({}, document.title, `${pathname}`);
                }
                return !playing;
              });
            }}
            playing={playing}
            volume={isMute ? 0 : volume}
            className={`${smallPlayer ? 'player-height' : ''}  ${
              parentComponent === 'feedbackModal'
                ? 'chat-window-react-player'
                : // : smallPlayer && !fullscreen ? 'height-player-full'
                !fullscreen
                ? smallPlayer
                  ? ''
                  : `canvas-detail-react-player ${fullscreen}`
                : smallPlayer
                ? ''
                : `canvas-detail-maximize-react-player`
            }`}
            url={videoURL} // 'https://cdn.api.video/vod/vi1pz7g8CPh9YUYTUn7iTdbF/hls/manifest.m3u8'
            onProgress={handleProgress}
            onDuration={handleDuration}
            ref={(player) => {
              playerRef = player;
            }}
            onPlay={() => {
              setPlaying(true);
            }}
            onPause={() => {
              setPlaying(false);
            }}
            id={'audio' + 'videoURL'}
            onStart={() => {
              setPlaying(true);
            }}
          />
          {parentComponent !== 'feedbackModal' && (
            <div className='video-play' onClick={playPauseVideo}>
              {playing ? (
                smallPlayer ? (
                  <PauseIconPlayer />
                ) : null
              ) : (
                <>{!validateYouTubeUrl(videoURL) && <PlayIconPlayer />}</>
              )}
            </div>
          )}
          {parentComponent === 'feedbackModal' && <VideoControl />}
        </div>
        {smallPlayer && (
          <Tooltip placement={'left'} title={`${'Open'}`}>
            <div
              style={{
                position: 'absolute',
                backgroundColor: 'rgb(77 70 70 / 12%)',
                right: 10,
                padding: '2px',
                zIndex: 11,
                top: 10,
                fontSize: 20,
                borderRadius: '3px',
              }}
            >
              <Icon
                onClick={() => {
                  window.open(
                    `${window.location.origin}/${showVisulizer ? 'audio' : 'c'}/${projectId}`,
                    '_blank',
                  );
                }}
                type='link'
                style={{ color: 'white' }}
              />
            </div>
          </Tooltip>
        )}

        {smallPlayer && !showVisulizer && !fullscreen && (
          <Tooltip placement='left' title='Full Screen'>
            <div
              style={{
                position: 'absolute',
                backgroundColor: 'rgba(255,255,255,0.1)',
                right: 10,
                padding: '2px',
                zIndex: 11,
                bottom: 10,
                fontSize: 20,
                borderRadius: '3px',
              }}
            >
              <div onClick={requestFullscreen} className={'video-control-icon pointer'}>
                <MaximizePlayerIcon color={'white'} />
              </div>
            </div>
          </Tooltip>
        )}
        {parentComponent !== 'feedbackModal' && (!smallPlayer || fullscreen) && <VideoControl />}
      </div>
      {false && (
        <>
          {loading && parentComponent === 'feedbackModal' ? <div>Loading...</div> : null}
          <iframe
            style={{ display: isShowIframe ? 'block' : 'none', border: '0px' }}
            className={iframeClass}
            frame-border='0'
            margin-height='0'
            margin-width='0'
            onLoad={hideLoader}
            src={videos[0]}
            width='100%'
            height='100%'
            scrolling='no'
            allowFullScreen
          ></iframe>
        </>
      )}
    </>
  );
};

export default RenderVideos;
