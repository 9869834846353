import { Avatar, Card, Drawer, Icon, Menu } from 'antd';
// import firebase from 'firebase';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { signOut as signOutAction } from '../../store/actions/authActions';
import { IAuthAction } from '../../types/authTypes';
import { trackAnalytics } from '../../utils';
import JiraConnect from '../jira-integration';
import ProjectForm from '../projects/ProjectForm';
import SubscriptionPlan from '../subscription-plan';
import TeamList from '../team';
import TrelloConnect from '../trello-integration';
// import CreateProject from '../projects/CreateProject';
const { SubMenu } = Menu;
const { Meta } = Card;

const Settings = ({
  signOut,
  profile,
  auth,
  history,
  handleSettingPopoverVisibleChange,
}: {
  signOut: () => void;
  profile: {
    fullName: string;
    role: string;
    photoURL: string;
    jiraConnect: boolean | undefined;
    trelloConnect: boolean | undefined;
    slackConnect: boolean | undefined;
    isDetailOnboardingVisited: boolean | undefined;
    isCreateOnboardingVisited: boolean | undefined;
  };
  auth: { email: string; uid: string };
  iframe: any;
  history: any;
  handleSettingPopoverVisibleChange: (value: boolean) => void;
}) => {
  const jiraConnect = (profile && profile.jiraConnect) || false;
  const trelloConnect = (profile && profile.trelloConnect) || false;
  const slackConnect = (profile && profile.slackConnect) || false;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [jiraIntegrationDrawerVisible, setJiraIntegrationDrawerVisible] = useState(false);
  const [trelloIntegrationDrawerVisible, setTrelloIntegrationDrawerVisible] = useState(false);
  const [slackIntegrationDrawerVisible, setSlackIntegrationDrawerVisible] = useState(false);
  const [subscriptionPlanDrawerVisible, setSubscriptionPlanDrawerVisible] = useState(false);
  const [dialogState, setDialogState] = useState(false);
  // const isDetailOnboardingVisited = (profile && profile.isDetailOnboardingVisited) || false;
  // const isCreateOnboardingVisited = (profile && profile.isCreateOnboardingVisited) || false;
  const REACT_APP_IS_BETA =
    process &&
    process.env &&
    process.env.REACT_APP_IS_BETA &&
    process.env.REACT_APP_IS_BETA.trim() === 'true'
      ? true
      : false;
  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  const logout = () => {
    trackAnalytics('Settings', 'Signout clicked');
    signOut();
  };
  const installChromeExtenstion = () => {
    trackAnalytics('Settings', 'Install chrome extention clicked');
    window.open(
      'https://chrome.google.com/webstore/detail/feedbackhero-screen-captu/fnfdbalaemoeodickdflahhnoibdgief',
      '_blank',
    );
  };
  const stats = () => {
    history.push('/stats');
  };
  const teamMember = () => {
    handleSettingPopoverVisibleChange(false);
    setDrawerVisible(true);
  };
  const openJiraIntegrationDrawer = () => {
    setJiraIntegrationDrawerVisible(true);
    handleSettingPopoverVisibleChange(false);
    trackAnalytics('Settings', 'Jira integration clicked');
  };
  const onJiraIntegrationDrawerClose = () => {
    setJiraIntegrationDrawerVisible(false);
  };
  const openTrelloIntegrationDrawer = () => {
    setTrelloIntegrationDrawerVisible(true);
    handleSettingPopoverVisibleChange(false);
    trackAnalytics('Settings', 'Trello integration clicked');
  };
  const onTrelloIntegrationDrawerClose = () => {
    setTrelloIntegrationDrawerVisible(false);
  };
  const openSlackIntegrationDrawer = () => {
    setSlackIntegrationDrawerVisible(true);
    handleSettingPopoverVisibleChange(false);
    trackAnalytics('Settings', 'Slack integration clicked');
  };
  const onSlackIntegrationDrawerClose = () => {
    setSlackIntegrationDrawerVisible(false);
  };

  const openSubscriptionPlanDrawer = () => {
    setSubscriptionPlanDrawerVisible(true);
    handleSettingPopoverVisibleChange(false);
  };

  const subscriptionPlanDrawerClose = () => {
    setSubscriptionPlanDrawerVisible(false);
  };

  // const updateUserOnboarding = (updateData: any) => {
  //   const { uid } = auth;
  //   if (uid) {
  //     const firestore = firebase.firestore();
  //     firestore
  //       .collection('users')
  //       .doc(uid)
  //       .set(updateData, { merge: true });
  //   }
  // };

  // const onBoardingOff = () => {
  //   updateUserOnboarding({
  //     isDetailOnboardingVisited: true,
  //     isCreateOnboardingVisited: true,
  //   });
  // };

  // const onBoardingOn = () => {
  //   trackAnalytics('Settings', 'onBoarding clicked');
  //   handleSettingPopoverVisibleChange(false);
  //   updateUserOnboarding({
  //     isDetailOnboardingVisited: false,
  //     isCreateOnboardingVisited: false,
  //   });
  // };
  return (
    <div id={'nameCard'}>
      <Card
        style={{ width: '100%', marginBottom: '40px' }}
        cover={
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              height: '48px',
            }}
          >
            <Avatar
              style={{ backgroundColor: '#039BE5', verticalAlign: 'middle' }}
              src={profile.photoURL && profile.photoURL}
              size='large'
            >
              {profile.fullName && profile.fullName.charAt(0).toUpperCase()}
            </Avatar>
          </div>
        }
      >
        <Meta
          title={<div className='show-centere'>{profile.fullName}</div>}
          description={<div className='show-centere'>{auth.email}</div>}
        />
      </Card>
      <Menu
        onClick={() => null}
        defaultSelectedKeys={[]}
        selectedKeys={[]}
        defaultOpenKeys={['sub1']}
        mode='inline'
      >
        {/* {history.location.pathname === '/' && <CreateProject isWriteAccess={isWriteAccess} />} */}

        {history.location.pathname !== '/' && (
          <Menu.Item key='Dashboard' onClick={() => history.push('/')}>
            <Icon type='mail' />
            Dashboard
          </Menu.Item>
        )}
        {profile && profile.role && profile.role === 'ADMIN' && (
          <Menu.Item key='Stats' onClick={stats}>
            <Icon type='line-charts' />
            Stats
          </Menu.Item>
        )}
        <Menu.Item key='Chrome' onClick={installChromeExtenstion}>
          <Icon type='camera' />
          Chrome Extension
        </Menu.Item>
        <SubMenu
          key='Integration'
          title={
            <span>
              <Icon type='api' />
              <span>Integration</span>
            </span>
          }
        >
          <Menu.Item key='Jira' onClick={openJiraIntegrationDrawer}>
            <Icon type={`${jiraConnect ? 'disconnect' : 'api'}`} />
            {jiraConnect ? 'Jira' : 'Jira'}
          </Menu.Item>

          <Menu.Item key='Trello' onClick={openTrelloIntegrationDrawer}>
            <Icon type={`${trelloConnect ? 'disconnect' : 'api'}`} />
            {trelloConnect ? 'Trello' : 'Trello'}
          </Menu.Item>

          <Menu.Item key='Slack' onClick={openSlackIntegrationDrawer}>
            <Icon type={`${slackConnect ? 'disconnect' : 'api'}`} />
            {slackConnect ? 'Slack' : 'Slack'}
          </Menu.Item>
        </SubMenu>

        <Menu.Item key='Members' onClick={teamMember}>
          <Icon type='team' />
          Team Members
        </Menu.Item>

        {/* {(!isDetailOnboardingVisited || !isCreateOnboardingVisited) && (
            <Button type='default' icon='disconnect' size='large' onClick={onBoardingOff}>
              Onboarding Off
            </Button>
          )} */}

        {/* {isDetailOnboardingVisited && isCreateOnboardingVisited && (
          <Menu.Item key='Onboarding' onClick={onBoardingOn}>
            <Icon type='api' />
            Onboarding On
          </Menu.Item>
        )} */}

        <Menu.Item key='Subscription' onClick={openSubscriptionPlanDrawer}>
          <Icon type='dollar' />
          Subscription Plan
        </Menu.Item>

        {/* <Menu.Item
          key='Live'
          onClick={() => {
            handleSettingPopoverVisibleChange(false);
            // const crispElement: any = document.querySelector('.crisp-client');
            // if (crispElement && crispElement.style) {
            //   crispElement.style.display = 'block';
            // }
            // @ts-ignore
            window.$crisp.push(['do', 'chat:open']);
          }}
        >
          <Icon type='wechat' />
          Live help
        </Menu.Item> */}

        {!REACT_APP_IS_BETA && (
          <Menu.Item
            key='betaFeatures'
            onClick={() => window.open('https://beta.app.zooc.io', '_blank')}
          >
            <Icon type='radar-chart' />
            Open beta features
          </Menu.Item>
        )}

        <Menu.Item key='Logout' onClick={logout}>
          <Icon type='logout' />
          Logout
        </Menu.Item>
      </Menu>
      <ProjectForm
        isOpenSteps={true}
        memberEMailEditable={true}
        visible={dialogState}
        setVisible={setDialogState}
        title='Create New Contact'
      />
      <Drawer
        title='Jira Integration'
        placement='right'
        closable={false}
        onClose={onJiraIntegrationDrawerClose}
        visible={jiraIntegrationDrawerVisible}
        className={'sidebar-team-member-drawer'}
      >
        <JiraConnect />
      </Drawer>

      <Drawer
        title='Trello Integration'
        placement='right'
        closable={false}
        onClose={onTrelloIntegrationDrawerClose}
        visible={trelloIntegrationDrawerVisible}
        className={'sidebar-team-member-drawer'}
      >
        <TrelloConnect />
      </Drawer>

      <Drawer
        title='Slack Integration'
        placement='right'
        closable={false}
        onClose={onSlackIntegrationDrawerClose}
        visible={slackIntegrationDrawerVisible}
        className={'sidebar-team-member-drawer'}
      ></Drawer>

      <Drawer
        title='Team Members'
        placement='right'
        closable={false}
        onClose={onDrawerClose}
        visible={drawerVisible}
        className={'sidebar-team-member-drawer'}
      >
        <TeamList />
      </Drawer>

      <Drawer
        title='Subscription'
        placement='right'
        closable={false}
        onClose={subscriptionPlanDrawerClose}
        visible={subscriptionPlanDrawerVisible}
        className={'sidebar-team-member-drawer'}
      >
        <SubscriptionPlan />
      </Drawer>

      <div className='settings-form'>
        <div className='settings-form-buttons'>
          {/* {history.location.pathname !== '/' && (
            <Button type='default' icon='home' size='large' onClick={() => history.push('/')}>
              Dashboard
            </Button>
          )}
          {profile && profile.role && profile.role === 'ADMIN' && (
            <Button type='default' icon='line-chart' size='large' onClick={stats}>
              Stats
            </Button>
          )}
          <Button type='default' icon='camera' size='large' onClick={installChromeExtenstion}>
            Chrome Extension
          </Button>

          <Button
            type='default'
            icon={`${jiraConnect ? 'disconnect' : 'api'}`}
            size='large'
            onClick={openJiraIntegrationDrawer}
          >
            {jiraConnect ? 'Disconnect Jira' : 'Connect Jira'}
          </Button>

          <Button
            type='default'
            icon={`${trelloConnect ? 'disconnect' : 'api'}`}
            size='large'
            onClick={openTrelloIntegrationDrawer}
          >
            {trelloConnect ? 'Disconnect Trello' : 'Connect Trello'}
          </Button>

          <Button
            type='default'
            icon={`${slackConnect ? 'disconnect' : 'api'}`}
            size='large'
            onClick={openSlackIntegrationDrawer}
          >
            {slackConnect ? 'Disconnect Slack' : 'Connect Slack'}
          </Button>

          <Button type='default' icon='team' size='large' onClick={teamMember}>
            Team Members
          </Button>
          {isDetailOnboardingVisited && isCreateOnboardingVisited && (
            <Button type='default' icon='api' size='large' onClick={onBoardingOn}>
              Onboarding On
            </Button>
          )}
          <Button type='default' icon='api' size='large' onClick={openSubscriptionPlanDrawer}>
            Subscription Plan
          </Button>
          <Button
            type='default'
            icon='wechat'
            size='large'
            onClick={() => {
              handleSettingPopoverVisibleChange(false);
              const windows: any = window;
              windows.crisp = [];
              windows.CRISP_WEBSITE_ID = 'cedf8634-e7bd-45f6-a876-340432ffc778';
              const d: any = document;
              const s = d.createElement('script');
              s.src = 'https://client.crisp.chat/l.js';
              s.async = 1;
              d.getElementsByTagName('head')[0].appendChild(s);
            }}
          >
            Chat
          </Button>
          <Button type='default' icon='logout' size='large' onClick={logout}>
            Logout
          </Button> */}
          <p style={{ marginTop: '20px', color: 'grey', textAlign: 'right' }}>v1.0.6</p>
          {/* {!REACT_APP_IS_BETA && (
            <div className='beta-features'>
              <a target='_blank' href='https://beta.app.zooc.io'>
                Open beta features
              </a>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  firebase: {
    auth: { email: string };
    profile: {
      fullName: string;
      role: string;
      trelloConnect: boolean | undefined;
      JiraConnect: boolean | undefined;
      slackConnect: boolean | undefined;
      isDetailOnboardingVisited: boolean | undefined;
      isCreateOnboardingVisited: boolean | undefined;
    };
  };
  project: {
    iframe: any;
  };
}) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    iframe: state.project.iframe,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, IAuthAction>) => {
  return {
    signOut: () => dispatch(signOutAction()),
  };
};

export const SettingsContent = compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Settings);
