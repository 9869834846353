import React from 'react';

const SendCall = () => {
    return (
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.800781" width="28" height="28" rx="14" fill="white"/>
        <path d="M21.1348 11.666V7.66602H17.1348" stroke="#001DAD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.4668 12.3327L21.1335 7.66602" stroke="#001DAD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.4677 18.2807V20.2807C20.4685 20.4664 20.4305 20.6502 20.3561 20.8203C20.2817 20.9904 20.1726 21.1431 20.0358 21.2686C19.899 21.3941 19.7374 21.4897 19.5616 21.5492C19.3857 21.6087 19.1993 21.6308 19.0144 21.614C16.963 21.3911 14.9924 20.6901 13.2611 19.5674C11.6503 18.5438 10.2846 17.1782 9.26106 15.5674C8.13438 13.8282 7.43322 11.848 7.2144 9.78738C7.19774 9.60303 7.21965 9.41722 7.27873 9.2418C7.33781 9.06637 7.43278 8.90518 7.55757 8.76846C7.68237 8.63175 7.83427 8.52252 8.00359 8.44773C8.17291 8.37294 8.35596 8.33422 8.54106 8.33405H10.5411C10.8646 8.33086 11.1783 8.44543 11.4236 8.6564C11.6689 8.86737 11.8291 9.16035 11.8744 9.48072C11.9588 10.1208 12.1154 10.7492 12.3411 11.354C12.4308 11.5927 12.4502 11.852 12.397 12.1013C12.3438 12.3506 12.2203 12.5795 12.0411 12.7607L11.1944 13.6074C12.1434 15.2764 13.5254 16.6583 15.1944 17.6074L16.0411 16.7607C16.2223 16.5815 16.4512 16.4579 16.7005 16.4048C16.9498 16.3516 17.2091 16.371 17.4477 16.4607C18.0526 16.6864 18.681 16.843 19.3211 16.9274C19.6449 16.9731 19.9407 17.1362 20.1521 17.3857C20.3635 17.6352 20.4758 17.9538 20.4677 18.2807Z" stroke="#001DAD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
export default SendCall;