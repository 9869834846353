import styled from '@emotion/styled';
import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { hexToRGBA } from 'utils';
import Spinner from '../components/shared/spinner';
import { IMasterProject, IProject } from '../types/projectTypes';
import { grid } from './constants';
import CanvasList from './primatives/CanvasList';
import SendCall from 'components/svg/SendCall';
import CallModal from 'components/shared/CallModal';
import CallInitiatedModal from 'components/shared/CallInitiatedModal';
import { notification } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';

const { REACT_APP_FIREBASE_CLOUD_FUNCTION_URL } = process.env;

const Container = styled.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`;

interface IOwnProps {
  id: string;
  index: number;
  workflowIndex: number;
  color: string;
  canvases: IProject[];
  step: string;
  selectedMasterProject: IMasterProject;
  history: any;
  isScrollable: boolean;
  isCombineEnabled: boolean;
  setActiveColumnId: any;
  activeColumnId: any;
  loader: any;
  batchCall: boolean;
  promptDescription: string;
  selectedOrganizationData: any;
  auth: any;
}

interface IState {
  callModal: boolean;
  callInitiatedModal: boolean;
}

class Column extends Component<IOwnProps, IState> {
  constructor(props: IOwnProps) {
    super(props);
    this.state = {
      callModal: false,
      callInitiatedModal: false,
    };
  }
  render() {
    const {
      id,
      workflowIndex,
      color,
      canvases,
      step,
      selectedMasterProject,
      history,
      isScrollable,
      isCombineEnabled,
      setActiveColumnId,
      activeColumnId,
      loader,
      batchCall,
      promptDescription,
      selectedOrganizationData
    } = this.props;

    const handleBatchCall = () => {
      console.log("userquota:", selectedOrganizationData);
      if (selectedOrganizationData && selectedOrganizationData.userQuota && selectedOrganizationData.userQuota.inboundCallLimit && (selectedOrganizationData.userQuota.inboundCallLimit > 0 || selectedOrganizationData.userQuota.inboundCallLimit === -1)) {
        this.setState((prev) => {
          return {
            ...prev,
            callModal: !this.state.callModal,
          }
        })
      } else {
        console.log("Inbound call quota limit has been reached.");
        notification.error({
          message: 'Inbound call quota limit has been reached, please upgrade your plan.',
        });
      }
    }

    const handleSubmit = () => {
      notification.success({
        message: 'Bulk Call Initiated Successfully',
      });
      fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/batchCall`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          // Authorization: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify({
          selectedMasterProjectId: selectedMasterProject.id,
          selectedWorkflowId: activeColumnId,
          authUserId: this.props.auth.uid
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("data", data)
        })
        .catch(() => {
          return { error: true, message: 'Something went wrong.' };
        });
      handleCancel(true);
    };

    const handleCancel = (_initiate?: boolean) => {
      this.setState((prev) => {
        return {
          ...prev,
          callModal: !this.state.callModal,
          // callInitiatedModal: initiate ? initiate : false,
        };
      });
    };
    const handleCancelCallInitiated = () => {
      this.setState((prev) => {
        return {
          ...prev,
          callInitiatedModal: !this.state.callInitiatedModal,
        };
      });
    };

    return (
      <div>
        <Draggable draggableId={id} key={id} index={workflowIndex}>
          {(provided, snapshot) => (
            <Container
              className={`${activeColumnId === id && 'selected-column'}`}
              onClick={() => {
                setActiveColumnId(id);
              }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              key={id}
            >
              <div
                className='kanbanColumnHeader'
                style={{
                  userSelect: 'none',

                  background: snapshot.isDragging ? hexToRGBA(color, 40) : hexToRGBA(color, 50),
                }}
              >
                <div className='kanbanColumnText' {...provided.dragHandleProps}>
                  {step} {canvases.length ? `(${canvases.length})` : ''}
                </div>
                <div className='kanbanColumnControls'>
                  {batchCall && (
                    <span className="cursor" onClick={handleBatchCall}>
                      {<SendCall/>}
                    </span>
                  )}
                </div>
              </div>

              {activeColumnId === id && loader ? (
                <div style={{ minWidth: '250px', height: '77vh' }}>
                  <Spinner />
                </div>
              ) : (
                <CanvasList
                  history={history}
                  selectedMasterProject={selectedMasterProject}
                  workflowIndex={workflowIndex}
                  listType='canvas'
                  canvases={canvases}
                  color={color}
                  internalScroll={isScrollable}
                  isCombineEnabled={Boolean(isCombineEnabled)}
                  isDropDisabled={false}
                  ignoreContainerClipping={false}
                />
              )}
            </Container>
          )}
        </Draggable>
        <CallModal
          visible={this.state.callModal}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          step={step}
          promptDescription={promptDescription}
        />
        <CallInitiatedModal
          visible={this.state.callInitiatedModal}
          handleCancel={handleCancelCallInitiated}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = () => {
  return {
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Column);