import { Alert, Card, Col, 
  Divider, 
  Form, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import Button from '../../primitives/SignUpButtons';
import {
  clearAuthState as clearAuthStateAction,
  getGitHubLogingResult,
  getGoogleLogingResult,
  loginWithGitHub,
  loginWithGoogle,
  signIn as signInAction,
} from '../../store/actions/authActions';
import { IAuthAction, Type } from '../../types/authTypes';
import { ButtonEvent } from '../../types/syntheticEventsTypes';
import { trackAnalytics } from '../../utils';
import { mobileCheck, showFullFeature } from '../../utils';
import { randomImage } from '../../utils/dummyData';
import { ArrowRight
   , GitHub, Google 
} from '.././svg';
import Spinner from '../shared/spinner';
import { Logo } from '../svg/Logo';

import './auth.css';

const isMobile = mobileCheck();
const urlParams = new URLSearchParams(window.location.search);
const channelId = urlParams.get('channelId') || '';
const slackUserId = urlParams.get('slackUserId') || '';
const recordingType = urlParams.get('type') || 'audio';

ReactGA.initialize('UA-155085731-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const FormItem = Form.Item;
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      offset: 4,
      span: 16,
    },
    xs: {
      offset: 0,
      span: 24,
    },
  },
};

interface ICreds {
  email: string;
  password: string;
}

interface IProps extends FormComponentProps {
  children?: JSX.Element | JSX.Element[];
  signIn: (o: ICreds) => void;
  clearAuthState: () => void;
  googleLogin: () => void;
  gitHubLogin: () => void;
  googleLogInSuccess: () => void;
  gitHubLoginSucces: () => void;
  authError: string;
  status: string;
  image: string;
  auth: {
    isLoaded: boolean;
    uid: string;
  };
  history: {
    location: any;
    push(url: string): void;
  };
}

const SignIn: React.SFC<IProps> = ({
  form,
  status,
  signIn,
  authError,
  auth,
  clearAuthState,
  history,
  googleLogin,
  gitHubLogin,
  googleLogInSuccess,
  gitHubLoginSucces,
}): JSX.Element => {
  const isShowFullFeature = showFullFeature();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState<any>('');

  useEffect(() => {
    // window.localStorage.removeItem('userId');
    // window.localStorage.removeItem('selectedMasterProjectId');
    // window.localStorage.removeItem('selectedWorkflowId');
    // window.localStorage.removeItem('selectedProjectId');
    // window.localStorage.removeItem('firebaseToken');
    // window.localStorage.removeItem('selectedOrganizationId');
  },[])

  useEffect(() => {
    const urlParamsData = new URLSearchParams(window.location.search);
    const isChromeExtension = urlParamsData.get('extension') || '';
    if (isChromeExtension) {
      localStorage.setItem('extension', 'true');
    }
    status === Type.StatusRunning ? setLoading(true) : setLoading(false);
    if (
      status === Type.SignInError ||
      status === Type.githubLogInError ||
      status === Type.GoogleLogInError
    ) {
      setErrorMessage(authError);
      trackAnalytics('User', 'login failed');
      return () => clearAuthState();
    }
    setImage(randomImage);

    if (localStorage.getItem('socialMediaLoginRedirected') === 'true') {
      googleLogInSuccess();
      gitHubLoginSucces();
      localStorage.setItem('socialMediaLoginRedirected', 'false');
    }
  }, [authError, status, clearAuthState, googleLogInSuccess, gitHubLoginSucces]);

  const handleSubmit = (e: ButtonEvent) => {
    e.preventDefault();
    form.validateFields((err: any, values: ICreds) => {
      if (!err) {
        trackAnalytics('User', 'Logged in');
        signIn(values);
      }
    });
  };

  const googleHandleSignIn = (event: any) => {
    event.preventDefault();
    trackAnalytics('User', 'Sign In wth Google Clicked');
    localStorage.setItem('socialMediaLoginRedirected', 'true');
    googleLogin();
  };

  const githubHandleSignIn = (event: any) => {
    event.preventDefault();
    trackAnalytics('User', 'Sign In wth Github Clicked');
    localStorage.setItem('socialMediaLoginRedirected', 'true');
    gitHubLogin();
  };

  if (auth.isLoaded && auth.uid) {
    if (channelId) {
      return (
        <Redirect
          to={{
            pathname: `/slack/${recordingType}`,
            search: `?channelId=${channelId}&slackUserId=${slackUserId}`,
          }}
        />
      );
    } else {
      return <Redirect to='/' />;
    }
  }
  if (auth.isLoaded && !auth.uid) {
    return (
      <Row>
        <Col
          className='hidden-on-mobile login-image'
          span={12}
          style={{ backgroundImage: `url(${process.env.REACT_APP_WEBSITE_BASE_URL}/${image})` }}
        ></Col>
        <Col className='mobile-view auth-scroll-form' style={{ overflow: 'auto' }} span={12}>
          <Card style={{ height: '100vh', border: '0px' }}>
            <div style={{ maxWidth: 600, margin: 'auto', overflow: 'auto' }}>
              <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Col className={isMobile ? 'sign-in-mobile-header-logo' : ''} span={12}>
                  <FormItem {...tailFormItemLayout}>
                    <a href={window.location.origin.replace('app.', '')}>
                      <Logo />
                    </a>
                  </FormItem>
                </Col>
                <Col className={isMobile ? 'sign-in-mobile-header-text' : ''} span={12}>
                  <FormItem {...tailFormItemLayout}>
                    <p>
                      {!isMobile && <>Don't have an account?</>}
                      <Link
                        style={{ marginLeft: 5, color: '#001DAD' }}
                        to='/signUp'
                        onClick={() => {
                          setErrorMessage('');
                          clearAuthState();
                          trackAnalytics('User', 'Clicked on forgot password');
                          history.push(
                            `/signUp${
                              channelId
                                ? `?channelId=${channelId}&type=${recordingType}&slackUserId=${slackUserId}`
                                : ''
                            }`,
                          );
                        }}
                      >
                        <span style={{ display: 'inline-block' }}>Sign Up</span>
                      </Link>
                    </p>
                  </FormItem>
                </Col>
              </Row>
              {!isMobile && (
                <FormItem {...tailFormItemLayout}>
                  <div className={'signin-and-signup'}>
                    <p>Sign In</p>
                  </div>
                </FormItem>
              )}
              <FormItem style={{ marginTop: '20px' }} {...tailFormItemLayout}>
                {errorMessage && (
                  <Alert
                    className='text-left'
                    message='Login Failed'
                    description={errorMessage}
                    type='error'
                    closable
                    showIcon
                    onClose={() => setErrorMessage('')}
                  />
                )}
              </FormItem>
              {(history.location.pathname === '/signin' ||
                history.location.pathname === '/signIn') && (
                <Form onSubmit={handleSubmit}>
                  <FormItem {...tailFormItemLayout}></FormItem>
                  {isMobile && (
                    <div className={'signin-and-signup'}>
                      <p>Sign In</p>
                    </div>
                  )}
                  <FormItem {...tailFormItemLayout} style={{ marginBottom: 10 }}>
                    {form.getFieldDecorator('email', {
                      rules: [
                        {
                          message: 'Email Please!',
                          required: true,
                        },
                      ],
                    })(<Input className='ant-input' placeholder='Email' type='text' />)}
                  </FormItem>
                  <FormItem {...tailFormItemLayout} style={{ marginBottom: 10 }}>
                    {form.getFieldDecorator('password', {
                      rules: [
                        {
                          message: 'Password Please!',
                          required: true,
                        },
                      ],
                    })(<Input type='password' className='ant-input' placeholder='Password' />)}
                  </FormItem>
                  <FormItem {...tailFormItemLayout}>
                    <div className={'login-text'}>
                      <Link
                        style={{ color: '#001DAD' }}
                        to='/reset-password'
                        onClick={() => {
                          trackAnalytics('User', 'Clicked on forgot password');
                          history.push(
                            `/reset-password${
                              channelId
                                ? `?channelId=${channelId}&type=${recordingType}&slackUserId=${slackUserId}`
                                : ''
                            }`,
                          );
                        }}
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </FormItem>
                  <FormItem {...tailFormItemLayout}>
                    <Button loading={loading} primary htmlType='submit'>
                      Sign In
                      <ArrowRight />
                    </Button>
                  </FormItem>
                </Form>
              )}
              { isShowFullFeature && <>
              <FormItem {...tailFormItemLayout} style={{ marginBottom: 15 }}>
                <Divider>Or</Divider>
              </FormItem>
              <FormItem {...tailFormItemLayout} style={{ marginBottom: 10 }}>
                <Button secondary onClick={googleHandleSignIn}>
                  <Google />
                  <span style={{ marginLeft: 8 }}>Sign In with Google</span>
                </Button>
              </FormItem>
              <FormItem {...tailFormItemLayout}>
                <Button secondary onClick={githubHandleSignIn}>
                  <GitHub />
                  <span style={{ marginLeft: 8 }}>Sign In with GitHub</span>
                </Button>
              </FormItem>
              </>
            }
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
  return <Spinner />;
};

const mapStateToProps = (state: {
  auth: {
    authError: string;
    status: string;
  };
  // @remove-any
  firebase: { auth: any };
}) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    status: state.auth.status,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, IAuthAction>) => {
  return {
    clearAuthState: () => dispatch(clearAuthStateAction()),
    signIn: (creds: ICreds) => dispatch(signInAction(creds)),
    googleLogin: () => dispatch(loginWithGoogle()),
    googleLogInSuccess: () => dispatch(getGoogleLogingResult('', '')),
    gitHubLogin: () => dispatch(loginWithGitHub()),
    gitHubLoginSucces: () => dispatch(getGitHubLogingResult('', '')),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create<IProps>()(withRouter(SignIn)));
