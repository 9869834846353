import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { mobileCheck } from '../../utils';
import { colors } from '../../utils/dummyData';
import './colorPicker.css';
const isMobile = mobileCheck();

interface IProps {
  setBackground: React.Dispatch<React.SetStateAction<string>>;
  setUpdateColor: React.Dispatch<React.SetStateAction<string>>;
  background: string;
  colorState: any;
  setColorState: any;
}
const ColorPicker: React.FC<IProps> = ({
  setBackground,
  background,
  colorState,
  setColorState,
  setUpdateColor,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChangeComplete = (color: any) => {
    setBackground(color.hex);
  };

  const handleSelectedColor = (selected: number, color: string) => {
    setColorState({
      ...colorState,
      selectedIndex: selected,
      selectedColor: color,
    });
    setUpdateColor(color);
  };

  return (
    <div style={{ flexDirection: isMobile ? 'column' : undefined }} className='color-container'>
      <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        {colors.map((color: string, index) => {
          return (
            <div
              key={index}
              className={colorState.selectedIndex === index ? 'selected-color-btn' : 'color-btns'}
              style={{ background: color }}
              onClick={() => handleSelectedColor(index, color)}
            />
          );
        })}
      </div>
      <span className='color-picker-container' onClick={() => handleSelectedColor(14, background)}>
        <div className='color-picker-text'>{background.toUpperCase()}</div>
        <div>
          <div
            className={colorState.selectedIndex === 14 ? 'selected-color-btn' : 'color-btns'}
            style={{ border: '1px solid #000000', background }}
            onClick={handleClick}
          />
          {displayColorPicker ? (
            <div style={{ position: 'absolute', zIndex: 2, left: isMobile ? '10%' : undefined }}>
              <div
                style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}
                onClick={handleClose}
              />
              <SketchPicker color={background} onChange={handleChangeComplete} />
            </div>
          ) : null}
        </div>
      </span>
    </div>
  );
};

export default ColorPicker;
