import { Alert, Col, Drawer, Form, Row, Typography, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import {
  signOut as signOutAction,
  updateUserEmail,
  updateUserProfile,
} from '../../store/actions/authActions';
import { IAuthAction, Type } from '../../types/authTypes';
import { trackAnalytics } from '../../utils';
import { mobileCheck } from '../../utils';
import ImageDragDrop from '../shared/ImageDragDrop';
import { Close } from '../svg';
import './auth.css';
import DeleteUserModal from './DeleteUserModal';
import SetNewPassword from './SetNewPassword';

const isMobile = mobileCheck();

interface IProps {
  userProfile: (fName: string, image: string, imgUrl: string, organizationDetail: any) => void;
  signOut: () => Promise<IAuthAction>;
  userEmail: (email: string) => void;
  onCloseDrawer: () => void;
  visible: boolean;
  authError: string;
  profile: any;
  form: any;
  status: any;
  userId: string;
  selfMasterProjects?: any;
  organizationDetail?: any;
}

const ProfileDrawer: React.FC<IProps> = ({
  onCloseDrawer,
  visible,
  authError,
  status,
  userId,
  signOut,
  profile: { photoURL, fullName, email, imageUrl },
  form: { getFieldDecorator, validateFieldsAndScroll },
  userProfile,
  userEmail,
  organizationDetail,
}) => {
  const [userName, setUserName] = useState('');
  const [oldEmail, setOdEmail] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [imageThumbnailUrl, setImageThumbnailUrl] = useState('');
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [userImageUrl, setUserImageUrl] = useState('');

  useEffect(() => {
    setImageThumbnailUrl(photoURL ? photoURL : '');
    setUserImageUrl(imageUrl ? imageUrl : '');
    setUserName(fullName ? fullName : '');
    setOdEmail(email ? email : '');
  }, [fullName, email]);

  useEffect(() => {
    status === Type.StatusRunning ? setLoading(true) : setLoading(false);
    if (status === Type.updateUserProfileErrore || status === Type.updateEmailErrore) {
      setErrorMessage(authError);
    }

    status === Type.updateEmailSuccess && setSuccess(true);
  }, [authError, status]);

  const handleChangeEmail = () => {
    validateFieldsAndScroll((err: any, values: any) => {
      setNewEmail(values.email);
      if (!err) {
        trackAnalytics('Profile', 'Save Changes Clicked');
        userProfile(values.fullName, imageThumbnailUrl, userImageUrl, organizationDetail);
        if (values.email !== oldEmail) {
          userEmail(values.email);
        }
      }
    });
  };

  return (
    <div>
      <Drawer
        title='Profile'
        placement='right'
        closable={false}
        onClose={() => onCloseDrawer()}
        visible={visible}
        style={{ position: 'absolute', overflowY: 'auto' }}
        bodyStyle={isMobile ? { width: '95%', marginLeft: '3%' } : {}}
        width={isMobile ? '90vw' : '75vw'}
        className='profile-drawer-container'
      >
        <div className='drawer-close-btn' onClick={() => onCloseDrawer()}>
          <Close />
        </div>
        <ColorContext.Consumer>
          {({ theme }) => (
            <Row style={{ width: '100%' }}>
              <Col
                span={isMobile ? 24 : 12}
                className={
                  isMobile
                    ? 'edit-profile-container-mobile'
                    : 'edit-profile-container edit-profile-drawer'
                }
                style={{ color: theme.text.color2 }}
              >
                <div className='edit-profile-header'>
                  <p style={{ marginTop: 25, marginLeft: isMobile ? '5px' : '25px' }}>
                    Personal Information
                  </p>
                  <div className={`${isMobile ? 'd-flex' : ''} profile-avatar`}>
                    <ImageDragDrop
                      hideDragAndDrop={true}
                      url={userImageUrl}
                      sendFeedback={(image: any, thumbnailImage: any) => {
                        userProfile(fullName, thumbnailImage, image, organizationDetail);
                        image && setUserImageUrl(image);
                        thumbnailImage && setImageThumbnailUrl(thumbnailImage);
                        setIsUploadingImage(false);
                        notification.success({
                          message: "Profile image updated successfully",
                        });
                      }}
                      getThumbnailBlob={() => null}
                      setIsUploadingImage={setIsUploadingImage}
                    />
                  </div>
                </div>
                <Form className='profile-input'>
                  <Typography.Text strong={true}>Full name</Typography.Text>
                  <Form.Item>
                    {getFieldDecorator('fullName', {
                      initialValue: userName ? userName : '',
                      rules: [
                        {
                          required: true,
                          message: 'Please input full name!',
                          whitespace: true,
                        },
                      ],
                    })(<Zinput placeholder='Sara Doe' />)}
                  </Form.Item>
                  <Typography.Text strong={true}>Email</Typography.Text>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      initialValue: oldEmail ? oldEmail : '',
                      rules: [
                        {
                          required: true,
                          message: 'Please input email!',
                          whitespace: true,
                        },
                      ],
                    })(<Zinput placeholder='sara.doe@gmail.com' />)}
                  </Form.Item>
                  <div className='profile-btn-container'>
                    <DeleteUserModal userId={userId} signOut={signOut} />
                    <Zbutton
                      primary
                      btnColors={theme}
                      onClick={handleChangeEmail}
                      loading={loading}
                      disabled={loading || isUploadingImage}
                    >
                      Save Changes
                    </Zbutton>
                  </div>
                  <Form.Item>
                    {errorMessage && (
                      <Alert
                        className='err-alert'
                        message='Changes Failed'
                        description={errorMessage}
                        type='error'
                        closable
                        showIcon
                        onClose={() => setErrorMessage('')}
                      />
                    )}
                    {success && (
                      <Alert
                        className='err-alert'
                        message={`Update sent to ${newEmail}`}
                        description={`An email confirmation has been sent to ${newEmail}, Please confirm your email to verify it’s you and complete the update.`}
                        type='success'
                        closable
                        showIcon
                        onClose={() => setSuccess(false)}
                      />
                    )}
                  </Form.Item>
                </Form>
                <p style={{ width: '100%', marginLeft: isMobile ? undefined : '25px' }}>
                  Change Password
                </p>
                <SetNewPassword />
              </Col>
              {/* <Col span={isMobile ? 24 : 12} className="profile-drawer-user-quota">
                  <UserQuota profile={profile} />
                </Col> */}
            </Row>
          )}
        </ColorContext.Consumer>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: {
  auth: {
    authError: string;
    status: string;
  };
  // @remove-any
  firebase: { auth: any };
}) => {
  return {
    userId: state.firebase.auth.uid,
    authError: state.auth.authError,
    status: state.auth.status,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, IAuthAction>) => {
  return {
    userProfile: (fName: string, image: string, imgUrl: string, organizationDetail: any) =>
      dispatch(updateUserProfile(fName, image, imgUrl, organizationDetail)),
    userEmail: (email: string) => dispatch(updateUserEmail(email)),
    signOut: () => dispatch(signOutAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create<IProps>()(ProfileDrawer));
