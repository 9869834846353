import { Modal } from 'antd';
import firebase from 'firebase';
import React, { FC, Fragment, useState } from 'react';
import { trackAnalytics, getLastSelectedWorkflowAndMasterProjectId } from 'utils';
import ColorContext from '../../context/ColorContext';
import { Zbutton } from '../../primitives';
import { IMasterProject, Iworkflow } from '../../types/projectTypes';
import DeleteWorkflowStep from './DeleteWorkflowStep';
import './workflow.css';
import WorkflowDialog from './WorkflowDialog';

interface IProps {
  selectedMasterProject: IMasterProject;
  isEdit: boolean;
  getCanvases: any;
  selectedWorkflowIndex: number;
  onCloseMobile?: any;
}

const Workflow: FC<IProps> = ({
  selectedMasterProject,
  isEdit,
  getCanvases,
  selectedWorkflowIndex,
  onCloseMobile,
}) => {
  //  const [selectedList, setSelectedList] = useState(selectedWorkflowIndex);

  // useEffect(() => {
  //   setSelectedList(selectedWorkflowIndex);
  // }, [selectedWorkflowIndex]);

  const [selectedWorkflow, setSelectedWorkflow] = useState({
    step: '',
    total: 0,
    color: '',
    workFlowDescription : '',
    batchCall : false,
    selectedPrompt : false,
    id: '',
  });
  const [workflowModalVisible, setWorkflowModalVisible] = useState(false);
  const [workflowDeleteModalVisible, setWorkflowDeleteModalVisible] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [isEditWorkflow, setIsEditWorkflow] = useState(false);
  const { workflow, id } = selectedMasterProject;

  const handleSlectedWorkflow = (slectedStep: Iworkflow) => {
    //  setSelectedList(selected);
    trackAnalytics('Workflow', 'Changed Work flow');
    setSelectedWorkflow(slectedStep);
    getCanvases(slectedStep.id, []);
    window.localStorage.setItem('selectedWorkflowId', slectedStep.id);
    onCloseMobile && onCloseMobile();
    getLastSelectedWorkflowAndMasterProjectId()
  };

  const deleteWorkflowStep = (stepId: string, stepName: string) => {
    const deletedWorkflow = workflow.filter((step: Iworkflow) => {
      return stepId !== step.id;
    });
    if (stepId) {
      Modal.confirm({
        content: '',
        title: `Are you sure, you want to delete "${stepName}" step?`,
        onOk() {
          const firestore = firebase.firestore();
          const updatedTimeStamp = new Date().getTime();
          firestore
            .collection('masterProjects')
            .doc(id)
            .set({
              ...selectedMasterProject,
              workflow: deletedWorkflow,
              updatedTimeStamp,
            });
        },
      });
    }
  };

  const showModal = () => {
    setWorkflowModalVisible(true);
  };

  const showDeleteModal = () => {
    setWorkflowDeleteModalVisible(true);
    setIsOptionSelected(false);
  };

  const handleCancel = () => {
    setWorkflowModalVisible(false);
    setWorkflowDeleteModalVisible(false);
  };

  return (
    <Fragment>
      <ColorContext.Consumer>
        {({ theme }) => (
          <Fragment>
            {isEdit ? (
              <div style={{ marginTop: 30, marginBottom: 30 }}>
                <Zbutton
                  primary
                  btnColors={theme}
                  onClick={() => {
                    setIsEditWorkflow(false);
                    showModal();
                  }}
                >
                  Add New
                </Zbutton>
              </div>
            ) : null}
            {!isEdit ? (
              <div className='workflow-title' style={{ color: theme.text.color2 }}>
                <span>Workflow</span>
                <span>
                  {workflow && workflow.length
                    ? workflow
                        .map((t: any) => t.total)
                        .reduce((crr: number, num: number) => crr + num)
                    : 0}
                </span>
              </div>
            ) : null}
            <ul className='workflow-step' style={{ color: theme.text.color2 }}>
              {workflow &&
                workflow.map((step: any, index: number) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleSlectedWorkflow(step)}
                      style={{
                        background:
                          selectedWorkflowIndex === index
                            ? theme.backgrounds.color1
                            : theme.backgrounds.color2,
                      }}
                    >
                      <div className='step-color-container'>
                        <div className='step-color' style={{ background: step.color }} />
                        <div>{step.step}</div>
                      </div>
                      {!isEdit ? (
                        <div>{step.total}</div>
                      ) : (
                        <div className='modify-btns-container'>
                          <div
                            className='hide-edit'
                            onClick={() => {
                              setIsEditWorkflow(true);
                              showModal();
                            }}
                          >
                            Edit
                          </div>
                          <div
                            className='hide-delete'
                            onClick={() => {
                              step.total === 0
                                ? deleteWorkflowStep(step.id, step.step)
                                : showDeleteModal();
                            }}
                          >
                            Delete
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
            </ul>
          </Fragment>
        )}
      </ColorContext.Consumer>
      <WorkflowDialog
        project={selectedMasterProject}
        selectedWorkflow={selectedWorkflow}
        handleCancel={handleCancel}
        visible={workflowModalVisible}
        isEdit={isEditWorkflow}
      />
      <DeleteWorkflowStep
        visible={workflowDeleteModalVisible}
        handleCancel={handleCancel}
        isOptionSelected={isOptionSelected}
        setIsOptionSelected={setIsOptionSelected}
        selectedWorkflow={selectedWorkflow}
        project={selectedMasterProject}
      />
    </Fragment>
  );
};

export default Workflow;
