import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';

const SuperCallLogsTable = () => {
  const [superCallLogs, setSuperCallLogs] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  // Fetch superCallLogs from Firebase
  const urlParams = new URLSearchParams(window.location.search);
  const dateParam = urlParams.get('date') || '2024-10-15';
  let dateFilter: any = new Date(dateParam);
  dateFilter = dateFilter == 'Invalid Date' ? new Date('2024-10-15') : dateFilter;
  useEffect(() => {
    const fetchSuperCallLogs = async () => {
      try {
        const firestore = firebase.firestore();
        const querySnapshot = await firestore
        .collection('superCallLogs')
        .where('timestamp', '>', dateFilter.getTime())
        .get()
        const logs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setSuperCallLogs(logs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching superCallLogs:", error);
        setLoading(false);
      }
    };

    fetchSuperCallLogs();
  }, []);

  // Render loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Timestamp</th>
            <th>Bland AI API Response</th>
            <th>Bland AI API Error</th>
            <th>Bland AI Webhook Request</th>
            <th>Bland AI Webhook Query</th>
          </tr>
        </thead>
        <tbody>
          {superCallLogs.map((log: any, index: any) => (
            <tr key={index}>
              <td>{index+1}</td>
              <td>{log.timestamp ? new Date(log.timestamp).toString() : ''}</td>
              <td>{log.blandAISuccessResponse}</td>
              <td>{log.blandAIResonseError}</td>
              <td>{log.blandAIStoreTranscriptRequest}</td>
              <td>{log.blandAIStoreTranscriptQuery}</td>
            </tr>
          ))}
        </tbody>
      </table>  
      );
};

export default SuperCallLogsTable;
