import { notification } from 'antd';
import firebase from 'firebase';
import moment from 'moment';
import { Type } from '../../types/authTypes';
import {
  ADD_VIDEO_PROJECT,
  CREATE_FEEDBACK_SUCCESS,
  CREATE_PROJECT_ERROR,
  CREATE_PROJECT_SUCCESS,
  IPageFeedback,
  LOADING,
  OPEN_CHAT_WINDOW,
  OPEN_COMMENT_WINDOW,
  SHOULD_DISABLE_UNDO_REDO,
  STOP_LOADING,
  UPDATE_VIDEO_PROJECT,
} from '../../types/projectTypes';
import { statsAction } from './authActions';

const { REACT_APP_FIREBASE_CLOUD_FUNCTION_URL } = process.env;

export const checkTrialPeriod = (firebaseToken: string) => {
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/checkTrialPeriod`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

export const reset = () => {
  return {
    type: STOP_LOADING,
  };
};

export const createProject = (
  project: {
    project_url: string;
    title: string;
    member_emails: string[];
    project_tags: string[];
    type: string;
    imageType?: string;
    project_base64_image?: any;
    appendVideo?: string;
    workflowId?: string;
    masterProjectId?: string;
    isOutboundCall?: boolean;
  },
  firebaseToken: string,
  selectedMasterProject?: any,
) => {
  return async (
    dispatch: (o: { type: string }, e?: {}) => void,
    getState: () => {
      organization: any;
      firebase: { auth: { uid: string; email: string }; profile: any };
    },
    { getFirestore }: any,
  ) => {
    dispatch({ type: LOADING });
    const firestore = getFirestore();
    const authorId = getState().firebase.auth.uid;

    getState().firebase.profile.isInitialUser &&
      firestore
        .collection('users')
        .doc(authorId)
        .update({
          isInitialUser: false,
        });

    const SERVER = process.env.REACT_APP_PROXY_SERVER;
    const allMemberEmails = JSON.parse(JSON.stringify(project.member_emails));
    const allTags = JSON.parse(JSON.stringify(project.project_tags));
    const projectDetail: any = {
      ...project,
      createdAt: new Date(),
      project_created_by: authorId || '',
      unreadFeedbackArray: [],
      member_emails: allMemberEmails,
      project_tags: allTags,
      imageType: project.imageType ? project.imageType : '',
      appendVideo: project.appendVideo || '',
      createdAtUTC: moment()
        .utc()
        .toISOString(),
      member_ids: (selectedMasterProject && selectedMasterProject.member_ids) || [authorId],
    };
    if (project.type === 'liveWebsite') {
      let fullUrlResponse: any = await fetch(`${SERVER}/fullUrl?url=${project.project_url}`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${firebaseToken}`,
        },
      });
      fullUrlResponse = await fullUrlResponse.json();
      if (fullUrlResponse && fullUrlResponse.status) {
        projectDetail.project_url = fullUrlResponse.result;
        projectDetail.project_base64_image = '';
      } else {
        notification.error({
          message: fullUrlResponse.message ? fullUrlResponse.message : 'Something went wrong.',
        });
        dispatch(
          { type: CREATE_PROJECT_ERROR },
          fullUrlResponse.message ? fullUrlResponse.message : 'Something went wrong.',
        );
        return false;
      }
    }

    const ticketNumber = await firestore
      .collection('masterProjects')
      .doc(project.masterProjectId)
      .get()
      .then((doc: any) => {
        const data = doc.data();
        return (data && data.ticketNumber && data.ticketNumber + 1) || 1;
      });

    projectDetail.ticketNumber = ticketNumber;
    let workflow = selectedMasterProject.workflow || [];
    let sequenceNumber = 1;
    workflow = workflow.map((workflowItem: any) => {
      if (workflowItem.id === project.workflowId) {
        sequenceNumber = workflowItem.total + 1;
      }
      return workflowItem;
    });
    projectDetail.sequenceNumber = sequenceNumber;
    return firestore
      .collection('projects')
      .add(projectDetail)
      .then(async (docRef: any) => {
        const insertedId = docRef.id;
        statsAction('PROJECT_CREATED', firestore);
        dispatch({ type: CREATE_PROJECT_SUCCESS });
        /* Start code for update canvas count and recording */
        const profile = getState().firebase.profile;

        let userQuota: any = {};
        if (profile.isManagedUserQuotaInOrganization) {
          userQuota = getState().organization.selectOrganizationDetails.userQuota;
        } else {
          userQuota = profile.userQuota;
        }

        if (userQuota && userQuota.canvasesCount && userQuota.canvasesCount > 0) {
          userQuota.canvasesCount = userQuota.canvasesCount - 1;
        }

        if (Object.keys(userQuota).length === 0) {
          // this is a case of outbound call / interview canvas
          // get organization by created_by and get user quota from organization document
          const organizationDetail = await firestore
            .collection('organization')
            .where('created_by', '==', selectedMasterProject.created_by)
            .get()
          if (
            organizationDetail &&
            organizationDetail.docs &&
            organizationDetail.docs.length
          ) {
            const organizationDetailDocs = organizationDetail.docs[0];
            const organizationId = organizationDetailDocs.id;
            const organizationDetailData = organizationDetailDocs.data();
            userQuota = organizationDetailData.userQuota
            console.log("project:", project, "userQuota:", userQuota);
            if (project.isOutboundCall && userQuota.outboundCallLimit > 0) {
              userQuota.outboundCallLimit = userQuota.outboundCallLimit - 1;
              firestore
                .collection('organization')
                .doc(organizationId)
                .update({
                  userQuota,
                });
            }
          }
        }

        // videoRecord/videoUpload/videoByURL
        // image
        // audio
        if (
          project.type === 'audio' &&
          userQuota &&
          userQuota.totalVoiceClips &&
          userQuota.totalVoiceClips > 0
        ) {
          userQuota.totalVoiceClips = userQuota.totalVoiceClips - 1;
        } else if (
          (project.type === 'videoRecord' || project.type === 'videoRecord') &&
          userQuota &&
          userQuota.totalVideoComments &&
          userQuota.totalVideoComments > 0
        ) {
          userQuota.totalVideoComments = userQuota.totalVideoComments - 1;
        }

        if (profile.isManagedUserQuotaInOrganization) {
          if (getState().organization.selectOrganizationDetails.id) {
            firestore
              .collection('organization')
              .doc(getState().organization.selectOrganizationDetails.id)
              .update({
                userQuota,
              });
          }
        } else if (authorId) {
          firestore
            .collection('users')
            .doc(authorId)
            .update({
              userQuota,
            });
        }
        /* End code for update canvas count and recording */

        if (project.workflowId && project.masterProjectId && selectedMasterProject.workflow) {
          workflow = workflow.map((workflowItem: any) => {
            if (workflowItem.id === project.workflowId) {
              workflowItem.total = workflowItem.total + 1;
            }
            return workflowItem;
          });
          const updatedTimeStamp = new Date().getTime();
          firestore
            .collection('masterProjects')
            .doc(project.masterProjectId)
            .set(
              {
                workflow,
                ticketNumber,
                updatedTimeStamp,
              },
              { merge: true },
            );
        }

        return insertedId;
      })
      .catch((err: Error) => {
        dispatch({ type: CREATE_PROJECT_ERROR }, err);
        return false;
      });
  };
};

export const editProject = (
  id: string,
  title: string,
  member_emails: string[],
  tags: string[],
  type: string,
  imageType: string,
  jiraIssueUrl: string,
  trelloIssueUrl: string,
) => {
  return (
    dispatch: (o: { type: string }, e?: {}) => void,
    _getState: () => {},
    { getFirestore }: any,
  ) => {
    dispatch({ type: LOADING });
    const firestore = getFirestore();
    const allEmails = member_emails ? JSON.parse(JSON.stringify(member_emails)) : [];
    const allTags = tags ? JSON.parse(JSON.stringify(tags)) : [];
    // allTags.push(type);
    return firestore
      .collection('projects')
      .doc(id)
      .update({
        title: title ? title : '',
        member_emails: allEmails,
        tags: allTags,
        type: type ? type : '',
        imageType: imageType ? imageType : '',
        jiraIssueUrl: jiraIssueUrl ? jiraIssueUrl : '',
        trelloIssueUrl: trelloIssueUrl ? trelloIssueUrl : '',
      })
      .then(() => {
        dispatch({ type: STOP_LOADING });
        return true;
      })
      .catch(() => {
        dispatch({ type: STOP_LOADING });
        return false;
      });
  };
};

export const deleteProject = (id: string) => {
  return async (_dispatch: () => void, _getState: () => {}, { getFirestore }: any) => {
    const firestore = getFirestore();
    firestore.delete({ collection: 'projects', doc: id });
    const pageDocument = await firestore
      .collection('pages')
      .where('project_id', '==', id)
      .get();
    if (pageDocument && pageDocument.docs && pageDocument.docs.length) {
      firestore.delete({ collection: 'pages', doc: pageDocument.docs[0].id });
    }
  };
};

export const deleteLoginUserAllNotifications = (isHideDeletedNotification?: boolean) => {
  return (_dispatch: () => any, getState: () => any, { getFirestore }: any) => {
    try {
      const loginUserId: string = getState().firebase.auth.uid;
      const firestore = getFirestore();
      firestore
        .collection('notifications')
        .doc(loginUserId)
        .delete();
      if (!isHideDeletedNotification) {
        notification.success({
          message: 'Notifications deleted successfully.',
        });
      }
    } catch (err) {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };
};

export const saveIframe = (payload: any) => {
  return {
    type: Type.saveIframe,
    payload,
  };
};

export const addFeedback = (feedback: IPageFeedback, projectId: string) => {
  return async (
    dispatch: (o: { type: string }, e?: {}) => void,
    getState: () => any,
    { getFirestore }: any,
  ) => {
    const pageUrlParams = new URLSearchParams(window.location.search);
    const pageParam: any = pageUrlParams.get('page');
    // TODO url is not coming from iframe properly
    const firestore = getFirestore();
    let authorId: any = getState().firebase.auth.uid;
    if (!authorId) {
      authorId = localStorage.getItem('userDetails');
      authorId = authorId && JSON.parse(authorId).uid;
    }
    try {
      const pageDocument = await firestore
        .collection('pages')
        .where('project_id', '==', projectId)
        .where('url', '==', feedback.url)
        .get();

      if (pageDocument && pageDocument.docs && pageDocument.docs.length) {
        const page = pageDocument.docs[0];
        const feedbacks = page.data().feedback;
        const annotations = page.data().annotations || [];
        await firestore
          .collection('pages')
          .doc(page.id)
          .set(
            {
              feedback: [
                ...feedbacks,
                {
                  circle_pointer: feedback.x ? feedback : {},
                  comment: [],
                  readBy: [authorId],
                  page: pageParam,
                },
              ],
              annotations,
            },
            { merge: true },
          );
        /* Start update unread count on add a new feedback */
        await firebase.firestore();
        const projectDetails = await firestore
          .collection('projects')
          .where(firebase.firestore.FieldPath.documentId(), '==', projectId)
          .get();
        if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
          const projectDetailData = projectDetails.docs[0].data();
          if (projectDetailData.project_created_by !== authorId) {
            const unreadFeedbackArray = projectDetailData.unreadFeedbackArray
              ? projectDetailData.unreadFeedbackArray
              : [];
            unreadFeedbackArray.push(feedback.id);
            firestore
              .collection('projects')
              .doc(projectId)
              .update({ unreadFeedbackArray });
          }
        }
        /* End update unread count on add a new feedback */
        statsAction('FEEDBACK_CREATED', firestore);
        dispatch({ type: CREATE_FEEDBACK_SUCCESS });
        // TODO refresh list ?
      } else {
        const page = {
          project_id: projectId,
          created_by: authorId,
          url: feedback.url,
          readBy: [authorId],
          feedback: [
            {
              circle_pointer: feedback.x ? feedback : {},
              comment: [],
              readBy: [authorId],
              page: pageParam,
            },
          ],
          annotations: feedback.json ? [{ pagePath: feedback.url, annotation: feedback.json }] : [],
        };
        const val = await firestore.collection('pages').add(page);
        statsAction('PAGE_CREATED', firestore);
        statsAction('FEEDBACK_CREATED', firestore);
        dispatch({ type: CREATE_FEEDBACK_SUCCESS });
        // TODO refresh list ?
        /* Start update unread count on add a new feedback */
        await firebase.firestore();
        const projectDetails = await firestore
          .collection('projects')
          .where(firebase.firestore.FieldPath.documentId(), '==', projectId)
          .get();
        if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
          const projectDetailData = projectDetails.docs[0].data();
          if (projectDetailData.project_created_by !== authorId) {
            firestore
              .collection('projects')
              .doc(projectId)
              .update({ unreadFeedbackArray: [feedback.id] });
          }
        }
        /* End update unread count on add a new feedback */
        return val;
      }
    } catch (err) {
      // TODO show error ?
    }
  };
};

export const disableUndoRedo = (data: any) => {
  return {
    type: SHOULD_DISABLE_UNDO_REDO,
    payload: data,
  };
};

export const openChatWindow = (data: any) => {
  return {
    type: OPEN_CHAT_WINDOW,
    payload: data,
  };
};

export const openCommentWindow = (data: any) => {
  return {
    type: OPEN_COMMENT_WINDOW,
    payload: data,
  };
};

export const addVideoProject = (data: any) => {
  return {
    type: ADD_VIDEO_PROJECT,
    payload: data,
  };
};

export const updateVideoProject = (data: any) => {
  return {
    type: UPDATE_VIDEO_PROJECT,
    payload: data,
  };
};
