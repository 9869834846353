import 'katex/dist/katex.min.css';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import 'suneditor/dist/css/suneditor.min.css';
import { mobileCheck } from '../../utils';
const isMobile = mobileCheck();

const IframeCanvas = (props: any) => {
  const { userNotes } = props;
  return (
    <div
      style={{
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        width: isMobile ? 'calc(100% - 5px)' : undefined,
        marginLeft: isMobile ? '5px' : undefined,
      }}
      dangerouslySetInnerHTML={{ __html: userNotes }}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    null,
  ),
)(IframeCanvas);
