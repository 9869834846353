import { Modal } from 'antd';
import { Spin } from 'antd';
import React from 'react';

interface ImagePreviewMapper {
  selectedImage: string;
  showPreview: boolean;
  setPreview: () => void;
}

export const ImagePreview = ({ selectedImage, showPreview, setPreview }: ImagePreviewMapper) => {
  const [imageLoadStatus, setImageLoadStatus] = React.useState(true);
  const handleImageLoaded = () => {
    setImageLoadStatus(false);
  };
  return (
    <Modal
      visible={showPreview}
      onOk={setPreview}
      onCancel={setPreview}
      style={{ textAlign: 'center' }}
      width='95vw'
      footer={[]}
    >
      <Spin
        indicator={
          <img
            style={{ height: '43px' }}
            src='/images/small-loader.gif'
            alt='Loading...'
          />
        }
        spinning={imageLoadStatus}
      >
        <img
          onLoad={() => {
            handleImageLoaded();
          }}
          alt='preview'
          src={selectedImage}
          style={{ maxWidth: '100%' }}
        />
      </Spin>
    </Modal>
  );
};
