import { Spin } from 'antd';
import React from 'react';

interface ImagePreviewMapper {
  selectedImage: string;
  // showPreview: boolean;
  // setPreview: () => void;
}

const ImagePreviewComponent = ({ selectedImage }: ImagePreviewMapper) => {
  const [imageLoadStatus, setImageLoadStatus] = React.useState(true);
  const handleImageLoaded = () => {
    setImageLoadStatus(false);
  };
  return (
    <Spin
      indicator={
        <img
          style={{ height: '43px' }}
          src='/images/small-loader.gif'
          alt='Loading...'
        />
      }
      spinning={imageLoadStatus}
    >
      <img
        onLoad={() => {
          handleImageLoaded();
        }}
        alt='preview'
        src={selectedImage}
        style={{ maxWidth: '100%' }}
      />
    </Spin>
  );
};

export default ImagePreviewComponent;
